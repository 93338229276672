import moment from 'moment'
import React from 'react'
import onClickOutside from 'react-onclickoutside'
import * as utils from './date_utils'
import { MonthDropdownOptions } from './month_dropdown_options'

const WrappedMonthDropdownOptions = onClickOutside(MonthDropdownOptions)

type Props = {
    month: number
    onChange(month: number): void
}

type State = {
    dropdownVisible: boolean
}

export class MonthDropdown extends React.PureComponent<Props, State> {
    state: State = {
        dropdownVisible: false
    }

    renderReadView = (monthNames: string[]) => (
        <div
            key="read"
            className="react-datepicker__month-read-view"
            onClick={this.toggleDropdown}
        >
            <span className="react-datepicker__month-read-view--selected-month">
                {monthNames[this.props.month]}
            </span>
        </div>
    )

    renderDropdown = (monthNames: string[]) => (
        <WrappedMonthDropdownOptions
            key="dropdown"
            month={this.props.month}
            monthNames={monthNames}
            onChange={this.onChange}
            onCancel={this.toggleDropdown}
        />
    )

    onChange = (month: number) => {
        this.toggleDropdown()

        if (month !== this.props.month) {
            this.props.onChange(month)
        }
    }

    toggleDropdown = () =>
        this.setState(state => ({
            dropdownVisible: !state.dropdownVisible
        }))

    render() {
        const { state } = this
        const monthNames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
            M => utils.getMonthInLocale(moment({ M }), 'MMMM YYYY')
        )

        return (
            <div
                className="react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--scroll"
            >
                {state.dropdownVisible ? this.renderDropdown(monthNames) : null}
                {this.renderReadView(monthNames)}
            </div>
        )
    }
}
