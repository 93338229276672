import React, { Component } from 'react'
import { classNames, isEqual } from '../../../helpers/misc'

interface Props<T> {
    items: T[]
    label?: string
    icon?: string
    getId: (ent: T) => string | number
    getDisplay: (ent: T) => string
    onChange?: (ent: T | null) => void
    placeholder?: string
    selected: T | string | number
    select: SelectProps
    meta: MetaProps
}

export class Select<T> extends Component<Props<T>> {
    static defaultProps: Pick<Props<any>, 'meta' | 'selected' | 'select'> = {
        selected: '',
        select: {},
        meta: {}
    }

    constructor(props: Props<T>) {
        super(props)
        this._onChange = this._onChange.bind(this)
    }

    shouldComponentUpdate(nextProps: Props<T>) {
        return !isEqual(nextProps, this.props)
    }

    render() {
        const { props } = this
        const {
            selected,
            placeholder,
            icon,
            items,
            getId,
            getDisplay,
            label,
            meta,
            select
        } = props

        const value =
            typeof selected !== 'string' && typeof selected !== 'number'
                ? getId(selected)
                : selected

        return (
            <div
                className={classNames('control form-input', {
                    'has-icons-left': !!icon,
                    'is-loading': meta.loading
                })}
            >
                {label && <label>{label}</label>}

                <div className="select is-fullwidth">
                    <select {...select} onChange={this._onChange} value={value}>
                        {placeholder && (
                            <option value={undefined}>{placeholder}</option>
                        )}
                        {items.map(i => (
                            <option key={getId(i)} value={getId(i)}>
                                {getDisplay(i)}
                            </option>
                        ))}
                    </select>
                </div>

                {icon && (
                    <span className="icon is-small is-left">
                        <i className={icon} />
                    </span>
                )}

                {meta.touched && !!meta.error && (
                    <p className="help is-danger">{meta.error}</p>
                )}
            </div>
        )
    }

    _onChange(ev: React.ChangeEvent<HTMLSelectElement>) {
        const { props } = this
        const { value: v } = ev.target

        if (!!props.onChange) {
            props.onChange(
                props.items.find(i => props.getId(i).toString() === v) || null
            )
        }
    }
}
