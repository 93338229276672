import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { inPath } from "../../helpers/path";
import { FormProps, useForm } from "../../hooks/form";
import { addOrUpdate, getById } from "../../service/api";
import { requiredMessage } from "../../utils/misc";
import { parseNumber } from "../../utils/parse";
import { isCNPJ, isCPF, isEmail } from "../../utils/validate";
import { Button } from "../button";
import { DecimalInput } from "../form/decimal-input";
import { ErrorMessage } from "../form/error-message";
import { ReadOnlyInput } from "../form/readonly-input";
import { Select } from "../form/select";
import { TextInput } from "../form/text-input";
import { ModalActions } from "../panel";
import { RequiredPageProps } from "../route/page";

type Route = RouteComponentProps<{ id?: string }>;
type Props = RequiredPageProps;
type UserForm = User & { password_checker: string };

function addOrUpdateUser(
  user: User,
  route: Route,
  form: FormProps<UserForm>,
  selfEdit: boolean,
  modal: ModalActions
) {
  addOrUpdate<User>("user", user)
    .then(() => {
      form.setSubmitting(false);
      modal.alert(
        form.hasId
          ? "Usuário atualizado com sucesso!"
          : selfEdit
          ? "Dados do Perfil atualizados com sucesso!"
          : "Usuário criado com sucesso!",
        () => route.history.push(selfEdit ? "/" : "/usuarios")
      );
    })
    .catch(err => form.setErrors({ _error: err.message }));
}

const UserTypes: Array<{ id: UserType; desc: string }> = [
  { id: "admin", desc: "Admin" },
  { id: "gerente", desc: "Gerente" },
  { id: "financeiro", desc: "Financeiro" },
  { id: "redator", desc: "Redator" },
  { id: "revisor", desc: "Revisor" }
];

export const UserForm = ({ route, operator, modal }: Props) => {
  const selfEdit = route.location.pathname === "/meu-perfil";
  const form = useForm<UserForm>(
    {
      initialEntity: {
        id: 0,
        name: "",
        email: "",
        password: "",
        password_checker: "",
        payment: {
          id: 0,
          account: "",
          agency: "",
          bankCode: "",
          cpfCnpj: "",
          holder: "",
          paypal: "",
          operation: "",
          pricePerWord: 0
        },
        disabled: false,
        sendEmail: 1,
        type: "redator"
      },
      validate: (values, err) => {
        const { cpfCnpj } = values.payment;

        if (!values.email) {
          err.email = requiredMessage;
        } else if (!isEmail(values.email)) {
          err.email = "Preencha o campo com um e-mail válido.";
        }

        if (!values.name) {
          err.name = requiredMessage;
        }

        if (!values.password && values.id === 0) {
          err.password = requiredMessage;
        }

        if (!values.password_checker && values.id === 0) {
          err.password_checker = requiredMessage;
        } else if (values.password !== values.password_checker) {
          err.password_checker = "Senhas não coincidem";
        }

        if (!!cpfCnpj && !isCPF(cpfCnpj) && !isCNPJ(cpfCnpj)) {
          err.payment = {
            ...err.payment,
            cpfCnpj: "Insira um CPF/CNPJ válido"
          };
        }

        if (values.payment.pricePerWord < 0.01 && operator.type === "redator") {
          err.payment = {
            ...err.payment,
            pricePerWord: "Valor tem que ser maior que R$ 0,01"
          };
        }

        return err;
      }
    },
    route
  );

  const { entity, errors, touched } = form;

  useEffect(() => {
    if (form.hasId || selfEdit) {
      form.handleFetch({
        action: (id, ac) =>
          getById<User>(
            "user",
            selfEdit ? operator.id : parseNumber(id),
            ac.signal
          ),
        errorFn: err => form.setErrors({ _error: err.message })
      });
    }
  }, []);

  const canSendEmail = entity.sendEmail == 1 ? true : false;
  return (
    <form
      className="content"
      onSubmit={ev => {
        ev.preventDefault();
        form.handleSubmit(ent =>
          addOrUpdateUser(ent, route, form, selfEdit, modal)
        );
      }}
    >
      <label>
        <h4>Usuário</h4>
      </label>
      <div className="columns">
        <div className="column">
          <TextInput
            label="Nome"
            value={entity.name}
            input={{
              autoComplete: "name"
            }}
            meta={{
              error: errors.name,
              touched: touched.name
            }}
            onChange={(name, type) =>
              form.handleChange({
                path: "name",
                type,
                values: { name }
              })
            }
          />
        </div>

        <div className="column">
          <TextInput
            label="E-mail"
            type="email"
            value={entity.email}
            input={{
              autoComplete: "email"
            }}
            meta={{
              error: errors.email,
              touched: touched.email
            }}
            onChange={(email, type) =>
              form.handleChange({
                path: "email",
                type,
                values: { email }
              })
            }
          />
        </div>

        <div className="column">
          {selfEdit ? (
            <ReadOnlyInput
              label="Tipo"
              value={UserTypes.find(x => x.id === entity.type)!.desc}
            />
          ) : (
            <Select
              label="Tipo"
              items={UserTypes.filter(
                x =>
                  (operator.type === "gerente" && x.id !== "admin") ||
                  operator.type === "admin"
              )}
              getId={x => x.id}
              getDisplay={x => x.desc}
              selected={entity.type}
              onChange={type =>
                form.handleChange({
                  path: "type",
                  values: { type: type!.id }
                })
              }
            />
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextInput
            label="Senha"
            type="password"
            value={entity.password}
            input={{
              autoComplete: "current-password"
            }}
            meta={{
              error: errors.password,
              touched: touched.password
            }}
            onChange={(password, type) =>
              form.handleChange({
                path: "password",
                type,
                values: { password }
              })
            }
          />
        </div>

        <div className="column">
          <TextInput
            label="Confirmar Senha"
            type="password"
            value={entity.password_checker}
            ignoreEnter={false}
            input={{
              autoComplete: "check-password"
            }}
            meta={{
              error: errors.password_checker,
              touched: touched.password_checker
            }}
            onChange={(password_checker, type) =>
              form.handleChange({
                path: "password_checker",
                type,
                values: { password_checker }
              })
            }
          />
        </div>
      </div>
      <label>
        <h5>Notificações</h5>
      </label>

      <div className="columns">
        <div className="column form-input">
          
          <p>
          <input
            checked={canSendEmail}
            onChange={ev =>
              form.setValues({
                sendEmail: ev.target.checked ? 1 : 0
              })
            }
            type="checkbox"
            className="mr-2"
          />
          E-mail</p>
        </div>
      </div>

      {entity.type === "redator" && (
        <>
          <h5>Informações de Pagamento</h5>

          <div className="columns">
            <div className="column">
              <DecimalInput
                label="Preço por palavra"
                meta={{
                  error: inPath(errors, e => e.payment.pricePerWord),
                  touched: inPath(touched, t => t.payment.pricePerWord)
                }}
                precision={3}
                value={entity.payment.pricePerWord || 0}
                onChange={(pricePerWord, type) =>
                  form.handleChange({
                    path: x => x.payment.pricePerWord,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        pricePerWord
                      }
                    }
                  })
                }
              />
            </div>

            <div className="column">
              <TextInput
                label="Titular"
                value={entity.payment.holder}
                meta={{
                  error: inPath(errors, e => e.payment.holder),
                  touched: inPath(touched, t => t.payment.holder)
                }}
                onChange={(holder, type) =>
                  form.handleChange({
                    path: x => x.payment.holder,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        holder
                      }
                    }
                  })
                }
              />
            </div>

            <div className="column">
              <TextInput
                label="CPF/CNPJ"
                value={entity.payment.cpfCnpj}
                meta={{
                  error: inPath(errors, e => e.payment.cpfCnpj),
                  touched: inPath(touched, t => t.payment.cpfCnpj)
                }}
                onChange={(cpfCnpj, type) =>
                  form.handleChange({
                    path: x => x.payment.cpfCnpj,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        cpfCnpj
                      }
                    }
                  })
                }
              />
            </div>

            <div className="column">
              <TextInput
                label="PayPal"
                type="email"
                value={entity.payment.paypal}
                meta={{
                  error: inPath(errors, e => e.payment.paypal),
                  touched: inPath(touched, t => t.payment.paypal)
                }}
                onChange={(paypal, type) =>
                  form.handleChange({
                    path: x => x.payment.paypal,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        paypal
                      }
                    }
                  })
                }
              />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <TextInput
                label="Operação"
                value={entity.payment.operation}
                meta={{
                  error: inPath(errors, e => e.payment.operation),
                  touched: inPath(touched, t => t.payment.operation)
                }}
                onChange={(operation, type) =>
                  form.handleChange({
                    path: x => x.payment.operation,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        operation
                      }
                    }
                  })
                }
              />
            </div>

            <div className="column">
              <TextInput
                label="Banco"
                value={entity.payment.bankCode}
                meta={{
                  error: inPath(errors, e => e.payment.bankCode),
                  touched: inPath(touched, t => t.payment.bankCode)
                }}
                onChange={(bankCode, type) =>
                  form.handleChange({
                    path: x => x.payment.bankCode,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        bankCode
                      }
                    }
                  })
                }
              />
            </div>

            <div className="column">
              <TextInput
                label="Agência"
                value={entity.payment.agency}
                meta={{
                  error: inPath(errors, e => e.payment.agency),
                  touched: inPath(touched, t => t.payment.agency)
                }}
                onChange={(agency, type) =>
                  form.handleChange({
                    path: x => x.payment.agency,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        agency
                      }
                    }
                  })
                }
              />
            </div>

            <div className="column">
              <TextInput
                label="Conta"
                value={entity.payment.account}
                meta={{
                  error: inPath(errors, e => e.payment.account),
                  touched: inPath(touched, t => t.payment.account)
                }}
                onChange={(account, type) =>
                  form.handleChange({
                    path: x => x.payment.account,
                    type,
                    values: {
                      payment: {
                        ...entity.payment,
                        account
                      }
                    }
                  })
                }
              />
            </div>
          </div>
        </>
      )}
      <div className="buttons is-right">
        <Button
          icon="fas fa-save"
          styleClass="is-link is-medium"
          loading={form.submitting}
          disabled={form.submitting}
          title="Salvar"
        />
      </div>
      <ErrorMessage error={errors._error} />
    </form>
  );
};
