import React, { Component } from 'react'
import { classNames, isEqual } from '../../../helpers/misc'
import { parseNumber } from '../../../utils/parse'

type Props = {
    value: number | null
    min?: number
    max?: number
    step?: number
    meta: MetaProps
    input: InputProps
    ignoreEnter: boolean
    placeholder?: string
    label?: string
    disabled?: boolean
    icon?: string
    onChange?: (value: number | null, type: ChangeType) => void
    refEl?: (el: HTMLInputElement | null) => void
}

export class IntegerInput extends Component<Props> {
    static defaultProps: Pick<Props, 'meta' | 'ignoreEnter' | 'input'> = {
        ignoreEnter: true,
        input: {},
        meta: {}
    }

    shouldComponentUpdate(nextProps: Props) {
        return !isEqual(nextProps, this.props)
    }

    render() {
        const {
            icon,
            refEl,
            meta,
            placeholder,
            disabled,
            value,
            min,
            max,
            step,
            label,
            input
        } = this.props
        const hasError = meta.touched && !!meta.error

        return (
            <div
                className={classNames('form-input', {
                    'is-danger': hasError
                })}
            >
                {label && <label>{label}</label>}

                <input
                    {...input}
                    disabled={disabled}
                    placeholder={placeholder}
                    type="number"
                    ref={el => refEl && refEl(el)}
                    onChange={this._onChange}
                    onBlur={this._onBlur}
                    onKeyDown={this._onKeyDown}
                    value={value || ''}
                    min={min}
                    max={max}
                    step={step}
                />

                {icon && (
                    <span className="icon is-small is-left">
                        <i className={icon} />
                    </span>
                )}

                {hasError && <p className="help is-danger">{meta.error}</p>}
            </div>
        )
    }

    _onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) =>
        ev.keyCode === 13 && this.props.ignoreEnter && ev.preventDefault()

    _onBlur = (ev: React.FocusEvent<HTMLInputElement>) =>
        this._handleChange(ev.target.value, 'blur')

    _onChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
        this._handleChange(ev.target.value, 'key')

    _handleChange(value: string, type: ChangeType) {
        const { props } = this

        if (!!props.onChange) {
            let num = value === '' ? null : parseNumber(value)

            if (!!props.max && !!num) {
                num = Math.min(num, props.max)
            }

            if (!!props.min && !!num) {
                num = Math.max(props.min, num)
            }

            props.onChange(num, type)
        }
    }
}
