import React, { useEffect, useState } from "react";
import { getList, remove } from "../../service/api";
import { Button } from "../button";
import { List } from "../list";
import { RequiredPageProps } from "../route/page";

type Props = RequiredPageProps;

export const AmbienteList = ({ modal }: Props) => {
  const [refresh, setRefresh] = useState(true);
  const [ambientes, setAmbientes] = useState<Enviroment[]>([]);

  useEffect(() => {
    if (refresh) {
      getList<Enviroment>("enviroment")
        .then(items => setAmbientes(items))
        .catch(err => modal.alert(err.message));

      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className="content">
      <div className="float-left">
        <h4>Gerenciar ambientes</h4>
      </div>

      <div className="buttons is-right">
        <Button
          onClick="/ambiente/cadastro"
          title="Novo Ambiente"
          icon="fas fa-plus"
          styleClass="is-link is-medium"
        />
      </div>

      <List<Enviroment>
        primaryKey={"id"}
        columns={[
          { title: "#", path: "id" },
          { title: "Nome", path: "name" }
        ]}
        buttons={[
          {
            action: "/ambiente/cadastro/:id",
            styleClass: "is-info",
            title: "Editar",
            icon: "fas fa-edit"
          },
          {
            action: entity =>
              modal.confirm(
                `Deseja remover o Ambiente '${entity.name}'?`,
                confirmed =>
                  confirmed &&
                  remove<Enviroment>("enviroment", entity.id)
                    .then(() => setRefresh(true))
                    .catch(err => modal.alert(err.message))
              ),
            icon: "fas fa-times",
            styleClass: "is-danger",
            title: "Excluir"
          }
        ]}
        items={ambientes}
        emptyMessage="Nenhum Ambiente encontrado."
      />
    </div>
  );
};
