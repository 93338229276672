import moment from 'moment'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { Manager, Popper, Reference } from 'react-popper'
import { classNames, isEqual } from '../../helpers/misc'
import { Calendar } from './calendar'
import {
    isDayDisabled,
    isSameDay,
    parseDate,
    safeDateFormat,
    set,
    toMoment
} from './date_utils'

const outsideClickIgnoreClass = 'react-datepicker-ignore-onclickoutside'
const WrappedCalendar = onClickOutside(Calendar)

type Props = {
    children?: any
    className?: string
    placeholder?: string
    dateFormat: string
    disabled: boolean
    ignoreEnter: boolean
    usePast: boolean
    maxDate: NullDate
    minDate: NullDate
    value: NullDate
    onChange?(date: NullDate): void
}

type State = {
    focused: boolean
    open: boolean
    inputValue: string
    preventFocus: boolean
    dateView: moment.Moment
}

export class DatePicker extends Component<Props, State> {
    static defaultProps: Pick<
        Props,
        'dateFormat' | 'disabled' | 'ignoreEnter'
    > = {
        dateFormat: 'DD/MM/YYYY',
        ignoreEnter: true,
        disabled: false
    }
    readonly state: State

    initialState: State
    input: HTMLInputElement | null
    inputFocusTimeout: NodeJS.Timer | null
    preventFocusTimeout: NodeJS.Timer | null

    constructor(props: Props) {
        super(props)

        const initialState: State = {
            dateView: this.getDateInView(props),
            focused: false,
            inputValue: safeDateFormat(props.value, props.dateFormat),
            open: false,
            preventFocus: false
        }

        this.state = initialState
        this.initialState = initialState
        this.input = null
        this.inputFocusTimeout = null
        this.preventFocusTimeout = null
    }

    shouldComponentUpdate(_: Props, newState: State) {
        return !isEqual(this.state, newState)
    }

    componentWillReceiveProps(nextProps: Props) {
        const { minDate, maxDate, value } = this.props

        if (!isSameDay(nextProps.value, value)) {
            this.setState({
                inputValue: safeDateFormat(
                    nextProps.value,
                    nextProps.dateFormat
                )
            })
        }

        if (
            (!isSameDay(nextProps.minDate, minDate) ||
                !isSameDay(nextProps.maxDate, maxDate)) &&
            value &&
            isDayDisabled(value, nextProps.minDate, nextProps.maxDate)
        ) {
            const momentValue = moment(value)

            if (
                !!nextProps.minDate &&
                momentValue.isBefore(nextProps.minDate)
            ) {
                this.setSelected(nextProps.minDate)
            } else if (
                !!nextProps.maxDate &&
                momentValue.isAfter(nextProps.maxDate)
            ) {
                this.setSelected(nextProps.maxDate)
            }
        }
    }

    componentWillUnmount() {
        if (this.preventFocusTimeout) {
            clearTimeout(this.preventFocusTimeout)
        }
    }

    setFocus = () => !!this.input && this.input.focus()
    setOpen = (open: boolean) => this.setState({ open })

    getDateInView = (props: Props) => {
        const { value, minDate, maxDate } = props
        const current = moment()
        const initialDate = value

        if (initialDate) {
            return moment(initialDate)
        } else {
            if (minDate && current.isBefore(minDate)) {
                return moment(minDate)
            } else if (maxDate && current.isAfter(maxDate)) {
                return moment(maxDate)
            }
        }

        return current
    }

    handleFocus = () =>
        this.setState(state => ({
            focused: true,
            open: !state.preventFocus ? true : state.open
        }))

    cancelFocusInput = () => {
        if (!!this.inputFocusTimeout) {
            clearTimeout(this.inputFocusTimeout)
            this.inputFocusTimeout = null
        }
    }

    deferFocusInput = () => {
        this.cancelFocusInput()
        this.inputFocusTimeout = setTimeout(() => this.setFocus(), 1)
    }

    handleDropdownFocus = () => {
        this.cancelFocusInput()
    }

    handleBlur = () => {
        this.setState({ focused: false })

        if (this.state.open) {
            this.deferFocusInput()
        } else {
            this.setState((_, props) => ({
                dateView: !!props.value
                    ? moment(props.value)
                    : this.getDateInView(props),
                inputValue: safeDateFormat(props.value, props.dateFormat)
            }))
        }
    }

    handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { props } = this
        const inputValue = ev.target.value

        this.setState({ inputValue })

        if (inputValue === '' && !!props.onChange) {
            return props.onChange(null)
        }

        const date = parseDate(inputValue, props.dateFormat)

        if (!!date) {
            this.setSelected(date.toDate(), true)
            this.setState({ dateView: date })
        }
    }

    handleSelect = (date: moment.Moment) => {
        // Preventing onFocus event to fix issue
        // https://github.com/Hacker0x01/react-datepicker/issues/628
        this.setState({ preventFocus: true }, () => {
            this.preventFocusTimeout = setTimeout(
                () => this.setState({ preventFocus: false }),
                50
            )
            return this.preventFocusTimeout
        })

        this.setSelected(date.toDate())
        this.setDateView(() => date)
        this.setOpen(false)
    }

    setSelected = (date: Date, fromKeyboard: boolean = false) => {
        const { props } = this
        let changedDate = date

        if (
            changedDate !== null &&
            isDayDisabled(changedDate, props.minDate, props.maxDate)
        ) {
            return
        }

        const selected = props.value
        const isSame = !!selected
            ? toMoment(selected).isSame(changedDate)
            : false

        if (!isSame) {
            if (!!selected && !fromKeyboard) {
                const msel = moment(selected)
                const cd = moment(changedDate)

                changedDate = set(cd, {
                    hour: msel.get('hour'),
                    minute: msel.get('minute'),
                    second: msel.get('second')
                }).toDate()
            }

            if (!!props.onChange) {
                props.onChange(changedDate)
            }
        }

        this.setState({ inputValue: safeDateFormat(date, props.dateFormat) })
    }

    onInputClick = () => {
        if (!this.props.disabled) {
            this.setOpen(true)
        }
    }

    onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { ignoreEnter } = this.props
        const eventKey = event.key

        if (eventKey === 'Escape') {
            event.preventDefault()
            this.setOpen(false)
        } else if (eventKey === 'Tab') {
            this.setOpen(false)
        } else if (eventKey === 'Enter') {
            const { state, props } = this

            if (state.inputValue === '' && props.onChange) {
                event.preventDefault()
                props.onChange(new Date())
            } else if (ignoreEnter) {
                event.preventDefault()
            }

            this.setOpen(false)
        }
    }

    closeCalendar = () => this.setOpen(false)

    setDateView = (mapper: (date: moment.Moment) => moment.Moment) =>
        this.setState(prev => ({ dateView: mapper(prev.dateView) }))

    renderCalendar = () => {
        const { state, props } = this

        if (!state.open || props.disabled) {
            return null
        }

        return (
            <WrappedCalendar
                usePast={props.usePast}
                selected={props.value}
                minDate={props.minDate}
                maxDate={props.maxDate}
                dateView={state.dateView}
                outsideClickIgnoreClass={outsideClickIgnoreClass}
                onClickOutside={this.closeCalendar}
                onSelect={this.handleSelect}
                setOpen={this.setOpen}
                onDropdownFocus={this.handleDropdownFocus}
                setDateView={this.setDateView}
            />
        )
    }

    renderDateInput = () => {
        const { props, state } = this
        const className = classNames(props.className, {
            [outsideClickIgnoreClass]: state.open
        })

        return (
            <input
                type="text"
                ref={input => (this.input = input)}
                className={className}
                value={state.inputValue}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onClick={this.onInputClick}
                onFocus={this.handleFocus}
                onKeyDown={this.onInputKeyDown}
            />
        )
    }

    render() {
        const calendar = this.renderCalendar()
        const hidePopper = !this.state.open || this.props.disabled

        return (
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <div className="react-datepicker-wrapper" ref={ref}>
                            <div className="react-datepicker__input-container">
                                {this.renderDateInput()}
                            </div>
                        </div>
                    )}
                </Reference>
                <Popper
                    placement="bottom-start"
                    modifiers={{
                        preventOverflow: {
                            boundariesElement: 'viewport',
                            enabled: true,
                            escapeWithReference: true
                        }
                    }}
                >
                    {({ ref, style, placement, scheduleUpdate }) => {
                        scheduleUpdate()

                        return (
                            <div
                                ref={ref}
                                className="react-datepicker-popper"
                                style={style}
                                data-placement={placement}
                            >
                                {!hidePopper ? calendar : null}
                            </div>
                        )
                    }}
                </Popper>
            </Manager>
        )
    }
}
