import React from "react";
import { __RouterContext } from "react-router";
import { RouteComponentProps } from "react-router";
import { useForm } from "../../hooks/form";
import { Button } from "../button";
import { TextInput } from "../form/text-input";

type Props = {
  onFilter: (value: string) => void;
  route: Route;
  initialEntity: Filter;
};

type Route = RouteComponentProps<{ id?: string }>;

const TaskFilterArquivadas = ({ route, onFilter, initialEntity }: Props) => {

  const form = useForm<Filter>(
    {
      initialEntity,
      validate: (values, err) => {
        return err;
      }
    },
    route
  );

  const { entity, touched } = form;

  return (
    <div className="box filterbox">
      <div className="columns is-variable is-3">
        <div className="column is-two-thirds">
          <TextInput
            placeholder="Título da Pauta"
            value={entity.description}
            input={{
              autoComplete: "description"
            }}
            meta={{
              touched: touched.description
            }}
            onChange={(description, type) => {
              form.handleChange({
                path: "description",
                type,
                values: { description }
              });
            }}
          />
        </div>

        <div className="column auto">
          <Button
            onClick={function (e) {
              onFilter(entity.description);
            }}
            styleClass="filtertop filterbtn is-fullwidth"
            title="Buscar"
            icon="fas fa-search"
          />
        </div>
      </div>
    </div>
  );
};

export default TaskFilterArquivadas;