import React from 'react'
import { DecimalInput } from '../components/form/decimal-input'
import { zip } from '../helpers/functional'
import { parseNumber } from './parse'

export type DecimalConfig = {
    precision: number
    decimalSeparator: string
    thousandSeparator: string
    prefix: string
    selectAllOnFocus: boolean
}

interface AdjustCaretPosition {
    value: string
    rawValue: string
    previousValue: string
    caretPosition: number
    placeholder: string
}

interface AdjustDecimalCaret {
    masked: string
    text: string
    caretPosition: number
}

interface ConformProps {
    value?: string
    previousValue: string
    mask: MaskChar[]
    placeholder: string
    caretPosition: number
}

const isAndroid = typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent)

export const placeholderChar = '_'

export const getInputSelection = ({ selectionStart, selectionEnd }: HTMLInputElement) =>
    ({
        end: selectionEnd ? selectionEnd : 0,
        start: selectionStart ? selectionStart : 0
    })

export function setCaretPosition(element: HTMLInputElement, pos: number) {
    if (document.activeElement === element) {
        if (isAndroid) {
            const callback = () => element.setSelectionRange(pos, pos, 'none')

            if (typeof requestAnimationFrame !== 'undefined') {
                requestAnimationFrame(callback)
            } else {
                setTimeout(callback, 0)
            }
        } else {
            element.setSelectionRange(pos, pos, 'none')
        }
    }
}

export const convertMaskToPlaceholder = (mask: MaskChar[] = []) =>
    mask.map(char => char instanceof RegExp ? placeholderChar : char).join('')

export const conformToMask = (config: ConformProps) => {
    const { value = '', previousValue, mask, placeholder, caretPosition } = config

    const editDistance = value.length - previousValue.length
    const isAddition = editDistance > 0
    const startIndex = caretPosition + (isAddition ? -editDistance : 0)

    const valueArr = value
        .split('')
        .filter((char, i) => {
            const shouldOffset = i >= startIndex && previousValue.length === mask.length

            return !(char !== placeholderChar && char === placeholder[shouldOffset ? i - editDistance : i])
        })

    let counter = 0

    const maskMapper = (pch: string, currentMask: MaskChar): string => {
        const ch = valueArr[counter]
        const isEditable = pch === placeholderChar && !!ch
        const isValidChar = ch !== placeholderChar && currentMask instanceof RegExp && currentMask.test(ch)

        if (isEditable || isValidChar) {
            counter++
        }

        return isEditable && isValidChar ? ch : isEditable ? maskMapper(pch, currentMask) : pch
    }

    return zip(placeholder.split(''), mask).map(([pch, cm]) => maskMapper(pch, cm)).join('')
}

export const adjustCaretPosition = (props: AdjustCaretPosition) => {
    const { caretPosition, placeholder, previousValue, rawValue, value } = props

    if (caretPosition === 0) {
        return 0
    }

    const editLength = rawValue.length - previousValue.length
    const isAddition = editLength > 0

    const isMultiCharDelete = editLength > 1 && !isAddition && previousValue.length !== 0
    const hasRejectedChar = isAddition && (previousValue === value || value === placeholder)

    if (isMultiCharDelete) {
        return caretPosition
    }

    let startingSearchIndex = 0
    let targetChar = ''

    if (hasRejectedChar) {
        startingSearchIndex = caretPosition - editLength
    } else {
        const normalizedValue = value.toLowerCase()
        const normalizedRawValue = rawValue.toLowerCase()

        const leftHalfChars = normalizedRawValue.substr(0, caretPosition).split('')
        const intersection = leftHalfChars.filter(char => normalizedValue.indexOf(char) !== -1)

        targetChar = intersection[intersection.length - 1]

        const countTargetCharInIntersection = intersection.filter(char => char === targetChar).length

        const countTargetCharInPlaceholder = placeholder
            .substr(0, placeholder.indexOf(placeholderChar))
            .split('')
            .filter((char, index) => (char === targetChar && rawValue[index] !== char))
            .length

        const requiredNumberOfMatches = countTargetCharInPlaceholder + countTargetCharInIntersection

        let numberOfEncounteredMatches = 0

        for (let i = 0; i < value.length; i++) {
            const ValueChar = normalizedValue[i]

            startingSearchIndex = i + 1

            if (ValueChar === targetChar) {
                numberOfEncounteredMatches++
            }

            if (numberOfEncounteredMatches >= requiredNumberOfMatches) {
                break
            }
        }
    }

    if (isAddition) {
        return placeholder
            .split('')
            .findIndex((char, i) => i >= startingSearchIndex && (char === placeholderChar || i === placeholder.length))
    } else {
        for (let i = startingSearchIndex; i >= 0; i--) {
            if (placeholder[i - 1] === placeholderChar || i === 0) {
                return i
            }
        }
    }

    return 0
}

export const applyMask = (mask: MaskChar[], value: string) =>
    value === '' ? '' : conformToMask({
        caretPosition: value.length - 1,
        mask,
        placeholder: convertMaskToPlaceholder(mask),
        previousValue: '',
        value
    })
    
export function splitDecimal(value: number, precision: number = 2): [number, number] {
    const num = value.toLocaleString('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision })
    const [int, fract] = num.toString().split('.')

    return [parseNumber(int), parseNumber(fract)]
}

export function maskDecimal(value: number, config?: Partial<DecimalConfig>) {
    const { prefix, precision: _prc, decimalSeparator, thousandSeparator } = { ...DecimalInput.defaultConfig, ...config }
    const precision = Math.max(0, Math.min(10, _prc))
    const _number = value.toLocaleString('en-US', { minimumFractionDigits: precision, maximumFractionDigits: precision })

    if (precision === 0) {
        return prefix + _number.split(',').join(thousandSeparator)
    }

    const [int, fract] = _number.split('.')

    return prefix + int.split(',').join(thousandSeparator) + decimalSeparator + fract
}

export function adjustDecimalCaret({ caretPosition, masked, text }: AdjustDecimalCaret) {
    const { length: maskedLen } = masked
    const { length } = text
    
    const pos = maskedLen - length + caretPosition
    const isNumericPos = /^\d+$/.test(masked[pos - 1])

    return isNumericPos ? pos : pos - 1
}

export const maskMoney = (value: number) =>
    maskDecimal(value, { prefix: 'R$' })

export const tableMaskMoney = (value: number) =>
    (
        <div className="flexTable">
            <div className="currency">R$</div>
            <div className="value">
                {maskDecimal(value)}
            </div>
        </div>
    )