import moment from "moment";
import React from "react";
import { config } from "../config";
import { cleanObject } from "../helpers/misc";
import { stringify } from "../utils/format";
import { localStore } from "../utils/store";

export const operatorPath = (type: UserType) => {
  if (type === "admin") {
    return "";
  } else {
    return type;
  }
};

export type AddResponse = { id: number };

export const logOut = () => {
  localStore.remove(config.TOKEN_KEY);
  localStore.remove(config.OPERATOR_KEY);
  window.location.reload();
};

export const getTokenHeader = () =>
  new Headers({
    Authorization: `Bearer ${localStore.get(config.TOKEN_KEY)}`,
    "Content-Type": "application/json"
  });

export async function parseStatus<T>(
  res: Response,
  callback?: (r: Response) => Promise<T>
): Promise<T> {
  const { status } = res;
  const contentType = res.headers.get("content-type");

  if (status === 401) {
    logOut();
    throw Error("Erro 401: Token expirada ou não informada.");
  } else if (status === 403) {
    throw Error("Erro 403: Você não tem permissão pra executar essa ação.");
  } else if (status === 404 && !contentType) {
    throw Error("Erro 404: Não encontrado.");
  } else if ([400, 404, 409, 422].includes(status)) {
    const payload: ErrorPayload = await res.json();
    throw Error(`Erro ${status}: ${payload.message}`);
  } else if (status === 500) {
    const payload: ErrorPayload = await res.json();

    throw {
      message: (
        <>
          <p>
            Erro 500: Erro interno do sistema, entre em contato com o suporte
          </p>

          <pre>
            <code>{payload.message}</code>
          </pre>
        </>
      )
    };
  } else if (status === 204) {
    return Promise.resolve(null as any);
  } else {
    return callback ? callback(res) : res.json();
  }
}

export async function loginRequest(data: LoginRequest) {
  const response = await fetch(`${config.API_URL}/login.php`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<LoginResponse>(response);
}

export async function getTasks(filter: Filter, signal?: AbortSignal) {
  const filterData = filter.prazo ? moment(filter.prazo).format() : "";
  const response = await fetch(
    `${config.API_URL}/task/list.php?projetoId=${filter.projetoId}&redatorId=${filter.redatorId}&description=${filter.description}&prazo=${filterData}`,
    {
      headers: getTokenHeader(),
      signal
    }
  );

  return parseStatus<TaskList>(response);
}

export async function getTasksEnviroment(
  id: number,
  filter: Filter,
  signal?: AbortSignal
) {
  const filterData = filter.prazo ? moment(filter.prazo).format() : "";
  const response = await fetch(
    `${config.API_URL}/task/listByEnviroment.php?id=${id}&projetoId=${filter.projetoId}&redatorId=${filter.redatorId}&description=${filter.description}&prazo=${filterData}`,
    {
      headers: getTokenHeader(),
      signal
    }
  );

  return parseStatus<TaskList>(response);
}

export async function getInvoiceList(params: InvoiceFilterParams, signal?: AbortSignal) {
  var url = "";
  if (params.filterUserByToken === true) {
    console.log("condicao1")
    return getInvoiceListByUser(params)
  }
  else {
    const _refMonth = params.referenceMonth + 1;

    const filterByMonthAndUser = params.userId > 0 ? true : false
    console.log(filterByMonthAndUser)
    if (filterByMonthAndUser) {
      console.log("condicao2")
      url = `${config.API_URL}/invoice/listByMonthAndUser.php?month=${_refMonth}&year=${params.referenceYear}&userId=${params.userId}`;
    }
    else {
      console.log("condicao3")
      url = `${config.API_URL}/invoice/listByMonth.php?month=${_refMonth}&year=${params.referenceYear}`;
    }

    const response = await fetch(
      url,
      {
        headers: getTokenHeader(),
        signal
      }
    );
    return parseStatus<Invoice[]>(response);
  }
}

export async function getInvoiceListByUser(params: InvoiceFilterParams, signal?: AbortSignal) {

  const url = `${config.API_URL}/invoice/listByUser.php?&userId=${params.userId}`;
  const response = await fetch(
    url,
    {
      headers: getTokenHeader(),
      signal
    }
  );
  return parseStatus<Invoice[]>(response);
}


export async function getCountInvoice(referenceMonth: number, referenceYear: number, userId: number, signal?: AbortSignal) {

  const response = await fetch(
    `${config.API_URL}/invoice/getCount.php?month=${referenceMonth}&year=${referenceYear}&userId=${userId}`,
    {
      headers: getTokenHeader(),
      signal
    }
  );
  console.log(`${config.API_URL}/invoice/getCount.php?month=${referenceMonth}&year=${referenceYear}&userId=${userId}`)
  return parseStatus<CountNameInvoice[]>(response);
}

export async function getArchivedTasks(taskDescription: string, page = 1, signal?: AbortSignal) {
  const response = await fetch(
    `${config.API_URL}/task/archive.php?page=${page}&description=${taskDescription}`,

    {
      headers: getTokenHeader(),
      signal
    }
  );
  return parseStatus<ArchivedTasks>(response);
}

export async function getPayments(
  de: NullDate,
  ate: NullDate,
  signal?: AbortSignal
) {
  const response = await fetch(
    `${config.API_URL}/task/payment.php${stringify(
      cleanObject({
        de: de ? moment(de).format("YYYY-MM-DD") : null,
        ate: ate ? moment(ate).format("YYYY-MM-DD") : null
      })
    )}`,
    {
      headers: getTokenHeader(),
      signal
    }
  );

  return parseStatus<RedatorPagamento[]>(response);
}

export async function readNotification(data: { id: number }) {
  const response = await fetch(`${config.API_URL}/notification/read.php`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function sendEmailGestao(
  type: UserType,
  data: {
    to: string;
    nomeTarefa: string;
    link: string;
    site: string;
  }
) {
  const url =
    type === "revisor"
      ? "notification/emailRevisor.php"
      : "notification/emailAdmin.php";

  const response = await fetch(`${config.API_URL}/${url}`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function sendEmailRedator(data: {
  to: string;
  nome: string;
  nomeTarefa: string;
  link: string;
  site: string;
  vencimento: string;
  chave: string;
}) {
  const url = "notification/emailRedator.php";

  const response = await fetch(`${config.API_URL}/${url}`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}
export async function updateTaskStatus(
  data: { id: number; statusId: number },
  makeReaded = false
) {
  if (makeReaded) {
    await updateTaskEvent({
      id: data.id,
      type: "readedAt"
    });
  }

  const response = await fetch(`${config.API_URL}/task/status.php`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function updateTaskEssay(data: {
  id: number;
  essay: string;
  keywords: string;
}) {
  const response = await fetch(`${config.API_URL}/task/essay.php`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function updateTask(data: {
  id: number;
  editorId: number;
  projetoId: number;
  enviromentId: number;
}) {
  const response = await fetch(`${config.API_URL}/task/update.php`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function updateTaskEvent(data: {
  id: number;
  type: "readedAt" | "approvedAt" | "paidAt";
}) {
  const response = await fetch(`${config.API_URL}/task/event.php`, {
    body: JSON.stringify(data),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function removeTaskFile(id: number) {
  const response = await fetch(`${config.API_URL}/task/deleteFile.php`, {
    body: JSON.stringify({ id }),
    headers: getTokenHeader(),
    method: "POST"
  });

  return await parseStatus<null>(response);
}

export async function updateTaskFile(id: number, file: File) {
  const body = new FormData();
  body.append("file", file);

  const headers = new Headers({
    Authorization: `Bearer ${localStore.get(config.TOKEN_KEY)}`
  });

  const response = await fetch(`${config.API_URL}/task/file.php?id=${id}`, {
    body,
    headers,
    method: "POST"
  });

  return parseStatus<AddResponse>(response);
}

export async function getList<T extends object>(
  name: EntityName<T>,
  query?: object,
  signal?: AbortSignal
) {
  const response = await fetch(
    `${config.API_URL}/${name}/list.php${query ? stringify(cleanObject(query)) : ""
    }`,
    {
      headers: getTokenHeader(),
      signal
    }
  );

  return parseStatus<T[]>(response);
}

export async function getById<T extends object>(
  name: EntityName<T>,
  id: number,
  signal?: AbortSignal
) {
  const response = await fetch(`${config.API_URL}/${name}/key.php?id=${id}`, {
    headers: getTokenHeader(),
    signal
  });

  return parseStatus<T>(response);
}

export async function add<T extends object>(name: EntityName<T>, data: T) {

  const response = await fetch(`${config.API_URL}/${name}/add.php`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getTokenHeader(),
    method: "POST"
  });
  return parseStatus<AddResponse>(response);
}

export async function update<T extends object>(name: EntityName<T>, data: T) {
  const response = await fetch(`${config.API_URL}/${name}/update.php`, {
    body: JSON.stringify(cleanObject(data)),
    headers: getTokenHeader(),
    method: "POST"
  });

  return parseStatus<null>(response);
}

export async function disable<T extends object>(
  name: EntityName<T>,
  id: number
) {
  const response = await fetch(`${config.API_URL}/${name}/disable.php`, {
    body: JSON.stringify({ id }),
    headers: getTokenHeader(),
    method: "POST"
  });

  return await parseStatus<null>(response);
}

export async function remove<T extends object>(
  name: EntityName<T>,
  id: number
) {
  const response = await fetch(`${config.API_URL}/${name}/delete.php`, {
    body: JSON.stringify({ id }),
    headers: getTokenHeader(),
    method: "POST"
  });

  return await parseStatus<T>(response);
}

export const addOrUpdate = <T extends AddResponse>(
  name: EntityName<T>,
  data: T
): Promise<AddResponse | null> =>
  data.id > 0 ? update(name, data) : add(name, data);


export const addOne = <T extends AddResponse>(
  name: EntityName<T>,
  data: T
): Promise<AddResponse | null> => add(name, data);