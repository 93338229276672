import React, { Component } from 'react'
import { classNames } from '../../../helpers/misc'

type Props = {
    value: string
    meta: MetaProps
    textarea: TextAreaProps
    placeholder?: string
    label?: string
    disabled?: boolean
    onChange?: (value: string, type: ChangeType) => void
    refEl?: (el: HTMLTextAreaElement | null) => void
}

export class TextAreaInput extends Component<Props> {
    static defaultProps: Pick<Props, 'meta' | 'textarea'> = {
        textarea: {},
        meta: {}
    }

    render() {
        const {
            refEl,
            meta,
            value,
            placeholder,
            disabled,
            label,
            textarea
        } = this.props
        const hasError = meta.touched && !!meta.error

        return (
            <div
                className={classNames('form-input', {
                    'is-danger': hasError
                })}
            >
                {label && <label>{label}</label>}

                <textarea
                    {...textarea}
                    disabled={disabled}
                    placeholder={placeholder}
                    ref={el => refEl && refEl(el)}
                    onChange={ev => this._handleChange(ev.target.value, 'key')}
                    onBlur={ev => this._handleChange(ev.target.value, 'blur')}
                    value={value}
                />

                {hasError && <p className="help is-danger">{meta.error}</p>}
            </div>
        )
    }

    _handleChange(value: string, type: ChangeType) {
        const { props } = this

        if (!!props.onChange) {
            props.onChange(value, type)
        }
    }
}
