import { createLocation } from "history";
import React, { Component } from "react";
import { history } from "../../../utils/history";
import { localStore } from "../../../utils/store";
const isModifiedEvent = (event: React.MouseEvent<HTMLAnchorElement>) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

type Props = {
  to: string;
  title?: string;
  className?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  children?: React.ReactNode;
};

export class Link extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(newProps: Props) {
    const { props } = this;

    return (
      newProps.to !== props.to ||
      newProps.children !== props.children ||
      newProps.className !== props.className
    );
  }

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { props } = this;

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      localStore.set("enviromentName", props.title ? props.title : "");
      event.preventDefault();

      history.push(props.to);
    }
  };

  render() {
    const { to, ...props } = this.props;

    const href = history.createHref(
      createLocation(to, null, undefined, history.location)
    );

    return <a {...props} onClick={this.handleClick} href={href} />;
  }
}
