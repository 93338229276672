type ClassName = string | undefined | null
type NameClasses = ClassName | ClassName[] | { [key: string]: boolean | null | undefined }

export const isVisible = (hideWhen = false) => !hideWhen

export function forceDownload(blob: Blob, name: string) {
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.style.display = 'none'
    link.href = blobUrl
    link.download = name
    link.click()
    URL.revokeObjectURL(blobUrl)
}

export function classNames(...args: NameClasses[]): string {
    const classes: string[] = []

    args.map(arg => {
        if (!!arg) {
            if (typeof arg === 'string' || typeof arg === 'number') {
                classes.push(arg.toString())
            } else if (Array.isArray(arg) && arg.length) {
                const inner = classNames(...arg)

                if (inner) {
                    classes.push(inner)
                }
            } else if (typeof arg === 'object' && !Array.isArray(arg) && arg !== null) {
                for (const [key, value] of Object.entries(arg)) {
                    if (value) {
                        classes.push(key)
                    }
                }
            }
        }
    })

    return classes.join(' ')
}

// Object
export const fillObject = <T extends object, V>(obj: T, value: V): ObjectOf<T, V> =>
    Object
        .entries(obj)
        .reduce(
            (acc, [k, v]) => ({ ...acc, [k]: v instanceof Object ? fillObject(v, value) : value }),
            {}
        )

export const isObject = (v: any): v is object =>
    v instanceof Object && !(v instanceof Array) && !(v instanceof Date)

export const isEmptyOrDefault = (v: any) =>
    v === null ||
    v === undefined ||
    v instanceof Array && v.length === 0 ||
    isObject(v) && isEqual(v, {}) ||
    typeof v === 'number' && v === 0 || typeof v === 'string' && v === ''

export const cleanObject = <T extends object>(obj: T): Partial<T> =>
    Object
        .entries(obj)
        .map(([k, v]) => isObject(v) ? [k, cleanObject(v)] : [k, v])
        .filter(([_, v]) => !isEmptyOrDefault(v))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})

export const pick = <O, K extends keyof O>(ks: K[], o: O): Pick<O, K> => {
    const copy: any = {}
    ks.forEach(k => (copy[k] = o[k]))
    return copy
}

export function isEqual<T extends object, K extends keyof T>(a: T, b: T, keys?: K[]) {
    if (!keys) {
        return JSON.stringify(a) === JSON.stringify(b)
    } 
    
    return JSON.stringify(pick(keys, a)) === JSON.stringify(pick(keys, b))
}

// Fn
export function debounce(fn: any, time: number) {
    let timeout: NodeJS.Timer

    return function (this: any, ...args: any[]) {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => fn.apply(context, args), time)
    }
}
