import moment from 'moment'
import React from 'react'
import { InjectedOnClickOutProps } from 'react-onclickoutside'
import {
    add,
    allDaysDisabledAfter,
    allDaysDisabledBefore,
    getWeekdayMinInLocale,
    set,
    subtract
} from './date_utils'
import { Month } from './month'
import { MonthDropdown } from './month_dropdown'
import { YearDropdown } from './year_dropdown'

const DROPDOWN_FOCUS_CLASSNAMES = [
    'react-datepicker__year-select',
    'react-datepicker__month-select',
    'react-datepicker__month-year-select'
]

const isDropdownSelect = (element: Partial<HTMLElement> = {}) => {
    const classes = (element.className || '').split(/\s+/)
    return DROPDOWN_FOCUS_CLASSNAMES.some(
        testClassname => classes.indexOf(testClassname) >= 0
    )
}

type Props = InjectedOnClickOutProps & {
    usePast: boolean
    maxDate: NullDate
    minDate: NullDate
    dateView: moment.Moment
    selected: NullDate
    onClickOutside(event: React.MouseEvent<HTMLDivElement>): void
    onSelect(date: moment.Moment): void
    onDropdownFocus(): void
    setOpen(opened: boolean): void
    setDateView(dateLambda: (date: moment.Moment) => moment.Moment): void
}

export class Calendar extends React.PureComponent<Props> {
    handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        this.props.onClickOutside(event)
    }

    handleDropdownFocus = (event: React.FocusEvent<HTMLDivElement>) => {
        if (isDropdownSelect(event.target)) {
            this.props.onDropdownFocus()
        }
    }

    increaseMonth = () =>
        this.props.setDateView(dateView => add(dateView, 1, 'month'))

    decreaseMonth = () =>
        this.props.setDateView(dateView => subtract(dateView, 1, 'month'))

    changeMonth = (month: number) =>
        this.props.setDateView(dateView => set(dateView, { month }))

    changeYear = (year: number) =>
        this.props.setDateView(dateView => set(dateView, { year }))

    onDaySelect = (day: moment.Moment) => this.props.onSelect(day)

    render() {
        const { props } = this
        const allPrevDaysDisabled = allDaysDisabledBefore(
            props.dateView,
            'month',
            props.minDate
        )
        const allNextDaysDisabled = allDaysDisabledAfter(
            props.dateView,
            'month',
            props.maxDate
        )
        const startOfWeek = props.dateView.clone().startOf('week')

        return (
            <div className="react-datepicker">
                <div className="react-datepicker__triangle" />

                {allPrevDaysDisabled ? null : (
                    <button
                        type="button"
                        className="react-datepicker__navigation react-datepicker__navigation--previous"
                        onClick={this.decreaseMonth}
                    />
                )}

                {allNextDaysDisabled ? null : (
                    <button
                        type="button"
                        className="react-datepicker__navigation react-datepicker__navigation--next"
                        onClick={this.increaseMonth}
                    />
                )}

                <div className="react-datepicker__month-container">
                    <div className="react-datepicker__header">
                        <div
                            className="react-datepicker__current-month"
                            onFocus={this.handleDropdownFocus}
                        >
                            <MonthDropdown
                                onChange={this.changeMonth}
                                month={props.dateView.get('month')}
                            />

                            <YearDropdown
                                date={props.dateView}
                                onSelect={props.onSelect}
                                setOpen={props.setOpen}
                                onChange={this.changeYear}
                                minDate={props.minDate}
                                maxDate={props.maxDate}
                                year={props.dateView.get('year')}
                            />
                        </div>

                        <div className="react-datepicker__day-names">
                            {[0, 1, 2, 3, 4, 5, 6].map(offset => {
                                const day = add(startOfWeek, offset, 'days')
                                const weekDayName = getWeekdayMinInLocale(day)

                                return (
                                    <div
                                        key={offset}
                                        className="react-datepicker__day-name"
                                    >
                                        {weekDayName}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <Month
                        dateView={props.dateView}
                        onDayClick={this.onDaySelect}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        selected={props.selected}
                    />

                    <div className="react-datepicker__footer">
                        <button
                            type="button"
                            onClick={() => this.onDaySelect(moment())}
                        >
                            Hoje
                        </button>

                        <button
                            type="button"
                            onClick={() =>
                                this.onDaySelect(moment().add(props.usePast ? -7 : 7, 'd'))
                            }
                        >
                            7 Dias
                        </button>

                        <button
                            type="button"
                            onClick={() =>
                                this.onDaySelect(moment().add(props.usePast ? -15 : 15, 'd'))
                            }
                        >
                            15 Dias
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
