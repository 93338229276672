import React, { Component } from 'react'
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside'
import UserAvatar from '../../assets/img/user.svg'
import { classNames } from '../../helpers/misc'
import { logOut } from '../../service/api'
import { Link } from '../route/link'
import { Page } from './Panel'

type Props = InjectedOnClickOutProps & {
    setMenu: (state: boolean | ((prev: boolean) => boolean)) => void
    showMenu: boolean
    operator: Operator
    extraPages: Page[]
}

export const PanelNavbar = onClickOutside(
    class extends Component<Props> {
        handleClickOutside = () => {
            this.props.setMenu(false)
        }

        render() {
            const { props } = this

            return (
                <ul className="navbar">
                    <li className="dropdown">
                        <a
                            className="nav-link"
                            href="javascript:;"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => props.setMenu(x => !x)}
                        >
                            <div className="">
                                <span className="avatar">
                                    <img
                                        alt={props.operator.name}
                                        src={UserAvatar}
                                    />
                                </span>
                               
                            </div>
                        </a>
                        <div
                            className={classNames('dropdown-menu', {
                                show: props.showMenu
                            })}
                        >
                            <div className="dropdown-header">
                                <h6>Seja bem-vindo!</h6>
                            </div>

                            {props.extraPages.map((link, i) => (
                                <Link
                                    key={i}
                                    className="dropdown-item"
                                    to={link.href}
                                >
                                    {link.icon && (
                                        <i
                                            className={link.icon}
                                            aria-hidden="true"
                                        />
                                    )}
                                    <span>{link.title}</span>
                                </Link>
                            ))}

                            <div className="dropdown-divider" />

                            <a
                                href="javascript:;"
                                className="dropdown-item"
                                onClick={logOut}
                            >
                                <i
                                    className="fas fa-sign-out-alt"
                                    aria-hidden="true"
                                />
                                <span>Sair</span>
                            </a>
                        </div>
                    </li>
                </ul>
            )
        }
    }
)
