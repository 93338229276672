import React, { useEffect, useState } from "react";
import { __RouterContext } from "react-router";
import { RouteComponentProps } from "react-router";
import AsyncSelect from "react-select/lib/Async";
import { useForm } from "../../hooks/form";
import { getList } from "../../service/api";
import { Button } from "../button";
import DatePicker from 'react-datepicker';
import { Console } from "console";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR);

type Props = {
  onFilter: (value: InvoiceFilterParams) => void;
  route: Route;
  operator: Operator;
};

type Route = RouteComponentProps<{ id?: string }>;

const InvoiceFilter = ({ route, operator, onFilter }: Props) => {
  const [datePickerSelected, setDatePickerSelected] = useState<Date>(new Date());
  const [userSelected, setUserSelected] = useState<number>(0);
  const [redatorList, setRedatorList] = useState<User[]>([]);
  const [currentFilter, setCurrentFilter] = useState<InvoiceFilterParams>({
    userId: 0,
    filterUserByToken: new Boolean(false),
    referenceMonth: new Date().getMonth(),
    referenceYear: new Date().getFullYear()
  });

  const form = useForm<InvoiceFilterParams>(
    {
      initialEntity: {
        userId: 0,
        filterUserByToken: new Boolean(false),
        referenceMonth: datePickerSelected.getMonth(),
        referenceYear: datePickerSelected.getFullYear()
      },
      validate: (values, err) => {
        return err;
      }
    },
    route
  );

  const { touched } = form;

  const endDate = new Date();

  useEffect(() => {
    setCurrentFilter({
      userId: userSelected,
      filterUserByToken: new Boolean(false),
      referenceMonth: datePickerSelected.getMonth(),
      referenceYear: datePickerSelected.getFullYear()
    })
  }, [datePickerSelected, userSelected]);

  function getRedatorName() {
    const redator = redatorList.find(x => x.id === userSelected)
    if (redator !== undefined) {
      return redator.name
    }
    else
      return "Nenhum redator"
  }

  async function getRedatores(name: string) {
    var reds = getList<User>("user", { type: "redator", name });
    setRedatorList(await reds);
    return (await reds).map(i => ({ label: i.name, value: i.id }));
  }

  return (
    <div className="box filterbox">
      <div className="columns is-variable is-5">

        <div className="column auto">
          <AsyncSelect<{
            label: string;
            value: number;
          }>
            defaultOptions={true}
            className="filtertop"
            cacheOptions={true}
            placeholder="Selecionar redator..."
            noOptionsMessage={() => "Nenhum redator localizado..."}
            loadOptions={getRedatores}
            onChange={args => {
              if (!!args && !(args instanceof Array)) {
                form.handleChange({
                  path: "userId",
                  values: {
                    userId: args.value
                  }
                });
                setUserSelected(args.value)
              } else {
                form.handleChange({
                  path: "userId",
                  values: {
                    userId: 0
                  }
                });
              }
            }}
            value={
              userSelected
                ? {
                  label: getRedatorName(),
                  value: userSelected
                }
                : null
            }
          />
        </div>

        <div className="column auto">
          <DatePicker
            className="button filtertop filterbtn is-medium is-fullwidth"
            selected={datePickerSelected}
            onChange={(date: Date) => setDatePickerSelected(date)}
            dateFormat="MM/yyyy"
            locale="ptBR"
            showMonthYearPicker
            maxDate={endDate}
            showFullMonthYearPicker
          />
        </div>

        <div className="column auto">
          <Button
            styleClass="filtertop filterbtn is-medium is-fullwidth"
            onClick={() => {
              setDatePickerSelected(new Date())
              setUserSelected(0)
            }}
            title="Limpar"
            icon="fas fa-eraser"
          />
        </div>
        <div className="column auto">
          <Button
            onClick={() => onFilter(currentFilter)}
            styleClass="filtertop filterbtn is-medium is-fullwidth"
            title="Aplicar filtro"
            icon="fas fa-check"
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceFilter;