export function createPathProxy<T>(path: PropertyKey[] = []): TypedPathProxy<T> {
    const proxy = new Proxy({}, {
        get(target: object, key: PropertyKey) {
            if (key === 'getPath') {
                return () => path
            }

            if (typeof key === 'string') {
                const intKey = parseInt(key, 10)
                if (key === intKey.toString()) {
                    key = intKey
                }
            }

            return createPathProxy([...path, key])
        }
    })

    return proxy as TypedPathProxy<T>
}

export function setValueInPath<T, V>(ent: T, paths: PropertyKey[], value: V): T {
    const newEnt = Object.assign({}, ent)

    paths.reduce(
        (o, key, index, keys) => {
            if (index < keys.length - 1) {
                o[key] = o[key] || (typeof keys[index + 1] === 'number' ? [] : {})
                return o[key]
            }
            o[key] = value
        },
        newEnt
    )

    return newEnt
}

export function inPath<T, V>(ent: T, path: PropAccessor<T>): V {
    if (path instanceof Function) {
        const paths = path(createPathProxy<T>()).getPath()

        return paths.reduce((e, p) => e && p in e ? e[p] : undefined, ent) as any as V
    }

    return ent[path] as any as V
}

export const getPropPath = <T>(acc: PropAccessor<T>) =>
    acc instanceof Function ? acc(createPathProxy<T>()).getPath() : [acc.toString()]

export const getPropName = <T>(acc: PropAccessor<T>) =>
    getPropPath(acc).join('.')