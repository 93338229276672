import moment from 'moment'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { isEqual } from '../../helpers/misc'
import { YearDropdownOptions } from './year_dropdown_options'

function generateYears(year: number, noOfYear: number, minDate: NullDate, maxDate: NullDate) {
    const list = []

    for (let i = 0; i < 2 * noOfYear + 1; i++) {
        const newYear = year + noOfYear - i
        let isInRange = true

        if (minDate) {
            isInRange = moment(minDate).year() <= newYear
        }

        if (maxDate && isInRange) {
            isInRange = moment(maxDate).year() >= newYear
        }

        if (isInRange) {
            list.push(newYear)
        }
    }

    return list
}

const WrappedYearDropdownOptions = onClickOutside(YearDropdownOptions)

type Props = {
    year: number
    date?: moment.Moment
    maxDate: NullDate
    minDate: NullDate
    onChange(year: number): void
    onSelect(date?: moment.Moment, event?: React.MouseEvent<HTMLDivElement>): void
    setOpen(opened: boolean): void
}

type State = {
    dropdownVisible: boolean
    yearsList: number[]
}

export class YearDropdown extends Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            dropdownVisible: false,
            yearsList: generateYears(
                props.year,
                5,
                props.minDate,
                props.maxDate
            )
        }

        this.shiftYears = this.shiftYears.bind(this)
        this.onChange = this.onChange.bind(this)
        this.toggleDropdown = this.toggleDropdown.bind(this)
    }

    shouldComponentUpdate(newProps: Props, newState: State) {
        const { props, state } = this

        return !isEqual(state, newState) || props.year !== newProps.year
    }

    shiftYears = (amount: number) =>
        this.setState(state => ({
            yearsList: state.yearsList.map(year => year + amount)
        }))

    onChange = (year: number) => {
        this.toggleDropdown()
        if (year === this.props.year) { return }
        this.props.onChange(year)
    }

    toggleDropdown = () =>
        this.setState(state => ({
            dropdownVisible: !state.dropdownVisible
        }))

    render() {
        const { state } = this

        return (
            <div
                className="react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--scroll"
            >
                {state.dropdownVisible ? (
                    <WrappedYearDropdownOptions
                        key="dropdown"
                        year={this.props.year}
                        yearsList={this.state.yearsList}
                        shiftYears={this.shiftYears}
                        onChange={this.onChange}
                        onCancel={this.toggleDropdown}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                    />
                ) : null}

                <div
                    key="read"
                    className="react-datepicker__year-read-view"
                    onClick={this.toggleDropdown}
                >
                    <span className="react-datepicker__year-read-view--selected-year">
                        {this.props.year}
                    </span>
                </div>
            </div>
        )
    }
}
