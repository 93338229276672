import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { classNames } from '../../helpers/misc'
import { getPayments, updateTaskEvent } from '../../service/api'
import { maskMoney } from '../../utils/mask'
import { Button } from '../button'
import { DateInput } from '../form/date-input'
import { ReadOnlyInput } from '../form/readonly-input'
import { ModalPortal } from '../modal-portal'
import { RequiredPageProps } from '../route/page'

type Props = RequiredPageProps

type PaymentForm = {
    de: NullDate
    ate: NullDate
    pagamentos: RedatorPagamento[]
}

type Modal = {
    opened: boolean
    payment: UserPayment | null
}

function makePayment(tasks: TaskPayment[]) {
    const promises = tasks.map(t =>
        updateTaskEvent({
            id: t.id,
            type: 'paidAt'
        })
    )

    return Promise.all(promises)
}

export const PaymentForm = ({ modal }: Props) => {
    const [paymentModal, setPaymentModal] = useState<Modal>({
        opened: false,
        payment: null
    })

    const [form, setForm] = useState<PaymentForm>({
        pagamentos: [],
        ate: null,
        de: null
    })

    const [refresh, setRefresh] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (refresh) {
            getPayments(form.de, form.ate)
                .then(pagamentos =>
                    setForm({
                        ...form,
                        pagamentos
                    })
                )
                .catch(err => modal.alert(err.message))

            setRefresh(false)
        }
    }, [refresh])

    return (
        <div className="content">
            <div className="columns">
                <div className="column is-6">
                    <div className="box is-clearfix">
                        <form>
                            <div className="columns">
                                <div className="column">
                                    <DateInput
                                        value={form.de}
                                        label="De"
                                        usePast={true}
                                        onChange={de =>
                                            setForm({
                                                ...form,
                                                de,
                                                ate:
                                                    de && moment(de).isSameOrBefore(moment())
                                                        ? new Date()
                                                        : form.ate
                                            })
                                        }
                                    />
                                </div>
                                <div className="column">
                                    <DateInput
                                        value={form.ate}
                                        label="Até"
                                        onChange={ate =>
                                            setForm({
                                                ...form,
                                                ate
                                            })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="control buttons is-right">
                                <Button
                                    styleClass="is-link"
                                    icon="fas fa-filter"
                                    title="Filtrar"
                                    onClick={() => setRefresh(true)}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="panels pagamento">
                {form.pagamentos.map(r => (
                    <div className="panel" key={r.id}>
                        <p className="panel-heading">
                            <span
                                className="name"
                                onClick={() =>
                                    setPaymentModal({
                                        opened: true,
                                        payment: r.payment
                                    })
                                }
                            >
                                {r.name}
                            </span>

                            <span className="tag is-warning">
                                {'Valor total: '}
                                {maskMoney(
                                    r.tasks.reduce(
                                        (acc, curr) =>
                                            acc +
                                            curr.wordCount * r.payment.pricePerWord +
                                            curr.planningValue,
                                        0
                                    )
                                )}
                            </span>
                        </p>
                        <span className="panel-block">Listagem de tarefas à pagar:</span>

                        {r.tasks.map((t, i) => (
                            <span key={i} className="panel-block">
                                <span className="panel-icon">
                                    <i className="fas fa-book" aria-hidden="true" />
                                </span>
                                <span className="tag">
                                    {maskMoney(
                                        t.wordCount * r.payment.pricePerWord + t.planningValue
                                    )}
                                </span>
                                {` ${t.name}`}
                            </span>
                        ))}

                        <div className="panel-block">
                            <Button
                                icon="fas fa-check-circle"
                                title="Marcar todos como pago"
                                styleClass="is-small is-fullwidth is-success"
                                loading={loading}
                                disabled={loading}
                                onClick={() => {
                                    setLoading(true)
                                    makePayment(r.tasks)
                                        .then(() => {
                                            setLoading(false)
                                            setRefresh(true)
                                        })
                                        .catch(err => modal.alert(err.message))
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <ModalPortal>
                <div
                    className={classNames('modal modal-action', {
                        'is-active': paymentModal.opened
                    })}
                >
                    <div
                        className="modal-background"
                        onClick={() =>
                            setPaymentModal({
                                opened: false,
                                payment: null
                            })
                        }
                    />

                    <div className="modal-content">
                        <div className="box is-clearfix">
                            {!!paymentModal.payment && (
                                <>
                                    <div className="columns">
                                        <div className="column">
                                            <ReadOnlyInput
                                                label="PayPal"
                                                value={paymentModal.payment.paypal}
                                            />
                                        </div>

                                        <div className="column">
                                            <ReadOnlyInput
                                                label="Titular"
                                                value={paymentModal.payment.holder}
                                            />
                                        </div>

                                        <div className="column">
                                            <ReadOnlyInput
                                                label="CPF/CNPJ"
                                                value={paymentModal.payment.cpfCnpj}
                                            />
                                        </div>
                                    </div>

                                    <div className="columns">
                                        <div className="column">
                                            <ReadOnlyInput
                                                label="Banco"
                                                value={paymentModal.payment.bankCode}
                                            />
                                        </div>

                                        <div className="column">
                                            <ReadOnlyInput
                                                label="Agência"
                                                value={paymentModal.payment.agency}
                                            />
                                        </div>

                                        <div className="column">
                                            <ReadOnlyInput
                                                label="Conta"
                                                value={paymentModal.payment.account}
                                            />
                                        </div>

                                        <div className="column">
                                            <ReadOnlyInput
                                                label="Operação"
                                                value={paymentModal.payment.operation}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <Button
                        className="modal-close is-large"
                        aria-label="close"
                        onClick={() =>
                            setPaymentModal({
                                opened: false,
                                payment: null
                            })
                        }
                    />
                </div>
            </ModalPortal>
        </div>
    )
}
