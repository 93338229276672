import React, { Component } from 'react'
import { classNames, isEqual } from '../../helpers/misc'
import { Link } from '../route/link'

type ButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'type' | 'ref'>
interface Props extends ButtonProps {
    refEl?: (el: HTMLButtonElement | null) => void
    styleClass?: string
    icon?: string
    onClick?: string | ((el: HTMLButtonElement) => void)
    loading: boolean
}

export class Button extends Component<Props> {
    static defaultProps: Pick<Props, 'loading' | 'className'> = {
        className: 'button',
        loading: false
    }

    constructor(props: Props) {
        super(props)
        this._onClick = this._onClick.bind(this)
    }

    shouldComponentUpdate(next: Props) {
        return !isEqual(next, this.props)
    }

    getContent(icon?: string, title?: string) {
        return (
            <>
                {!!icon && (
                    <span className="icon is-small">
                        <i className={icon} aria-hidden="true" />
                    </span>
                )}

                {!!title && <span>{title}</span>}
            </>
        )
    }

    render() {
        const { loading, onClick, styleClass, icon, title, className, refEl, ...props } = this.props
        const buttonClassName = classNames(className, styleClass, {
            'is-loading': loading
        })

        return typeof onClick === 'string' ? (
            <Link className={buttonClassName} to={onClick}>
                {this.getContent(icon, title)}
            </Link>
        ) : (
                <button
                    {...props}
                    ref={el => !!refEl && refEl(el)}
                    type="submit"
                    className={buttonClassName}
                    onClick={this._onClick}
                >
                    {this.getContent(icon, title)}
                </button>
            )
    }
    
    _onClick(ev: React.MouseEvent<HTMLButtonElement>) {
        const { onClick } = this.props

        if (!!onClick && typeof onClick !== 'string') {
            ev.preventDefault()
            onClick(ev.target as HTMLButtonElement)
        }
    }
}
