import { onlyNumbers } from './format'

type CalculatorFunc = (d: number[], m: number) => number

const isRepeatingChars = (txt: string) => txt.split('').every(char => char === txt.charAt(0))

const CPFSum = (list: number[], multiplier: number) => list.reduce((result, num) => result + num * multiplier--, 0)
const CNPJSum = (list: number[], multiplier: number) =>
    list.reduce(
        (result, num) => {
            multiplier = multiplier === 1 ? 9 : multiplier
            return result + num * multiplier--
        },
        0
    )

const getValidationDigit = (digits: number[], multiplier: number, calculator: CalculatorFunc) => {
    const num = calculator(digits, multiplier) % 11
    return num > 1 ? 11 - num : 0
}

export const isEmail = (emailTxt: string) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailTxt)

export const isCPF = (cpfTxt: string) => {
    cpfTxt = onlyNumbers(cpfTxt)

    if (cpfTxt.length !== 11 || isRepeatingChars(cpfTxt)) {
        return false
    }

    const digits = cpfTxt.substring(0, 9).split('').map(n => parseInt(n, 10))
    const checker = cpfTxt.substring(9)

    const firstDigit = getValidationDigit(digits, 10, CPFSum)
    const secondDigit = getValidationDigit([...digits, firstDigit], 11, CPFSum)

    return checker === `${firstDigit}${secondDigit}`
}

export const isCNPJ = (cnpjTxt: string) => {
    cnpjTxt = onlyNumbers(cnpjTxt)

    if (cnpjTxt.length !== 14 || isRepeatingChars(cnpjTxt)) {
        return false
    }

    const digits = cnpjTxt.substring(0, 12).split('').map(n => parseInt(n, 10))
    const checker = cnpjTxt.substring(12)

    const firstDigit = getValidationDigit(digits, 5, CNPJSum)
    const secondDigit = getValidationDigit([...digits, firstDigit], 6, CNPJSum)

    return checker === `${firstDigit}${secondDigit}`
}