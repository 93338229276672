import React, { useEffect, useState } from "react";
import { getInvoiceList, remove } from "../../service/api";
import { app } from "../../service/firebase";
import { Button } from "../button";
import InvoiceFilter from "../filters/Invoice-Filter";
import { List } from "../list";
import { RequiredPageProps } from "../route/page";

type Props = RequiredPageProps;

export const InvoiceList = ({ modal, operator, route }: Props) => {
  const [refresh, setRefresh] = useState(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [showFilter, setShowFilter] = useState(false);

  const [filter, setFilter] = useState<InvoiceFilterParams>({
    userId: operator.type === "redator" ? operator.id : 0,
    filterUserByToken: operator.type === "redator" ? true : false,
    referenceMonth: new Date().getMonth(),
    referenceYear: new Date().getFullYear()
  });


  useEffect(() => {
    if (refresh) {
      console.log(filter)
      getInvoiceList(filter)
        .then(items => {
          setInvoices(items);
        })
        .catch(err => modal.alert(err.message));

      setRefresh(false);
    }
  }, [refresh]);

  function forceDownloadUrl(url: string, fileNameParam: string) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileNameParam;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  function filterInvoices(params: InvoiceFilterParams) {
    params.filterUserByToken = false;
    setFilter(params);
    setRefresh(true);
  }

  function removeInvoice(entity: Invoice) {

    remove<Invoice>('invoice', entity.id)
      .then(() => {
        const storageRef = app.storage().ref()
        var desertRef = storageRef.child(entity.file);

        desertRef.delete().then(function () {
          console.log("removido fo firebase storage")
        }).catch(function (error) {
          console.log("não foi possível remover")
        });
        setRefresh(true)
      })
      .catch(err => modal.alert(err.message))
  }

  return (
    <div className="content">
      <h3>Notas Fiscais</h3>
      {["admin", "gerente", "financeiro"].includes(operator.type) && (
        <div>
          <div className="columns is-gapless">

            <div className="column is-right">
              <div className="buttons is-right">
                <Button
                  onClick={() => setShowFilter(!showFilter)}
                  title="Filtrar"
                  icon="fas fa-filter"
                />
              </div>
            </div>

          </div>
          <div>
            {showFilter && (
              <InvoiceFilter
                operator={operator}
                route={route}
                onFilter={filterInvoices}
              />
            )}
          </div>

          <div>
            <List<Invoice>
              primaryKey={'id'}
              columns={[
                { title: '#', path: 'id' },
                // { title: 'Descrição', path: 'description'},
                { title: 'Arquivo', path: 'file' },
                { title: 'Valor', path: 'value', format: 'money' },
                { title: 'Usuário', path: 'userName' }
              ]}
              buttons={[
                {
                  action: entity => forceDownloadUrl(entity.urlFile, entity.file),
                  icon: 'fas fa-download',
                  styleClass: 'is-info',
                  title: 'Baixar'
                }
              ]}

              items={invoices}
              emptyMessage="Nenhuma nota encontrada."
            />
          </div>
        </div>
      )}

      {["redator"].includes(operator.type) && (
        <div>
          <div className="columns is-gapless">

            <div className="column is-right">
              <div className="buttons is-right">
                <Button
                  onClick="/notasfiscais/cadastro"
                  title="Nota Fiscal"
                  icon="fas fa-plus"
                  styleClass="is-link is-medium"
                />
              </div>
            </div>

          </div>

          <div>
            <List<Invoice>
              primaryKey={'id'}
              columns={[
                { title: '#', path: 'id' },
                {
                  title: 'Mês Referência',
                  path: 'referenceYear',
                  format: e => (`${("0" + (e.referenceMonth)).slice(-2)}/${e.referenceYear}`)
                },
                { title: 'Valor', path: 'value', format: 'money' },
                { title: 'Arquivo', path: 'file' }
              ]}
              buttons={[
                {
                  action: entity => forceDownloadUrl(entity.urlFile, entity.file),
                  icon: 'fas fa-download',
                  styleClass: 'is-info',
                  title: 'Baixar'
                },
                {
                  action: entity =>
                    modal.confirm(
                      `Deseja remover a nota '${entity.file}'?`,
                      confirmed =>
                        confirmed &&
                        removeInvoice(entity)
                    ),
                  icon: 'fas fa-times',
                  styleClass: 'is-danger',
                  title: 'Remover'
                }
              ]}
              items={invoices}
              emptyMessage="Nenhuma nota encontrada."
            />
          </div>
        </div>
      )}

    </div>
  );
};