import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { FormProps, useForm } from '../../hooks/form'
import { addOrUpdate, getById } from '../../service/api'
import { requiredMessage } from '../../utils/misc'
import { parseNumber } from '../../utils/parse'
import { Button } from '../button'
import { ErrorMessage } from '../form/error-message'
import { ReadOnlyInput } from '../form/readonly-input'
import { TextInput } from '../form/text-input'
import { ModalActions } from '../panel'
import { RequiredPageProps } from '../route/page'

type Route = RouteComponentProps<{ id?: string }>
type Props = RequiredPageProps

function addOrUpdateProjeto(
    projeto: Projeto,
    route: Route,
    form: FormProps<Projeto>,
    modal: ModalActions
) {
    addOrUpdate<Projeto>('projeto', projeto)
        .then(() => {
            form.setSubmitting(false)
            modal.alert(
                form.hasId
                    ? 'Projeto atualizado com sucesso!'
                    : 'Projeto criado com sucesso!',
                () => route.history.push('/projetos')
            )
        })
        .catch(err => form.setErrors({ _error: err.message }))
}

export const ProjetoForm = ({ route, modal }: Props) => {
    const form = useForm<Projeto>(
        {
            initialEntity: {
                id: 0,
                name: '',
                link: ''
            },
            validate: (values, err) => {
                if (!values.name) {
                    err.name = requiredMessage
                }

                if (!values.link) {
                    err.link = requiredMessage
                }

                return err
            }
        },
        route
    )

    const { entity, errors, touched } = form

    useEffect(() => {
        if (form.hasId) {
            form.handleFetch({
                action: (id, ac) =>
                    getById<Projeto>('projeto', parseNumber(id), ac.signal),
                errorFn: err => form.setErrors({ _error: err.message })
            })
        }
    }, [])

    return (
        <form
            className="content"
            onSubmit={ev => {
                ev.preventDefault()
                form.handleSubmit(ent =>
                    addOrUpdateProjeto(ent, route, form, modal)
                )
            }}
        >
            <label>
                <h4>Adicionar site</h4>
            </label>
            <div className="columns">
                <div className="column">
                    <TextInput
                        label="Nome"
                        value={entity.name}
                        input={{
                            autoComplete: 'name'
                        }}
                        meta={{
                            error: errors.name,
                            touched: touched.name
                        }}
                        onChange={(name, type) =>
                            form.handleChange({
                                path: 'name',
                                type,
                                values: { name }
                            })
                        }
                    />
                </div>

                <div className="column">
                    <TextInput
                        label="URL"
                        value={entity.link}
                        meta={{
                            error: errors.link,
                            touched: touched.link
                        }}
                        onChange={(link, type) =>
                            form.handleChange({
                                path: 'link',
                                type,
                                values: { link }
                            })
                        }
                    />
                </div>
            </div>

            <ErrorMessage error={errors._error} />

            <div className="buttons is-right">
                <Button
                    icon="fas fa-save"
                    styleClass="is-link is-medium"
                    loading={form.submitting}
                    disabled={form.submitting}
                    title="Salvar"
                />
            </div>
        </form>
    )
}
