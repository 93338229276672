import React from "react";
import { __RouterContext } from "react-router";
import { RouteComponentProps } from "react-router";
import AsyncSelect from "react-select/lib/Async";
import { useForm } from "../../hooks/form";
import { Button } from "../button";
import { DateInput } from "../form/date-input";
import { TextInput } from "../form/text-input";

type Props = {
  onFilter: (value: Filter) => void;
  route: Route;
  operator: Operator;
  currentFilter: Filter;
  projetoList: Projeto[];
  redatorList: User[];
};

type Route = RouteComponentProps<{ id?: string }>;

const TaskFilter = ({ route, onFilter, operator, currentFilter, projetoList, redatorList }: Props) => {
  

if(currentFilter.prazo !== null){
 currentFilter.prazo = new Date( new Date(currentFilter.prazo).setHours(23,59,59,999))
}

  const form = useForm<Filter>(
    {
      initialEntity: currentFilter,
      validate: (values, err) => {
        return err;
      }
    },
    route
  );

  const { entity, touched } = form;

  function getRedatorName(){
    const redator = redatorList.find(x => x.id === entity.redatorId)

    if(redator !== undefined)
      return redator.name
    else
      return "Nenhum redator"
  }

  function getProjetoName(){
    const projeto = projetoList.find(x => x.id === entity.projetoId)

    if(projeto !== undefined)
      return projeto.name
    else
      return "Nenhum projeto"
  }

  async function getRedatores(name: string) {
    entity.redatorId = 0
    return [{ label: "Nenhum redator", value: 0}]
      .concat(redatorList.map(i => ({ label: i.name, value: i.id })))
      .filter((i) =>
        new RegExp(name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
          i.label
        )
      )
    }

  async function getProjetos(name: string) {
    entity.projetoId = 0    
      return [{ label: "Nenhum projeto", value: 0}]
        .concat(projetoList.map(i => ({ label: i.name, value: i.id })))
        .filter((i) =>
        new RegExp(name.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi").test(
          i.label
        )
      )
  }

  return (
    <div className="box filterbox">
      <div className="columns is-variable is-2">
        <div className="column auto">
          <TextInput
            placeholder="Título da Pauta"
            value={entity.description}
            input={{
              autoComplete: "description"
            }}
            meta={{
              touched: touched.description
            }}
            onChange={(description, type) => {
              form.handleChange({
                path: "description",
                type,
                values: { description }
              });
            }}
          />
        </div>

        <div className="column auto">
          <DateInput
            placeholder="Vencimento"
            value={entity.prazo}
            meta={{
              touched: touched.prazo
            }}
            onChange={prazo => {
              form.handleChange({
                path: "prazo",
                values: { prazo }
              });
            }}
          />
        </div>

        <div className="column auto">
          {["admin", "gerente"].includes(operator.type) && (
            <AsyncSelect<{
              label: string;
              value: number;
            }>
              defaultOptions={true}
              className="filtertop"
              cacheOptions={true}
              placeholder="Selecionar site..."
              noOptionsMessage={() => "Nenhum site localizado..."}
              loadOptions={getProjetos}
              onChange={args => {
                if (!!args && !(args instanceof Array)) {
                  form.handleChange({
                    path: "projetoId",
                    values: {
                      projetoId: args.value
                    }
                  });
                } else {
                  form.handleChange({
                    path: "projetoId",
                    values: {
                      projetoId: 0
                    }
                  });
                }
              }}
              value={
                entity.projetoId                
                  ? {
                      label: getProjetoName(),
                      value: entity.projetoId
                    }
                  : null
              }
            />
          )}
        </div>

        <div className="column auto">
          {["admin", "gerente"].includes(operator.type) && (
            <AsyncSelect<{
              label: string;
              value: number;
            }>
              defaultOptions={true}
              className="filtertop"
              cacheOptions={true}
              placeholder="Selecionar redator..."
              noOptionsMessage={() => "Nenhum redator localizado..."}
              loadOptions={getRedatores}
              onChange={args => {
                if (!!args && !(args instanceof Array)) {
                  form.handleChange({
                    path: "redatorId",
                    values: {
                      redatorId: args.value
                    }
                  });
                } else {
                  form.handleChange({
                    path: "redatorId",
                    values: {
                      redatorId: 0
                    }
                  });
                }
              }}
              value={
                entity.redatorId
                  ? {
                      label: getRedatorName(),
                      value: entity.redatorId
                    }
                  : null
              }
            />
          )}
        </div>

        <div className="column auto">
          <Button
            styleClass="filtertop filterbtn is-medium is-fullwidth"
            onClick={() => {
              entity.description = ""
              entity.prazo = null
              entity.projetoId = 0
              entity.redatorId = 0

              onFilter(currentFilter);
            }}
            title="Limpar"
            icon="fas fa-eraser"
          />
        </div>
        <div className="column auto">
          <Button
            onClick={() => onFilter(entity)}
            styleClass="filtertop filterbtn is-medium is-fullwidth"
            title="Aplicar filtro"
            icon="fas fa-check"
          />
        </div>
      </div>
    </div>
  );
};

export default TaskFilter;
