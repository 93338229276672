import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { config } from '../../../config'
import { localStore } from '../../../utils/store'

export const PrivateRoute = ({ component: Component, render, ...rest }: RouteProps) =>
    (
        <Route
            {...rest}
            render={props => (
                !!localStore.get(config.TOKEN_KEY)
                    ? (Component ? <Component {...props} /> : render ? render(props) : <div>undefined component</div>)
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )}
        />
    )