import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { FormProps, useForm } from '../../hooks/form'
import { addOrUpdate, getById } from '../../service/api'
import { requiredMessage } from '../../utils/misc'
import { parseNumber } from '../../utils/parse'
import { Button } from '../button'
import { ErrorMessage } from '../form/error-message'
import { IntegerInput } from '../form/integer-input'
import { ReadOnlyInput } from '../form/readonly-input'
import { Select } from '../form/select'
import { TextInput } from '../form/text-input'
import { TextAreaInput } from '../form/textarea-input'
import { ModalActions } from '../panel'
import { RequiredPageProps } from '../route/page'

type Route = RouteComponentProps<{ id?: string }>
type Props = RequiredPageProps

function addOrUpdatePersona(
    persona: Persona,
    route: Route,
    form: FormProps<Persona>,
    modal: ModalActions
) {
    addOrUpdate<Persona>('persona', persona)
        .then(() => {
            form.setSubmitting(false)
            modal.alert(
                form.hasId
                    ? 'Persona atualizado com sucesso!'
                    : 'Persona criada com sucesso!',
                () => route.history.push('/personas')
            )
        })
        .catch(err => form.setErrors({ _error: err.message }))
}

export const PersonaForm = ({ route, modal }: Props) => {
    const form = useForm<Persona>(
        {
            initialEntity: {
                id: 0,
                name: '',
                age: 0,
                description: '',
                education: '',
                goals: '',
                operationArea: '',
                problems: '',
                resolution: '',
                segment: '',
                sex: 'M'
            },
            validate: (values, err) => {
                if (!values.name) {
                    err.name = requiredMessage
                }

                if (!values.age) {
                    err.age = requiredMessage
                }

                if (!values.description) {
                    err.description = requiredMessage
                }

                return err
            }
        },
        route
    )

    const { entity, errors, touched } = form

    useEffect(() => {
        if (form.hasId) {
            form.handleFetch({
                action: (id, ac) =>
                    getById<Persona>('persona', parseNumber(id), ac.signal),
                errorFn: err => form.setErrors({ _error: err.message })
            })
        }
    }, [])

    return (
        <form
            className="content"
            onSubmit={ev => {
                ev.preventDefault()
                form.handleSubmit(ent =>
                    addOrUpdatePersona(ent, route, form, modal)
                )
            }}
        >
            <div className="">
                <h4>Adicionar persona</h4>
            </div>
            
            <div className="columns">

                <div className="column">
                    <TextInput
                        label="Nome"
                        value={entity.name}
                        input={{
                            autoComplete: 'name'
                        }}
                        meta={{
                            error: errors.name,
                            touched: touched.name
                        }}
                        onChange={(name, type) =>
                            form.handleChange({
                                path: 'name',
                                type,
                                values: { name }
                            })
                        }
                    />
                </div>

                <div className="column">
                    <IntegerInput
                        label="Idade"
                        value={entity.age}
                        meta={{
                            error: errors.age,
                            touched: touched.age
                        }}
                        onChange={(age, type) =>
                            form.handleChange({
                                path: 'age',
                                type,
                                values: { age: age || 0 }
                            })
                        }
                    />
                </div>

                <div className="column">
                    <Select
                        label="Sexo"
                        getId={x => x.id}
                        getDisplay={x => x.name}
                        items={[
                            { id: 'F', name: 'Feminino' },
                            { id: 'M', name: 'Masculino' }
                        ]}
                        selected={entity.sex}
                        onChange={item =>
                            form.handleChange({
                                path: 'sex',
                                values: { sex: item!.id }
                            })
                        }
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <TextAreaInput
                        label="Descrição"
                        meta={{
                            error: errors.description,
                            touched: touched.description
                        }}
                        value={entity.description}
                        onChange={(description, type) =>
                            form.handleChange({
                                path: 'description',
                                type,
                                values: { description }
                            })
                        }
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <TextInput
                        label="Educação"
                        value={entity.education}
                        meta={{
                            error: errors.education,
                            touched: touched.education
                        }}
                        onChange={(education, type) =>
                            form.handleChange({
                                path: 'education',
                                type,
                                values: { education }
                            })
                        }
                    />
                </div>

                <div className="column">
                    <TextInput
                        label="Área de Atuação"
                        value={entity.operationArea}
                        meta={{
                            error: errors.operationArea,
                            touched: touched.operationArea
                        }}
                        onChange={(operationArea, type) =>
                            form.handleChange({
                                path: 'operationArea',
                                type,
                                values: { operationArea }
                            })
                        }
                    />
                </div>

                <div className="column">
                    <TextInput
                        label="Segmento"
                        value={entity.segment}
                        meta={{
                            error: errors.segment,
                            touched: touched.segment
                        }}
                        onChange={(segment, type) =>
                            form.handleChange({
                                path: 'segment',
                                type,
                                values: { segment }
                            })
                        }
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <TextAreaInput
                        label="Problemas"
                        meta={{
                            error: errors.problems,
                            touched: touched.problems
                        }}
                        value={entity.problems}
                        onChange={(problems, type) =>
                            form.handleChange({
                                path: 'problems',
                                type,
                                values: { problems }
                            })
                        }
                    />
                </div>

                <div className="column">
                    <TextAreaInput
                        label="Resolução"
                        meta={{
                            error: errors.resolution,
                            touched: touched.resolution
                        }}
                        value={entity.resolution}
                        onChange={(resolution, type) =>
                            form.handleChange({
                                path: 'resolution',
                                type,
                                values: { resolution }
                            })
                        }
                    />
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <TextInput
                        label="Objetivos"
                        value={entity.goals}
                        meta={{
                            error: errors.goals,
                            touched: touched.goals
                        }}
                        onChange={(goals, type) =>
                            form.handleChange({
                                path: 'goals',
                                type,
                                values: { goals }
                            })
                        }
                    />
                </div>
            </div>

            <ErrorMessage error={errors._error} />

            <div className="buttons is-right">
                <Button
                    icon="fas fa-save"
                    styleClass="is-link is-medium"
                    loading={form.submitting}
                    disabled={form.submitting}
                    title="Salvar"
                />
            </div>
        </form>
    )
}
