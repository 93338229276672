import React, { useEffect, useState } from "react";
import { disable, getList } from "../../service/api";
import { Button } from "../button";
import { List } from "../list";
import { RequiredPageProps } from "../route/page";

type Props = RequiredPageProps;

export const UserList = ({ modal, operator }: Props) => {
  const [refresh, setRefresh] = useState(true);
  const [users, setUser] = useState<User[]>([]);

  useEffect(() => {
    if (refresh) {
      getList<User>("user")
        .then(items => setUser(items))
        .catch(err => modal.alert(err.message));

      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className="content">
      <div className="float-left">
          <h4>Usuários</h4>
      </div>

      <div className="buttons is-right">
        <Button
          onClick="/usuarios/cadastro"
          title="Novo Usuário"
          icon="fas fa-plus"
          styleClass="is-link is-medium"
        />
      </div>

      <List<User>
        primaryKey={"id"}
        columns={[
          { title: "#", path: "id" },
          { title: "Nome", path: "name" },
          { title: "E-mail", path: "email" },
          {
            title: "Tipo",
            path: "type",
            format: t => t.type.charAt(0).toUpperCase() + t.type.slice(1)
          }
        ]}
        buttons={
          operator.type === "admin"
            ? [
                {
                  action: "/usuarios/cadastro/:id",
                  styleClass: "is-info",
                  title: "Editar",
                  icon: "fas fa-edit"
                },
                {
                  action: entity =>
                    modal.confirm(
                      `Deseja desativar o vendedor '${entity.name}'?`,
                      confirmed =>
                        confirmed &&
                        disable<User>("user", entity.id)
                          .then(() => setRefresh(true))
                          .catch(err => modal.alert(err.message))
                    ),
                  icon: "fas fa-times",
                  styleClass: "is-danger",
                  hideWhen: ent => ent.disabled,
                  title: "Excluir"
                }
              ]
            : []
        }
        items={users}
        emptyMessage="Nenhum usuário encontrado."
      />
    </div>
  );
};
