import React, { useContext, useEffect, useState } from "react";
import {
  __RouterContext,
  Route,
  RouteComponentProps,
  Switch
} from "react-router";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import useForceUpdate from "use-force-update";
import { config } from "../../config";
import { passVoid } from "../../helpers/functional";
import { classNames } from "../../helpers/misc";
import { getList } from "../../service/api";
import { history } from "../../utils/history";
import { localStore } from "../../utils/store";
import { Button } from "../button";
import { TextInput } from "../form/text-input";
import { ModalPortal } from "../modal-portal";
import { Link } from "../route/link";
import { PageRoute, RequiredPageProps } from "../route/page";
import { PanelBadge } from "./PanelBadge";
import { PanelNavbar } from "./PanelNavbar";
import Logo from '../../assets/img/logo-white.svg'

export type Page = {
  title: string;
  href: string;
  icon?: string;
  hideWhen?: (op: Operator) => boolean;
  component: React.ComponentType<RequiredPageProps>;
};

type UnlinkedPage = {
  path: string | string[];
  component: React.ComponentType<RequiredPageProps>;
  hideWhen?: (op: Operator) => boolean;
};

type Props = {
  pages: Page[];
  extraPages?: Page[];
  path: string;
  route: RouteComponentProps<any>;
  routes?: UnlinkedPage[];
  redirectHandler?: (operator: Operator) => React.ReactNode;
  homeRender?: (operator: Operator) => React.ReactNode;
};

type ModalMessage = string | JSX.Element;
type OnSendFn = (value: string) => void;
type OnActFn = (accepted: boolean) => void;
type ModalState = {
  alert: {
    opened: boolean;
    message: ModalMessage;
    onClose?: VoidFn;
  };
  prompt: {
    opened: boolean;
    title: string;
    message: string;
    value: string;
    type?: InputType;
    onSend?: OnSendFn;
  };
  confirm: {
    opened: boolean;
    message: ModalMessage;
    onAct?: OnActFn;
  };
  image:{
    opened: boolean;
    title: string;
    imgUrl: string;
    onCloseImage?: VoidFn;
  }
};

export type ModalActions = {
  alert: (message: ModalMessage, onClose?: VoidFn) => void;
  prompt: (
    title: string,
    message: string,
    onSend: OnSendFn,
    type?: InputType
  ) => void;
  confirm: (message: ModalMessage, onAct: OnActFn) => void;
  image: (title: string, imgUrl: string, onCloseImage?: VoidFn) => void;
};

function closeModal(
  action: "close" | "submit" | "cancel",
  setModal: React.Dispatch<React.SetStateAction<ModalState>>
) {
  setModal(prev => {
    const { onClose = passVoid } = prev.alert;
    const { onSend = passVoid } = prev.prompt;
    const { onAct = passVoid } = prev.confirm;
    const { onCloseImage = passVoid} = prev.image;

    const type = prev.confirm.opened
      ? "confirm"
      : prev.prompt.opened
      ? "prompt"
      : prev.alert.opened
      ? "alert"
      : "image";

    if (type === "alert") {
      onClose();
    } else if (type === "prompt" && action !== "close") {
      onSend(prev.prompt.value);
    } else if (type === "confirm") {
      onAct(action === "submit");
    } else if (type === "image") {
      onCloseImage();
    }

    return {
      alert: {
        message: "",
        opened: false
      },
      confirm: {
        message: "",
        opened: false
      },
      prompt: {
        message: "",
        opened: false,
        title: "",
        value: ""
      },
      image: {
        opened: false,
        title: "",
        imgUrl: ""
      }
    };
  });
}

export const Panel = (props: Props) => {
  const deviceWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const [operator] = useState<Operator>(
    localStore.get(config.OPERATOR_KEY) || ({} as any)
  );

  const [hideSidebar, setHideSidebar] = useState(deviceWidth <= 768);
  const [showMenu, setShowMenu] = useState(false);
  const [modal, setModal] = useState<ModalState>({
    alert: {
      message: "",
      opened: false
    },
    confirm: {
      message: "",
      opened: false
    },
    prompt: {
      message: "",
      opened: false,
      title: "",
      value: ""
    },
    image: {
      opened: false,
      title: "",
      imgUrl: ""
    }
  });

  const { routes: _routes = [], route, extraPages = [] } = props;
  const pages = props.pages.filter(
    ({ hideWhen = () => false }) => !hideWhen(operator)
  );

  const allPages = pages
    .concat(extraPages)
    .filter(({ hideWhen = () => false }) => !hideWhen(operator));

  const routes = _routes.filter(
    ({ hideWhen = () => false }) => !hideWhen(operator)
  );

  const opened =
    modal.alert.opened || modal.prompt.opened || modal.confirm.opened || modal.image.opened;

  const modalActions = {
    alert: (message: ModalMessage, onClose?: VoidFn) => {
      setModal(prev => ({
        ...prev,
        alert: { opened: true, message, onClose }
      }));
    },
    confirm: (message: ModalMessage, onAct: OnActFn) => {
      setModal(prev => ({
        ...prev,
        confirm: { opened: true, message, onAct }
      }));
    },
    prompt: (
      title: string,
      message: string,
      onSend: OnSendFn,
      type: InputType = "text"
    ) => {
      setModal(prev => ({
        ...prev,
        prompt: {
          ...prev.prompt,
          message,
          onSend,
          opened: true,
          title,
          type
        }
      }));
    },
    image: (title: string, imgUrl: string, onClose?: VoidFn) => {
      setModal(prev => ({
        ...prev,
        image: { opened: true, title, imgUrl, onClose }
      }));
    },
  };

  const [refresh, setRefresh] = useState(true);
  const [ambientes, setAmbientes] = useState<Enviroment[]>([]);
  const forceUpdate = useForceUpdate();
  const routerContext = useContext(__RouterContext);
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    routerContext.history.listen(forceUpdate);

    setRefresh(true);
  }, [routerContext]);

  useEffect(() => {
    if (refresh) {
      getList<Enviroment>("enviroment").then(enviroments =>
        setAmbientes(enviroments)
      );

      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className="panel">
      {props.redirectHandler && props.redirectHandler(operator)}

      <div
        className={classNames("sidebar", {
          "is-collapsed": hideSidebar
        })}
      >
        <div className="columns logo">
          <div className="column is-four-fifths">
          <img
              alt="logo"
              onClick={event =>  window.location.href='/'}
              src={Logo}
             />   
          </div>
          <div className="column auto">
            <span style={{verticalAlign: "text-bottom"}} className="icon has-text-white">
              <i onClick={() => setHideSidebar(true)} className="fas fa-bars"></i>
            </span>
          </div>
        </div>
        

        <div className="sidebar-nav">
          <div>
            {pages.map((link, i) => {
              const href = link.href.replace("/:id?", "");
              return (
                <Link
                  key={i}
                  className={classNames("item", {
                    active: route.location.pathname === href
                  })}
                  to={href}
                >
                  {link.icon && (
                    <span className="icon">
                      <i className={link.icon} aria-hidden="true" />
                    </span>
                  )}
                  <span className="name" title={link.title}>
                    {link.title}
                  </span>
                </Link>
              );
            })}
          </div>

          {["admin", "gerente"].includes(operator.type) && (
            <div>
              <ul className="item">
                <li className="sidebar-dropdown">
                  <a
                    onClick={() => {
                      setClosed(!closed);

                      history.push("/tarefasAmbiente/");
                    }}
                  >
                    <i className="icon fas fa-chalkboard-teacher" />
                    <span>
                      Ambientes
                      <i
                        className={classNames("icon", "fas", "fa-angle-right", {
                          open: !closed
                        })}
                      />
                    </span>
                  </a>
                  <SlideDown closed={closed}>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <Link
                            key={0}
                            className={classNames("item", {
                              active:
                                route.location.pathname === `/tarefasAmbiente/`
                            })}
                            to={`/tarefasAmbiente/`}
                            title={"Todos Ambientes"}
                          >
                            <span className="icon">
                              <i
                                className="fas fa-clipboard-check"
                                aria-hidden="true"
                              />
                            </span>

                            <span className="name" title="Todos Ambientes">
                              Todos Ambientes
                            </span>
                          </Link>
                        </li>
                        {ambientes.map((amb, i) => {
                          return (
                            <li>
                              <Link
                                key={i}
                                className={classNames("item", {
                                  active:
                                    route.location.pathname ===
                                    `/tarefasAmbiente/${amb.id}`
                                })}
                                to={`/tarefasAmbiente/${amb.id}`}
                                title={amb.name}
                              >
                                <span className="icon">
                                  <i
                                    className="fas fa-caret-right"
                                    aria-hidden="true"
                                  />
                                </span>

                                <span className="name" title={amb.name}>
                                  {amb.name}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                        <li>
                          <Link
                            key={0}
                            className={classNames("item", {
                              active: route.location.pathname === `/ambiente`
                            })}
                            to={`/ambiente`}
                          >
                            <span className="icon">
                              <i
                                className="fas fa-users-cog"
                                aria-hidden="true"
                              />
                            </span>

                            <span className="name" title="Gerenciar Ambientes">
                              Gerenciar Ambientes
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </SlideDown>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames("sidebar-background", {
          "is-closed": hideSidebar
        })}
        onClick={() => setHideSidebar(true)}
      />

      <div id="main">
        <div
          className={classNames("page-topbar", {
            "hide-sidebar": !hideSidebar
          })}
        >
          <a href="javascript:;" className="side-burguer-toggle">
            <span style={{verticalAlign: "text-bottom"}} className="icon has-text-dark">
              <i onClick={() => setHideSidebar(false)} className="fas fa-bars"></i>
            </span>
          </a>

          <div className="right">
            <PanelBadge
              modal={modalActions}
              route={route}
              operator={operator}
            />
            <PanelNavbar
              operator={operator}
              extraPages={extraPages}
              setMenu={setShowMenu}
              showMenu={showMenu}
            />
          </div>
        </div>

        <Switch>
          {routes.map(({ path, component }) =>
            typeof path === "string" ? (
              <PageRoute
                key={path}
                exact={true}
                path={path}
                component={component}
                operator={operator}
                modal={modalActions}
              />
            ) : (
              path.map(pt => (
                <PageRoute
                  key={pt}
                  exact={true}
                  path={pt}
                  component={component}
                  operator={operator}
                  modal={modalActions}
                />
              ))
            )
          )}

          {allPages.map(pg => (
            <PageRoute
              key={pg.href}
              exact={true}
              path={pg.href}
              component={pg.component}
              operator={operator}
              modal={modalActions}
            />
          ))}

          <Route
            exact={true}
            path={props.path}
            render={() =>
              !!props.homeRender ? props.homeRender(operator) : <div />
            }
          />

          <Route
            render={() => (
              <div className="content">
                <h2>Erro 404 - Não encontrado.</h2>
                <p>
                  {`A página que você solicitou não pôde ser encontrada, entre em contato com o administrador ou tente novamente.`}
                </p>
              </div>
            )}
          />
        </Switch>

        <footer className="footerPanel">
          <p>Todos os Direitos Reservados</p>
        </footer>
      </div>

      <ModalPortal>
        <div
          className={classNames("modal modal-action", {
            "is-active": opened
          })}
        >
          <div
            className="modal-background"
            onClick={() => closeModal("close", setModal)}
          />

          <div className="modal-content">
            {modal.prompt.opened && (
              <div className="box is-clearfix">
                <h1 className="title is-4">{modal.prompt.title}</h1>

                <TextInput
                  label={modal.prompt.message}
                  placeholder="Digite..."
                  type={modal.prompt.type}
                  value={modal.prompt.value}
                  onChange={value =>
                    setModal(prev => ({
                      ...prev,
                      prompt: { ...prev.prompt, value }
                    }))
                  }
                />

                <div className="buttons is-right">
                  <Button
                    icon="fas fa-arrow-left"
                    title="Voltar"
                    onClick={_ => closeModal("close", setModal)}
                  />

                  <Button
                    styleClass="is-info"
                    icon="fas fa-check"
                    title="Enviar"
                    onClick={_ => closeModal("submit", setModal)}
                  />
                </div>
              </div>
            )}

            {modal.alert.opened && (
              <div className="box is-clearfix">
                <h1 className="title is-4">Alerta</h1>
                <p>{modal.alert.message}</p>
                <br />

                <div className="buttons is-right">
                  <Button
                    styleClass="is-link"
                    title="Ok"
                    onClick={_ => closeModal("submit", setModal)}
                  />
                </div>
              </div>
            )}

            {modal.confirm.opened && (
              <div className="box is-clearfix">
                <h1 className="title is-4">Atenção</h1>
                <p>{modal.confirm.message}</p>
                <br />

                <div className="buttons is-right">
                  <Button
                    styleClass="is-link"
                    title="Sim"
                    icon="fas fa-check"
                    onClick={_ => closeModal("submit", setModal)}
                  />

                  <Button
                    styleClass="is-danger"
                    title="Não"
                    icon="fas fa-times"
                    onClick={_ => closeModal("cancel", setModal)}
                  />
                </div>
              </div>
            )}

            {modal.image.opened && (
              <div className="box is-clearfix">
                <h1 className="title is-4">{modal.image.title}</h1>
                <img src={modal.image.imgUrl}/>

                <div className="buttons is-right">
                  <Button
                    styleClass="is-link"
                    title="Fechar"
                    onClick={_ => closeModal("submit", setModal)}
                  />
                </div>
              </div>
            )}
          </div>

          <Button
            className="modal-close is-large"
            aria-label="close"
            onClick={_ => closeModal("close", setModal)}
          />
        </div>
      </ModalPortal>
    </div>
  );
};
