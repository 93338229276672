import React from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router'
import { ModalActions } from '../../panel'

export type RequiredPageProps = {
    operator: Operator
    route: RouteComponentProps<any>
    modal: ModalActions
}

type Props = Omit<RouteProps, 'render'> & {
    operator: Operator
    modal: ModalActions
}

export const PageRoute = ({ component, operator, modal, ...rest }: Props) => {
    const Component = component as any
    return (
        <Route
            {...rest}
            render={r =>
                Component ? (
                    <Component route={r} operator={operator} modal={modal} />
                ) : (
                    <div>undefined component</div>
                )
            }
        />
    )
}
