import React, { useRef } from 'react'
import { app } from '../../service/firebase'
import { FileInput } from '../form/file-input'
import upload_img from '../../assets/img/cloud-upload.png'
import "../../assets/sass/upload.scss"
import { Button } from '../button'

const FileUploader = ({ onChange }: any) => {

    return (
        <>
            <div className="image-upload">
                <label htmlFor="file-input" className="button is-link is-right">Selecionar arquivo
                    {/* <img id="img-upload" src={upload_img} /> */}
                </label>

                <input id="file-input" type="file" accept='application/pdf,application/xml' onChange={(e) => onChange(e)} />

            </div>
        </>
    )

}

export default FileUploader;