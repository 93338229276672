import * as moment from 'moment'
import { stringify as _stringify } from 'qs'
import { applyMask } from './mask'
import { CnpjMask, CpfMask } from './misc'
import { isCNPJ } from './validate'

export const stringify = (obj: object, addPrefix = true) =>
    _stringify(obj, {
        addQueryPrefix: addPrefix,
        allowDots: true,
        encodeValuesOnly: true
    })

export const onlyNumbers = (txt: string) =>
    txt.replace(/[^\d]/g, '')

export const toLocaleDate = (date: string | number | Date) =>
    moment.utc(date).local().format('DD/MM/YYYY')

export const toLocaleDateTime = (date: string | number | Date) =>
    moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss')

export const maskCpfCnpj = (cpfcnpj: string) =>
    isCNPJ(cpfcnpj) ? applyMask(CnpjMask, cpfcnpj) : applyMask(CpfMask, cpfcnpj)