import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { inPath } from "../../helpers/path";
import { FormProps, useForm } from "../../hooks/form";
import { addOne, getArchivedTasks, getCountInvoice, getInvoiceList, operatorPath } from '../../service/api';
import { requiredMessage } from "../../utils/misc";
import { Button } from "../button";
import { DecimalInput } from "../form/decimal-input";
import { ErrorMessage } from "../form/error-message";
import { TextInput } from "../form/text-input";
import { ModalActions } from "../panel";
import { RequiredPageProps } from "../route/page";
import FileUploader from "./UploadForm";
import { app } from '../../service/firebase';
import DatePicker from 'react-datepicker';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import "../../assets/sass/datepicker.sass";
import "../../assets/sass/invoiceForm.scss";
import { emptyString } from "react-select/lib/utils";

type Route = RouteComponentProps<{ id?: string }>;
type Props = RequiredPageProps;

type InvoiceForm = Invoice;

export const InvoiceForm = ({ route, modal, operator }: Props) => {

  const [datePickerSelected, setDatePickerSelected] = useState<Date>(new Date());
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNameCreated, setFileNameCreated] = useState("");
  const [fileTextSelected, setFileTextSelected] = useState("");
  const [invFile, setInvFile] = useState(new Blob());
  const form = useForm<InvoiceForm>(
    {
      initialEntity: {
        id: 0,
        file: '',
        urlFile: '',
        userId: 0,
        value: 0,
        createAt: new Date(),
        referenceMonth: new Date().getMonth(),
        referenceYear: new Date().getFullYear(),
        userName: ''
      },
      validate: (values, err) => {
        if (fileName == "") {
          err.file = requiredMessage;
        }

        if (values.value < 0.01) {
          err.value = "Valor tem que ser maior que R$ 0,01";
        }
        return err;
      }
    },
    route
  );

  function onChangeFile(e: any) {
    const file = e.target.files[0]

    setFileName(file.name)
    setInvFile(file)
    setFileTextSelected(`Arquivo: ${file.name}`);
  }

  async function onFirebaseSave(ent: Invoice) {

    if (fileName == "")
      modal.alert("Nenhum arquivo foi selecionado");
    else if (ent.value < 0.01)
      modal.alert("Valor da nota não foi informado")
    else {
      ent.referenceMonth = datePickerSelected.getMonth() + 1
      ent.referenceYear = datePickerSelected.getFullYear()

      await getCountInvoice(ent.referenceMonth, ent.referenceYear, operator.id)
        .then((data) => {
          const sequence = Number(data) + 1
          console.log(sequence)
          ent.file = `nf-${("0" + ent.referenceMonth).slice(-2)}-${ent.referenceYear}-${operator.name.split(" ")[0]}-${("0" + sequence)}`
        })
        .catch(err => {
          console.log(err)
          form.setErrors({ _error: err.message })
        });

      const storageRef = app.storage().ref()
      const fileRef = storageRef.child(ent.file)

      await fileRef.put(invFile).then(() => {
        console.log("Upload File", ent.file)
      })
      var fileUrlInvoice = await fileRef.getDownloadURL()

      addInvoice(ent, fileUrlInvoice, route, form, modal);
    }
  }

  function addInvoice(
    invoice: Invoice,
    fileUrlInvoice: string,
    route: Route,
    form: FormProps<InvoiceForm>,
    modal: ModalActions
  ) {
    invoice.urlFile = fileUrlInvoice
    console.log(invoice)
    addOne<Invoice>("invoice", invoice)
      .then(() => {
        console.log("sucesso")
        form.setSubmitting(false);
        modal.alert(
          form.hasId
            ? "Não tem id"
            : "Nota fiscal criada com sucesso!",
          () => route.history.push("/notasfiscais")
        );
      })
      .catch(err => {
        console.log(err)
        form.setErrors({ _error: err.message })
      });
  }


  const { entity, errors, touched } = form;

  return (
    <form
      className="content"
      onSubmit={ev => {
        ev.preventDefault();
        onFirebaseSave(entity);
      }}
    >
      <label>
        <h4>Nota Fiscal</h4>
      </label>
      <div className="columns">
        <div className="column">
          <DecimalInput
            label="Valor da Nota"
            meta={{
              error: inPath(errors, e => e.value),
              touched: inPath(touched, t => t.value)
            }}
            precision={2}
            value={entity.value || 0}
            onChange={(value, type) =>
              form.handleChange({
                path: x => x.value,
                type,
                values: { value }
              })
            }
          />
        </div>

        <div className="column">
          <div className="invoice_form_align">
            <label>Mês de Referência</label>
            <DatePicker
              className="button"
              selected={datePickerSelected}
              onChange={(date: Date) => setDatePickerSelected(date)}
              dateFormat="MM/yyyy"
              locale="ptBR"
              showMonthYearPicker
              maxDate={new Date()}
              showFullMonthYearPicker
            />
          </div>
        </div>

        <div className="column">
          <div className="invoice_form_align">
            <div className="invoice_form_align_top"></div>
            <FileUploader onChange={onChangeFile}></FileUploader>
          </div>
        </div>

        <div className="column">
          <div className="invoice_form_align">
            <div className="invoice_form_align_top"></div>
            <div className="buttons is-right">
              <Button
                icon="fas fa-save"
                styleClass="is-link is-medium"
                loading={form.submitting}
                disabled={form.submitting}
                title="Salvar"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns secondLine">
        <div className="column"></div>
        <div className="column"></div>
        <div className="column" id="uploadedFile">{fileTextSelected}</div>
        <div className="column"></div>
      </div>

      <ErrorMessage error={errors._error} />
    </form>
  );
};