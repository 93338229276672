import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { config } from '../../config'
import { FormProps, useForm } from '../../hooks/form'
import { loginRequest } from '../../service/api'
import { requiredMessage } from '../../utils/misc'
import { localStore } from '../../utils/store'
import { isEmail } from '../../utils/validate'
import { Button } from '../button'
import { ErrorMessage } from '../form/error-message'
import { TextInput } from '../form/text-input'
import Logo from '../../assets/img/logo-white.svg'
import DatePicker from "react-datepicker";


type Route = RouteComponentProps<any>

type Props = {
    route: Route
}

function tryLogin(
    login: LoginRequest,
    route: Route,
    form: FormProps<LoginRequest>
) {
    loginRequest(login)
        .then(resp => {
            form.setSubmitting(false)
            localStore.set(config.TOKEN_KEY, resp.accessToken)
            localStore.set(config.OPERATOR_KEY, resp.user)

            route.history.push(`/`)
        })
        .catch(err => form.setErrors({ _error: err.message }))
}

export const Login = ({ route }: Props) => {
    const form = useForm<LoginRequest>(
        {
            initialEntity: {
                email: '',
                password: ''
            },
            validate: (values, err) => {
                if (!values.email) {
                    err.email = requiredMessage
                } else if (!isEmail(values.email)) {
                    err.email = 'Preencha o campo com um e-mail válido.'
                }

                if (!values.password) {
                    err.password = requiredMessage
                }

                return err
            }
        },
        route
    )

    const { entity, errors, touched } = form
    
    return (
        <div className="dialog--nux dialog-page">
            <div className="dialog--nux-header">
                <div className="dialog--nux-logo">
                <img
                    alt="logo"
                    className="logo login"
                    src={Logo}
                />                
                </div>

            </div>

            <div className="dialog--nux-container" id="dialog-container">
                <div className="dialog--nux-content">

                    <form
                        className="pt-3 dialog--nux-inner"
                        onSubmit={ev => {
                            ev.preventDefault()
                            form.handleSubmit(ent => tryLogin(ent, route, form))
                        }}
                    >
                        <div id="normal_login_contents">
          
                            <ErrorMessage error={errors._error} />

                            <TextInput
                                label="E-mail"
                                type="email"
                                value={entity.email}
                                input={{
                                    autoComplete: 'email'
                                }}
                                meta={{
                                    error: errors.email,
                                    touched: touched.email
                                }}
                                onChange={(email, type) =>
                                    form.handleChange({
                                        path: 'email',
                                        type,
                                        values: { email }
                                    })
                                }
                            />

                            <TextInput
                                label="Senha"
                                type="password"
                                value={entity.password}
                                ignoreEnter={false}
                                input={{
                                    autoComplete: 'current-password'
                                }}
                                meta={{
                                    error: errors.password,
                                    touched: touched.password
                                }}
                                onChange={(password, type) =>
                                    form.handleChange({
                                        path: 'password',
                                        type,
                                        values: { password }
                                    })
                                }
                            />

                            <div className="form-input form-input--button">
                                <Button
                                    styleClass="is-link is-large is-fullwidth"
                                    title="Entrar"
                                    loading={form.submitting}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
