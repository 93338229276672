import { parse as _parse } from 'qs'
import { onlyNumbers } from './format'

export const parseNumber = (txt: string = '') =>
    parseInt(`0${onlyNumbers(txt)}`, 10)

export function parseQueryString<T>(query: string): T {
    return _parse(query, {
        allowDots: true,
        ignoreQueryPrefix: true
    })
}

export const parseMoney = (value = '0'): number => {
    let decimalPart

    value
        .split(/\D/)
        .reverse()
        .filter(e => !!e)
        .map((numberPiece, ind, arr) => {
            if (ind === 0 && arr.length > 1) {
                decimalPart = numberPiece
            }
        })

    value = value.replace(/\D/g, '')
    value = value.replace(new RegExp(`${decimalPart}$`), `.${decimalPart}`)

    return Number.parseFloat(value)
}