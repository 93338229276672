import React from 'react'

type Props = Omit<InputProps, 'readOnly'> & {
    value: string | number
    icon?: string
    label?: string
    isStatic?: boolean
}

export const ReadOnlyInput = ({
    value,
    label,
    icon,
    isStatic,
    ...input
}: Props) => (
    <div className="form-input">
        {label && <label>{label}</label>}

        <input {...input} readOnly={true} value={value} />

        {icon && (
            <span className="icon is-small is-left">
                <i className={icon} />
            </span>
        )}
    </div>
)
