import moment from 'moment'
import { config } from '../config'

const expire = 'expire_'
const namespace = !!config.NAMESPACE ? `${config.NAMESPACE}_` : ''
const keyName = (key: string, prefix: string = '') =>
    `${prefix}${namespace}${key}`

const getExpirationDate = (key: string) => {
    const exp = localStorage.getItem(keyName(key, expire))

    return !!exp ? moment(exp) : moment(Number.MIN_VALUE)
}

const remove = (key: string) => {
    localStorage.removeItem(keyName(key, expire))
    localStorage.removeItem(keyName(key))
}

function set<T>(key: string, data: T, expireMinutes: number = 1430) {
    const expireDate = moment().add(expireMinutes, 'minutes')

    localStorage.setItem(keyName(key, expire), expireDate.toISOString())
    localStorage.setItem(keyName(key), JSON.stringify(data))
}

function get<T>(key: string): T | undefined {
    const expiration = getExpirationDate(key)
    const value = localStorage.getItem(keyName(key))

    if (!value || expiration.isSameOrBefore(new Date())) {
        remove(key)
        return undefined
    }

    return JSON.parse(value) as T
}

export const localStore = { set, get, remove }
