import "moment/locale/pt-br";
import React from "react";
import { render } from "react-dom";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Router, Switch } from "react-router";
import "./assets/sass/main.scss";
import { AmbienteForm } from "./components/entity/AmbienteForm";
import { AmbienteList } from "./components/entity/AmbienteList";
import { PaymentForm } from "./components/entity/PaymentForm";
import { PersonaForm } from "./components/entity/PersonaForm";
import { PersonaList } from "./components/entity/PersonaList";
import { ProjetoForm } from "./components/entity/ProjetoForm";
import { ProjetoList } from "./components/entity/ProjetoList";
import { TaskList } from "./components/entity/TaskList";
import { UserForm } from "./components/entity/UserForm";
import { UserList } from "./components/entity/UserList";
import { Login } from "./components/login";
import { Panel } from "./components/panel";
import { RequiredPageProps } from "./components/route/page";
import { PrivateRoute } from "./components/route/private";
import * as serviceWorker from "./serviceWorker";
import { history } from "./utils/history";
import { InvoiceList } from './components/entity/InvoiceList';
import { InvoiceForm } from './components/entity/InvoiceForm';

const rootElement = document.getElementById("root");
const Home = (_: RequiredPageProps) => (
  <div className="content">
    <div className="title is-5">Bem-vindo ao ContentSuno</div>
  </div>
);

if (rootElement !== null) {
  render(
    <Router history={history}>
      <Switch>
        <Route exact={true} path="/login" render={r => <Login route={r} />} />

        <PrivateRoute
          path="/"
          render={route => (
            <Panel
              path="/"
              route={route}
              homeRender={_ => <div />}
              extraPages={[
                {
                  component: UserForm,
                  href: "/meu-perfil",
                  title: "Meu Perfil",
                  icon: "fas fa-user"
                }
              ]}
              pages={[
                {
                  icon: "fas fa-home",
                  component: Home,
                  href: "/",
                  title: "Página Inicial"
                },
                {
                  icon: "fas fa-dollar-sign",
                  component: PaymentForm,
                  href: "/pagamentos",
                  title: "Pagamentos",
                  hideWhen: op => op.type !== "financeiro"
                },
                {
                  icon: "fas fa-check-circle",
                  component: TaskList,
                  href: "/tarefas/:id?",
                  title: "Todas as Tarefas",
                  hideWhen: op =>
                    ["admin", "gerente", "financeiro"].includes(op.type)
                },
                {
                  icon: "fas fa-id-card",
                  component: PersonaList,
                  href: "/personas",
                  title: "Personas",
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  icon: "fas fa-book-open",
                  component: ProjetoList,
                  href: "/projetos",
                  title: "Sites",
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  icon: "fas fa-user",
                  component: UserList,
                  href: "/usuarios",
                  title: "Usuários",
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  icon: "fas fa-receipt",
                  component: InvoiceList,
                  href: "/notasfiscais",
                  title: "Notas Fiscais",
                  hideWhen: op => !["redator","financeiro","admin","gerente"].includes(op.type)
                }
              ]}
              routes={[
                {
                  component: UserForm,
                  path: ["/usuarios/cadastro", "/usuarios/cadastro/:id"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  component: PersonaForm,
                  path: ["/persona/cadastro", "/persona/cadastro/:id"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  component: ProjetoForm,
                  path: ["/projeto/cadastro", "/projeto/cadastro/:id"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },

                {
                  component: AmbienteList,
                  path: ["/ambiente"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  component: AmbienteForm,
                  path: ["/ambiente/cadastro", "/ambiente/cadastro/:id"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  component: TaskList,
                  path: ["/tarefasAmbiente", "/tarefasAmbiente/:idx?"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  component: TaskList,
                  path: ["/tarefas", "/tarefas/:id?"],
                  hideWhen: op => !["admin", "gerente"].includes(op.type)
                },
                {
                  component: InvoiceForm,
                  path: ["/notasfiscais/cadastro", "/notasfiscais/cadastro/:id"],
                  hideWhen: op => !["redator"].includes(op.type)
                },
              ]}
            />
          )}
        />
      </Switch>
    </Router>,
    rootElement
  );
}

serviceWorker.register();
