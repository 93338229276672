import { Component } from 'react'
import { createPortal } from 'react-dom'

const modalElement = document.getElementById('modal')

export class ModalPortal extends Component {
    container = document.createElement('div')

    componentDidMount() {
        if (!!modalElement) {
            modalElement.appendChild(this.container)
        }
    }

    componentWillUnmount() {
        if (!!modalElement) {
            modalElement.removeChild(this.container)
        }
    }

    render() {
        const { props } = this
        
        return createPortal(props.children, this.container)
    }
}
