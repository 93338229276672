import { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { deepMerge } from '../helpers/functional'
import { fillObject } from '../helpers/misc'
import { getPropPath, setValueInPath } from '../helpers/path'

type FormState<T> = {
    displayName: string
    fetching: boolean
    submitting: boolean
    touched: FormTouched<T>
    errors: FormErrors<T>
}

export type FormProps<T> = FormState<T> & {
    entity: T
    hasId: boolean
    setSubmitting: (submitting: boolean) => void
    setErrors: (errors: FormErrors<T>) => void
    setValues: (ent: ((prev: Readonly<T>) => Partial<T>) | Partial<T>) => void
    handleChange: (args: ChangeProps<T>) => void
    handleSubmit: (onSubmit: OnSubmitFn<T>) => void
    handleFetch: <F1, F2>(props: FetchProps<F1, F2>) => void
    resetForm: () => void
}

type OnSubmitFn<T> = (entity: T) => void

type ChangeProps<T> = {
    values: Partial<T>
    type?: ChangeType
    path: PropAccessor<T>
}

type FetchProps<T, F> = {
    action: (id: string, ac: AbortController) => Promise<T>
    mapper?: (ent: T) => F | Promise<F>
    errorFn: (err: Error) => void
}

type FormSettings<T> = {
    initialEntity: Required<T>
    displayName?: (ent: T) => React.ReactText
    validate: (entity: T, errors: FormErrors<T>) => FormErrors<T>
}

const initialStatus = {
    displayName: '',
    fetching: false,
    submitting: false,
    errors: {},
    touched: {}
}

export function useForm<T>(
    settings: FormSettings<T>,
    route: RouteComponentProps<any>
): FormProps<T> {
    const [status, setStatus] = useState<FormState<T>>(initialStatus)
    const [entity, setEntity] = useState(settings.initialEntity)
    const abortControl = new AbortController()

    return {
        entity,
        hasId: !!route.match.params.id,
        submitting: status.submitting,
        fetching: status.fetching,
        displayName: status.displayName,
        errors: status.errors,
        touched: status.touched,
        handleSubmit: (onSubmit: OnSubmitFn<T>) => {
            const errors = settings.validate(entity, {})
            const canSubmit = Object.values(errors).length === 0

            setStatus(prev => ({
                ...prev,
                touched: fillObject(entity, true),
                submitting: canSubmit,
                errors
            }))

            const elem = document.activeElement

            if (elem instanceof HTMLInputElement) {
                elem.blur()
            }
            if (canSubmit) {
                onSubmit(entity)
            }
        },
        handleFetch: <F1, F2>({
            action,
            errorFn,
            mapper
        }: FetchProps<F1, F2>) => {
            const handleError = (err: any) => {
                setStatus(prev => ({ ...prev, fetching: false }))
                errorFn(err)
            }

            const finallyDo = (ent: T) => {
                setStatus(prev => ({
                    ...prev,
                    displayName: !!settings.displayName
                        ? settings.displayName(ent).toString()
                        : '',
                    fetching: false
                }))

                setEntity(prev => deepMerge(prev, ent as Required<T>))
            }

            setStatus(prev => ({ ...prev, fetching: true }))

            action(route.match.params.id, abortControl).then(resp => {
                const promEnt = !mapper ? (resp as any) : mapper(resp)

                if (promEnt instanceof Promise) {
                    promEnt
                        .then(ent => finallyDo(ent as any))
                        .catch(handleError)
                } else {
                    finallyDo(promEnt as T)
                }
            })
        },
        handleChange: ({ type = 'blur', path, values }: ChangeProps<T>) => {
            setEntity(prev => {
                const ent = Object.assign({}, prev, values)

                setStatus(prevStat => {
                    const errors = settings.validate(ent, {})
                    let touched = { ...prevStat.touched }

                    if (type === 'blur') {
                        const paths = getPropPath(path)
                        touched = setValueInPath(touched, paths, true)
                    }

                    return { ...prevStat, errors, touched }
                })

                return ent
            })
        },
        setSubmitting: (submitting: boolean) =>
            setStatus(prev => ({ ...prev, submitting })),
        setErrors: (errors: FormErrors<T>) =>
            setStatus(prev => ({
                ...prev,
                errors: Object.assign({}, prev.errors, errors),
                submitting: false
            })),
        setValues: (values: ((prev: Readonly<T>) => Partial<T>) | Partial<T>) =>
            setEntity(prev =>
                Object.assign(
                    {},
                    prev,
                    values instanceof Function ? values(prev) : values
                )
            ),
        resetForm: () => {
            setEntity(settings.initialEntity)
            setStatus(initialStatus)
        }
    }
}
