import moment from 'moment'
import React, { Component } from 'react'
import { range } from '../../helpers/functional'
import { classNames } from '../../helpers/misc'
import * as utils from './date_utils'

type Props = {
    dateView: moment.Moment
    selected: NullDate
    maxDate: NullDate
    minDate: NullDate
    onDayClick(day: moment.Moment): void
}

type State = {
    weekStart: moment.Moment
}

export class Month extends Component<Props, State> {
    readonly state: State
    constructor(props: Props) {
        super(props)

        this.state = {
            weekStart: props.dateView.clone().startOf('month').startOf('week')
        }

        this.renderWeek = this.renderWeek.bind(this)
    }

    componentWillReceiveProps(props: Props) {
        this.setState({
            weekStart: props.dateView.clone().startOf('month').startOf('week')
        })
    }

    renderDay(offset: number, month: number, week: number) {
        const { props, state } = this
        const startOfWeek = utils.add(state.weekStart, week, 'week').startOf('week')
        const day = utils.add(startOfWeek, offset, 'days')

        const weekday = day.get('day')
        const isDisabled = utils.isDayDisabled(day, props.minDate, props.maxDate)

        const classes = classNames(
            'react-datepicker__day',
            'react-datepicker__day--' + utils.getDayOfWeekCode(day),
            {
                'react-datepicker__day--disabled': isDisabled,
                'react-datepicker__day--outside-month': month !== day.get('month'),
                'react-datepicker__day--selected': utils.isSameDay(day, props.selected),
                'react-datepicker__day--today': utils.isSameDay(day, moment()),
                'react-datepicker__day--weekend': weekday === 0 || weekday === 6
            }
        )

        return (
            <div
                key={`${month}-${week}-${offset}`}
                className={classes}
                onClick={() => !isDisabled && props.onDayClick(day)}
                aria-label={`day-${day.get('date')}`}
                role="option"
            >
                {day.get('date')}
            </div>
        )
    }

    renderWeek(week: number) {
        const { props } = this
        const month = props.dateView.get('month')

        return (
            <div key={week} className="react-datepicker__week">
                {[0, 1, 2, 3, 4, 5, 6].map(offset => this.renderDay(offset, month, week))}
            </div>
        )
    }

    render() {
        const { props, state } = this
        const weekEnd = props.dateView.clone().endOf('month').endOf('week')
        const numberOfWeeks = Math.ceil(weekEnd.diff(state.weekStart, 'days') / 7)

        return (
            <div
                className="react-datepicker__month"
                role="listbox"
            >
                {range(0, numberOfWeeks - 1).map(this.renderWeek)}
            </div>
        )
    }
}
