import React, { useEffect, useState } from 'react'
import { getList, remove } from '../../service/api'
import { Button } from '../button'
import { List } from '../list'
import { RequiredPageProps } from '../route/page'

type Props = RequiredPageProps

export const ProjetoList = ({ modal }: Props) => {
    const [refresh, setRefresh] = useState(true)
    const [projetos, setProjeto] = useState<Projeto[]>([])

    useEffect(() => {
        if (refresh) {
            getList<Projeto>('projeto')
                .then(items => setProjeto(items))
                .catch(err => modal.alert(err.message))

            setRefresh(false)
        }
    }, [refresh])

    return (
        <div className="content">
            <div className="float-left">
                <h4>Sites</h4>
            </div>
            <div className="buttons is-right">
                <Button
                    onClick="/projeto/cadastro"
                    title="Novo Site"
                    icon="fas fa-plus"
                    styleClass="is-link is-medium"
                />
            </div>

            <List<Projeto>
                primaryKey={'id'}
                columns={[
                    { title: '#', path: 'id' },
                    { title: 'Nome', path: 'name' },
                    { title: 'URL', path: 'link' }
                ]}
                buttons={[
                    {
                        action: '/projeto/cadastro/:id',
                        styleClass: 'is-info',
                        title: 'Editar',
                        icon: 'fas fa-edit'
                    },
                    {
                        action: entity =>
                            modal.confirm(
                                `Deseja remover o projeto '${entity.name}'?`,
                                confirmed =>
                                    confirmed &&
                                    remove<Projeto>('projeto', entity.id)
                                        .then(() => setRefresh(true))
                                        .catch(err => modal.alert(err.message))
                            ),
                        icon: 'fas fa-times',
                        styleClass: 'is-danger',
                        title: 'Remover'
                    }
                ]}
                items={projetos}
                emptyMessage="Nenhum projeto encontrado."
            />
        </div>
    )
}
