import React, { Component } from 'react'
import { classNames } from '../../../helpers/misc'
import {
    adjustDecimalCaret,
    DecimalConfig,
    getInputSelection,
    maskDecimal,
    setCaretPosition
} from '../../../utils/mask'
import { parseNumber } from '../../../utils/parse'

type Props = DecimalConfig & {
    value: number
    label?: string
    placeholder?: string
    disabled?: boolean
    icon?: string
    onChange?: (value: number, type: ChangeType) => void
    refEl?: (el: HTMLInputElement | null) => void
    input: InputProps
    ignoreEnter: boolean
    meta: MetaProps
}

interface State {
    caretPosition: number
}

export class DecimalInput extends Component<Props, State> {
    static defaultConfig: DecimalConfig = {
        decimalSeparator: ',',
        precision: 2,
        prefix: '',
        selectAllOnFocus: true,
        thousandSeparator: '.'
    }

    static defaultProps: Pick<
        Props,
        'meta' | 'ignoreEnter' | 'input' | keyof DecimalConfig
    > = {
        ...DecimalInput.defaultConfig,
        ignoreEnter: true,
        input: {},
        meta: {}
    }

    inputElement: HTMLInputElement | null

    readonly state: State = {
        caretPosition: 0
    }

    constructor(props: Props) {
        super(props)
        this._onChangeOrBlur = this._onChangeOrBlur.bind(this)
        this._onKeyDown = this._onKeyDown.bind(this)
        this._onFocus = this._onFocus.bind(this)
        this._applyRef = this._applyRef.bind(this)

        this.inputElement = null
    }

    handleChange(text: string, type: ChangeType) {
        const { props } = this
        const inputElement = this.inputElement

        if (!!props.onChange && !!inputElement) {
            const precision = Math.max(0, Math.min(20, props.precision))
            const { end: caretPosition } = getInputSelection(inputElement)
            const value = Math.min(
                parseNumber(text) / 10 ** precision,
                Number.MAX_SAFE_INTEGER
            )
            const masked = maskDecimal(value, {
                ...this.getConfig(),
                prefix: ''
            })

            this.setState({
                caretPosition: adjustDecimalCaret({
                    caretPosition,
                    masked,
                    text
                })
            })

            props.onChange(value, type)
        }
    }

    componentDidUpdate() {
        const { state, inputElement } = this

        if (!!inputElement) {
            setCaretPosition(inputElement, state.caretPosition)
        }
    }

    render() {
        const { meta, value, input, label } = this.props
        const config = this.getConfig()

        const hasError = meta.touched && !!meta.error
        const showPrefix = config.prefix !== ''

        return (
            <>
                <div
                    className={classNames('form-input', {
                        'is-danger': hasError
                    })}
                >
                    {label && <label>{label}</label>}

                    {showPrefix && (
                        <div className="control">
                            <div className="button is-static">
                                {config.prefix}
                            </div>
                        </div>
                    )}

                    <input
                        {...input}
                        type="tel"
                        value={maskDecimal(value, {
                            ...config,
                            prefix: ''
                        })}
                        ref={this._applyRef}
                        onChange={this._onChangeOrBlur}
                        onBlur={this._onChangeOrBlur}
                        onFocus={this._onFocus}
                        onKeyDown={this._onKeyDown}
                    />
                </div>

                {hasError && <p className="help is-danger">{meta.error}</p>}
            </>
        )
    }

    _applyRef = (el: HTMLInputElement | null) =>
        (this.inputElement = el) && this.props.refEl && this.props.refEl(el)

    _onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) =>
        ev.keyCode === 13 && this.props.ignoreEnter && ev.preventDefault()

    _onFocus = (ev: React.FocusEvent<HTMLInputElement>) =>
        this.props.selectAllOnFocus && ev.target.select()

    _onChangeOrBlur = (
        ev:
            | React.ChangeEvent<HTMLInputElement>
            | React.FocusEvent<HTMLInputElement>
    ) =>
        this.handleChange(ev.target.value, ev.type === 'blur' ? ev.type : 'key')

    getConfig(): DecimalConfig {
        const {
            decimalSeparator,
            precision,
            prefix,
            selectAllOnFocus,
            thousandSeparator
        } = this.props
        return {
            decimalSeparator,
            precision,
            prefix,
            selectAllOnFocus,
            thousandSeparator
        }
    }
}
