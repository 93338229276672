import React, { Component } from "react";
import { classNames, isEqual } from "../../../helpers/misc";
import { DatePicker } from "../../datepicker";

type Props = {
  value: NullDate;
  clearButton: boolean;
  usePast: boolean;
  hide?: boolean;
  disabled?: boolean;
  label?: string;
  dateFormat?: string;
  placeholder?: string;
  onChange?: (value: NullDate) => void;
  maxDate: NullDate;
  minDate: NullDate;
  meta: MetaProps;
};

export class DateInput extends Component<Props> {
  static defaultProps: Pick<
    Props,
    "meta" | "maxDate" | "minDate" | "clearButton" | "usePast"
  > = {
    usePast: false,
    clearButton: true,
    meta: {},
    maxDate: null,
    minDate: null
  };

  constructor(props: Props) {
    super(props);
    this._changeDate = this._changeDate.bind(this);
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(nextProps, this.props, ["disabled", "meta", "value"]);
  }

  render() {
    const {
      meta,
      value,
      dateFormat,
      placeholder,
      label,
      minDate,
      maxDate,
      usePast,
      hide = false,
      disabled
    } = this.props;
    const hasError = meta.touched && !!meta.error;

    return (
      <div
        className={classNames("form-input", {
          "is-danger": hasError,
          "is-hidden": hide
        })}
      >
        {label && <label>{label}</label>}

        <DatePicker
          placeholder={placeholder}
          dateFormat={dateFormat}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          usePast={usePast}
          onChange={this._changeDate}
          value={value}
        />

        {hasError && <p className="help is-danger">{meta.error}</p>}
      </div>
    );
  }

  _changeDate(value: NullDate) {
    const { props } = this;

    if (!!props.onChange) {
      props.onChange(value);
    }
  }
}
