import React from 'react'

interface Props {
    error?: string | JSX.Element
}

export const ErrorMessage = ({ error }: Props) =>
    error ? (
        <div className="alert alert--error">
            <p>{error}</p>
        </div>
    ) : null
