import React, { Component } from 'react'
import onClickOutside, { InjectedOnClickOutProps } from 'react-onclickoutside'
import { RouteComponentProps } from 'react-router'
import { classNames } from '../../helpers/misc'
import { getList, readNotification } from '../../service/api'
import { toLocaleDateTime } from '../../utils/format'
import { Button } from '../button'
import { ModalActions } from './Panel'

type Props = InjectedOnClickOutProps & {
    modal: ModalActions
    route: RouteComponentProps<any>
    operator: Operator
}
type State = {
    opened: boolean
    loading: boolean
    notifications: NotifyEnt[]
}

export const PanelBadge = onClickOutside(
    class extends Component<Props, State> {
        state: State = {
            opened: false,
            loading: false,
            notifications: []
        }

        handleClickOutside = () => {
            this.setState({ opened: false })
        }

        componentDidMount() {
            getList<NotifyEnt>('notification')
                .then(notifications => this.setState({ notifications }))
                .catch(err => this.props.modal.alert(err.message))
        }

        render() {
            const { route, operator } = this.props
            const { notifications, opened, loading } = this.state

            return (
                <ul className="badge navbar">
                    <li>
                        <a
                            href="javascript:;"
                            onClick={() =>
                                this.setState(prev => ({
                                    ...prev,
                                    opened: !prev.opened
                                }))
                            }
                        >
                            <i className="fas fa-bell" />
                            {notifications.length > 0 && (
                                <span className="button-badge">
                                    {notifications.length}
                                </span>
                            )}
                        </a>
                    </li>

                    <div
                        className={classNames('dropdown-menu', {
                            show: opened
                        })}
                    >
                        <div className="dropdown-header">
                            <h6>Notificações</h6>

                            {notifications.length > 1 && (
                                <Button
                                    icon="fas fa-trash-alt"
                                    styleClass="is-small bdn"
                                    title="Limpar Todas"
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => {
                                        this.setState({
                                            loading: true
                                        })

                                        Promise.all(
                                            notifications.map(n =>
                                                readNotification({
                                                    id: n.id
                                                })
                                            )
                                        ).then(() =>
                                            this.setState({
                                                loading: false,
                                                notifications: []
                                            })
                                        )
                                    }}
                                />
                            )}
                        </div>

                        {notifications.length === 0 && (
                            <span>Nenhuma notificação encontrada.</span>
                        )}

                        {notifications.map((n, i) => (
                            <a
                                key={i}
                                href="javascript:;"
                                className={classNames('dropdown-item', {
                                    'is-link':
                                        !!n.taskId &&
                                        operator.type !== 'financeiro',
                                    'is-important': n.important
                                })}
                            >
                                <div
                                    className="notification-content"
                                    onClick={ev => {
                                        ev.preventDefault()

                                        if (
                                            !!n.taskId &&
                                            operator.type !== 'financeiro'
                                        ) {
                                            route.history.push(
                                                `/tarefas/${n.taskId}`
                                            )

                                            this.setState({ opened: false })
                                        }
                                    }}
                                >
                                    <div className="notification-heading">
                                        {n.message}
                                    </div>
                                    <small className="notification-timestamp">
                                        {toLocaleDateTime(n.createdAt)}
                                    </small>
                                </div>
                                <span
                                    className="notify-close"
                                    onClick={() => {
                                        readNotification({
                                            id: n.id
                                        })

                                        this.setState(prev => ({
                                            ...prev,
                                            notifications: prev.notifications.filter(
                                                x => x.id !== n.id
                                            )
                                        }))
                                    }}
                                >
                                    <i className="fas fa-times" />
                                </span>
                            </a>
                        ))}
                    </div>
                </ul>
            )
        }
    }
)
