import ClipboardJS from "clipboard";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import Dropzone from "react-dropzone";
import ReactQuill from "react-quill";
import { __RouterContext, RouteComponentProps } from "react-router";
import useForceUpdate from "use-force-update";
import AsyncSelect from "react-select/lib/Async";
import { config } from "../../config";
import { zip } from "../../helpers/functional";
import { classNames } from "../../helpers/misc";
import { FormProps, useForm } from "../../hooks/form";
import {
  add,
  getById,
  getList,
  removeTaskFile,
  updateTaskEssay,
  updateTaskEvent,
  updateTaskFile,
  updateTaskStatus,
  updateTask,
  sendEmailRedator
} from "../../service/api";
import { toLocaleDate, toLocaleDateTime } from "../../utils/format";
import { requiredMessage } from "../../utils/misc";
import { localStore } from "../../utils/store";
import { Button } from "../button";
import { DateInput } from "../form/date-input";
import { ErrorMessage } from "../form/error-message";
import { IntegerInput } from "../form/integer-input";
import { TextInput } from "../form/text-input";
import { ModalPortal } from "../modal-portal";
import { ModalActions } from "../panel";
import { DecimalInput } from "../form/decimal-input";
import { parseNumber } from "../../utils/parse";
type Props = {
  taskId: number;
  closeForm: VoidFn;
  refreshArchived: VoidFn;
  setRefresh: (x: boolean) => void;
  setTaskId: (x: number) => void;
  showForm: boolean;
  route: RouteComponentProps<any>;
  operator: Operator;
  modal: ModalActions;
};

type TaskForm = Task & {
  oldEssay: string;
  personaName: string;
  newFiles: File[];
};

const findRedator = (name: string) =>
  getList<User>("user", { type: "redator", name }).then(users =>
    users.map(i => ({
      label: i.name,
      value: i.id,
      email: i.email,
      sendEmail: i.sendEmail
    }))
  );

const findPersona = (name: string) =>
  getList<Persona>("persona", { name }).then(personas =>
    [
      {
        label: "Sem persona definida",
        value: 0
      }
    ].concat(personas.map(i => ({ label: i.name, value: i.id })))
  );

const findProjeto = (name: string) =>
  getList<Projeto>("projeto", { name }).then(projetos =>
    [
      {
        label: "Nenhum projeto",
        value: 0
      }
    ].concat(projetos.map(i => ({ label: i.name, value: i.id })))
  );

const findEnviroment = (name: string) =>
  getList<Enviroment>("enviroment", { name }).then(enviroments =>
    [
      {
        label: "Nenhum ambiente",
        value: 0
      }
    ].concat(enviroments.map(i => ({ label: i.name, value: i.id })))
  );

const countWords = (value: string) => {
  const cleanedValue = (value || "")
    .replace(/&nbsp;/gi, " ")
    .replace(/(<([^>]+)>)/gi, " ");

  if (!cleanedValue) {
    return 0;
  }

  return cleanedValue.trim().split(/\s+/).length;
};

function addTask(
  task: TaskForm,
  form: FormProps<TaskForm>,
  closeForm: VoidFn,
  setRefresh: (x: boolean) => void
) {
  add<Task>("task", task)
    .then(resp => {
      const id = !!resp ? resp.id : task.id;
      Promise.all(task.newFiles.map(f => updateTaskFile(id, f))).then(() => {
        setRefresh(true);
        closeForm();
        form.resetForm();
      });
    })
    .catch(err => form.setErrors({ _error: err.message }));
}

function sendEmail(data: {
  to: string;
  nome: string;
  nomeTarefa: string;
  link: string;
  site: string;
  vencimento: string;
  chave: string;
}) {
  sendEmailRedator(data);
}

// tslint:disable-next-line
new ClipboardJS(".copyButton");

export const TaskForm = ({
  closeForm,
  showForm,
  route,
  setRefresh,
  setTaskId,
  operator,
  taskId,
  refreshArchived,
  modal
}: Props) => {
  const isRegister = taskId === 0;
  const [showPersona, setShowPersona] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [lastSave, setLastSave] = useState<NullDate>(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const form = useForm<TaskForm>(
    {
      initialEntity: {
        id: 0,
        name: "",
        description: "",
        essay: "",
        oldEssay: "",
        keywords: "",
        showTag: false,
        due: null,
        approvedAt: null,
        readedAt: null,
        editorId: 0,
        editorName: "",
        editorEmail: "",
        editorSendEmail: 0,
        personaId: 0,
        personaName: "",
        persona: null,
        projetoId: 0,
        projetoName: "",
        enviromentId: 0,
        enviromentName: "",
        projetoLink: "",
        statusId: 0,
        files: [],
        newFiles: [],
        wordCount: 0,
        planningValue: 0
      },
      validate: (values, err) => {
        if (!values.name) {
          err.name = requiredMessage;
        }

        if (!values.due) {
          err.due = requiredMessage;
        }

        if (!values.wordCount) {
          err.wordCount = requiredMessage;
        }

        if (!values.editorId) {
          err.editorId = requiredMessage;
        }

        if (!values.projetoId) {
          err.projetoId = requiredMessage;
        }

        if (!values.enviromentId) {
          err.enviromentId = requiredMessage;
        }
        return err;
      }
    },
    route
  );

  useEffect(() => {
    setShowDesc(taskId === 0);

    if (taskId !== 0) {
      form.handleFetch<Task, TaskForm>({
        action: (_, ac) => getById<Task>("task", taskId, ac.signal),
        errorFn: err => form.setErrors({ _error: err.message }),
        mapper: ent => ({
          ...ent,
          oldEssay: ent.essay,
          personaName: ent.persona ? ent.persona.name : "",
          newFiles: []
        })
      });
    }
  }, [taskId]);

  const close = () => {
    setTimeout(() => {
      setTaskId(0);
      setShowPersona(false);
      form.resetForm();
      closeForm();
    }, 300);
  };

  const { entity, errors, touched } = form;
  const { persona } = entity;

  const newFileCount = entity.newFiles.length;
  const upFileCount =
    entity.files.reduce(
      (acc, curr) => (curr.userId === operator.id ? acc + 1 : acc),
      0
    ) + newFileCount;

  const fileCount = entity.files.length + newFileCount;

  const isAdminEdit =
    !isRegister && ["admin", "gerente"].includes(operator.type);

  const isRedatorEdit = operator.type === "redator" && entity.statusId === 2;
  const isRevisorEdit =
    ["revisor", "admin", "gerente"].includes(operator.type) &&
    [3, 4].includes(entity.statusId);

  const canUploadFile = isRegister || (isRedatorEdit && fileCount <= 7);
  const canEditEssay = isRedatorEdit || isRevisorEdit;

  const untagDesc = entity.description.replace(/(<([^>]+)>)/gi, "");

  const forceUpdate = useForceUpdate();
  const routerContext = useContext(__RouterContext);
  const [idxRef, setIdxRef] = useState(0);
  const [currentEditor, setCurrentEditor] = useState(0);

  useEffect(() => {
    routerContext.history.listen(forceUpdate);
    setIdxRef(parseNumber(route.match.params.idx));
    if (idxRef) {
      getById<Enviroment>("enviroment", idxRef).then(item => {
        entity.enviromentId = item.id;
        entity.enviromentName = item.name;
      });
    }
  }, [routerContext]);

  return (
    <ModalPortal>
      <div
        className={classNames("modal modal-form", {
          "is-active": showForm
        })}
      >
        <div className="modal-background" onClick={() => close()} />

        <div className="modal-full">
          <form
            className="box is-clearfix"
            onSubmit={ev => {
              ev.preventDefault();

              if (canEditEssay) {
                updateTaskEssay({
                  id: entity.id,
                  essay: entity.essay,
                  keywords: entity.keywords
                })
                  .then(() => {
                    setLastSave(new Date());
                    form.setValues({
                      oldEssay: entity.essay
                    });

                    Promise.all(
                      entity.newFiles.map(f => updateTaskFile(entity.id, f))
                    ).then(resps => {
                      const newFiles = zip(entity.newFiles, resps).map(
                        ([f, resp]) => ({
                          id: resp.id,
                          filename: f.name,
                          userId: operator.id
                        })
                      );

                      form.setValues(prev => ({
                        newFiles: [],
                        files: [...prev.files, ...newFiles]
                      }));
                    });
                  })
                  .catch(err => modal.alert(err.message));

                return;
              }

              form.handleSubmit(ent => {
                if (ent.editorSendEmail) {
                  const data = ent.due ? ent.due : new Date();

                  sendEmail({
                    to: ent.editorEmail,
                    nome: ent.editorName,
                    nomeTarefa: ent.name,
                    site: ent.projetoName,
                    vencimento: moment(data).format("DD/MM/YYYY HH:mm:ss"),
                    chave: ent.keywords,
                    link: ent.projetoLink
                  });
                }
                addTask(ent, form, closeForm, setRefresh);
              });
            }}
          >
            <div className="title-tag">
              {!isRegister && entity.showTag && (
                <span className="tag is-medium is-success">+ Planejamento</span>
              )}
              <h1 className="title is-4 far-way">
                {isRegister
                  ? `Nova Tarefa em ${entity.enviromentName}`
                  : entity.name}
              </h1>
              {!isRegister &&
                !isUpdate &&
                ["admin", "gerente"].includes(operator.type) && (
                  <Button
                    icon="fas fa-edit"
                    styleClass={classNames("is-small", "is-link")}
                    title="Editar"
                    onClick={() => {
                      setCurrentEditor(entity.editorId);
                      setIsUpdate(true);
                    }}
                  />
                )}
              {isUpdate && ["admin", "gerente"].includes(operator.type) && (
                <Button
                  icon="fas fa-save"
                  styleClass={classNames("is-small", "is-link")}
                  title="Salvar Alteração"
                  onClick={() => {
                    form.setSubmitting(true);
                    updateTask({
                      id: entity.id,
                      projetoId: entity.projetoId,
                      editorId: entity.editorId,
                      enviromentId: entity.enviromentId
                    })
                      .then(() => {
                        form.setSubmitting(false);
                        form.setValues({
                          id: entity.id,
                          projetoId: entity.projetoId,
                          projetoName: entity.projetoName,
                          editorId: entity.editorId,
                          editorName: entity.editorName
                        });
                        if (
                          entity.editorSendEmail &&
                          entity.editorId !== currentEditor
                        ) {
                          const data = entity.due ? entity.due : new Date();
                          sendEmail({
                            to: entity.editorEmail,
                            nome: entity.editorName,
                            nomeTarefa: entity.name,
                            site: entity.projetoName,
                            vencimento: moment(data).format(
                              "DD/MM/YYYY HH:mm:ss"
                            ),
                            chave: entity.keywords,
                            link: entity.projetoLink
                          });
                        }
                        setIsUpdate(false);
                        setRefresh(true);
                      })
                      .catch(err => modal.alert(err.message));
                  }}
                />
              )}
            </div>

            {isRegister && (
              <div className="columns">
                <div className="column is-8">
                  <TextInput
                    label="Título da Pauta"
                    value={entity.name}
                    input={{
                      autoComplete: "name"
                    }}
                    meta={{
                      error: errors.name,
                      touched: touched.name
                    }}
                    onChange={(name, type) =>
                      form.handleChange({
                        path: "name",
                        type,
                        values: { name }
                      })
                    }
                  />
                </div>

                <div className="column">
                  <TextInput
                    label="Palavra(s) Chave(s)"
                    meta={{
                      error: errors.keywords,
                      touched: touched.keywords
                    }}
                    value={entity.keywords}
                    onChange={(keywords, type) =>
                      form.handleChange({
                        path: "keywords",
                        type,
                        values: { keywords }
                      })
                    }
                  />
                </div>
              </div>
            )}

            <div className="columns">
              {!isRegister && !!entity.keywords && (
                <div className="column">
                  <label className="form-label">
                    Palavra(s) Chave(s) da pauta
                  </label>
                  {entity.keywords}
                </div>
              )}

              <div className="column">
                <label className="form-label">Redator</label>

                <AsyncSelect<{
                  label: string;
                  value: number;
                  sendEmail: number;
                  email: string;
                }>
                  defaultOptions={true}
                  cacheOptions={true}
                  className={classNames({
                    "filtertop is-hidden": !isRegister && !isUpdate
                  })}
                  placeholder="Selecionar redator..."
                  noOptionsMessage={() => "Nenhum redator localizado..."}
                  loadOptions={findRedator}
                  onChange={args => {
                    if (!!args && !(args instanceof Array)) {
                      form.handleChange({
                        path: "editorId",
                        values: {
                          editorId: args.value,
                          editorName: args.label,
                          editorSendEmail: args.sendEmail,
                          editorEmail: args.email
                        }
                      });
                    } else {
                      form.handleChange({
                        path: "editorId",
                        values: {
                          editorId: 0,
                          editorName: "",
                          editorSendEmail: 0,
                          editorEmail: ""
                        }
                      });
                    }
                  }}
                  value={
                    entity.editorId
                      ? {
                          label: entity.editorName,
                          value: entity.editorId,
                          sendEmail: entity.editorSendEmail,
                          email: entity.editorEmail
                        }
                      : null
                  }
                />

                {!isRegister && !isUpdate ? entity.editorName : ""}

                {touched.editorId && errors.editorId && isRegister && (
                  <p className="input--error">{errors.editorId}</p>
                )}
              </div>

              <div className="column">
                <label className="form-label">Persona</label>

                <AsyncSelect<{
                  label: string;
                  value: number;
                }>
                  defaultOptions={true}
                  cacheOptions={true}
                  className={classNames({
                    "is-hidden": !isRegister
                  })}
                  placeholder="Selecionar persona..."
                  noOptionsMessage={() => "Nenhuma persona localizada..."}
                  loadOptions={findPersona}
                  onChange={args => {
                    if (!!args && !(args instanceof Array)) {
                      form.handleChange({
                        path: "personaId",
                        values: {
                          personaId: args.value,
                          personaName: args.label
                        }
                      });
                    } else {
                      form.handleChange({
                        path: "personaId",
                        values: {
                          personaId: 0,
                          personaName: ""
                        }
                      });
                    }
                  }}
                  value={
                    entity.personaId
                      ? {
                          label: entity.personaName,
                          value: entity.personaId
                        }
                      : null
                  }
                />

                {!isRegister &&
                  (!!persona ? (
                    <a
                      href="javascript:;"
                      onClick={() => setShowPersona(x => !x)}
                    >
                      {persona.name}
                    </a>
                  ) : (
                    "Persona não definida"
                  ))}
              </div>
            </div>

            {!!persona && showPersona && (
              <div className="columns">
                <div className="column">
                  <div className="persona-box box">
                    <h4>{persona.name}</h4>
                    <h6>
                      {persona.sex === "F" ? "Feminino" : "Masculino"}
                      <span>, {persona.age} anos</span>
                    </h6>

                    <hr />

                    <div className="persona-info">
                      <label className="form-label">Descrição</label>
                      {persona.description}
                    </div>

                    <div className="columns">
                      <div className="column">
                        <div className="persona-info">
                          <label className="form-label">Educação</label>
                          {persona.education}
                        </div>
                      </div>
                      <div className="column">
                        <div className="persona-info">
                          <label className="form-label">Área de Atuação</label>
                          {persona.operationArea}
                        </div>
                      </div>
                      <div className="column">
                        <div className="persona-info">
                          <label className="form-label">Segmento</label>
                          {persona.segment}
                        </div>
                      </div>
                    </div>

                    <div className="persona-info">
                      <label className="form-label">Problemas</label>
                      {persona.problems}
                    </div>

                    <div className="persona-info">
                      <label className="form-label">Resolução</label>
                      {persona.resolution}
                    </div>

                    <div className="persona-info">
                      <label className="form-label">Objetivos</label>
                      {persona.goals}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="columns">
              <div className="column">
                <DateInput
                  label="Vencimento"
                  value={entity.due}
                  hide={!isRegister}
                  meta={{
                    error: errors.due,
                    touched: touched.due
                  }}
                  onChange={due =>
                    form.handleChange({
                      path: "due",
                      values: { due }
                    })
                  }
                />

                {!isRegister && (
                  <>
                    <label className="form-label">Vencimento</label>

                    {entity.due ? toLocaleDate(entity.due) : ""}
                  </>
                )}
              </div>

              {["admin", "gerente", "revisor"].includes(operator.type) && (
                <div
                  className={classNames("column", {
                    form: isRegister
                  })}
                >
                  <label className="form-label">Site</label>

                  <AsyncSelect<{
                    label: string;
                    value: number;
                  }>
                    defaultOptions={true}
                    cacheOptions={true}
                    className={classNames({
                      "is-hidden": !isRegister && !isUpdate
                    })}
                    placeholder="Selecionar site..."
                    noOptionsMessage={() => "Nenhum site localizado..."}
                    loadOptions={findProjeto}
                    onChange={args => {
                      if (!!args && !(args instanceof Array)) {
                        form.handleChange({
                          path: "projetoId",
                          values: {
                            projetoId: args.value,
                            projetoName: args.label
                          }
                        });
                      } else {
                        form.handleChange({
                          path: "projetoId",
                          values: {
                            projetoId: 0,
                            projetoName: ""
                          }
                        });
                      }
                    }}
                    value={
                      entity.projetoId
                        ? {
                            label: entity.projetoName,
                            value: entity.projetoId
                          }
                        : null
                    }
                  />

                  {!isRegister &&
                    !isUpdate &&
                    (!!entity.projetoId ? (
                      <a target="_blank" href={entity.projetoLink}>
                        {entity.projetoName}
                      </a>
                    ) : (
                      "Nenhum"
                    ))}

                  {touched.projetoId &&
                    errors.projetoId &&
                    (isRegister || isUpdate) && (
                      <p className="input--error">{errors.projetoId}</p>
                    )}
                </div>
              )}

              {["admin", "gerente", "revisor"].includes(operator.type) &&
                !idxRef && (
                  <div
                    className={classNames("column", {
                      form: isRegister
                    })}
                  >
                    <label className="form-label">Ambiente</label>

                    <AsyncSelect<{
                      label: string;
                      value: number;
                    }>
                      defaultOptions={true}
                      cacheOptions={true}
                      className={classNames({
                        "is-hidden": !isRegister && !isUpdate
                      })}
                      placeholder="Selecionar ambiente..."
                      noOptionsMessage={() => "Nenhum ambiente localizado..."}
                      loadOptions={findEnviroment}
                      onChange={args => {
                        if (!!args && !(args instanceof Array)) {
                          form.handleChange({
                            path: "enviromentId",
                            values: {
                              enviromentId: args.value,
                              enviromentName: args.label
                            }
                          });
                        } else {
                          form.handleChange({
                            path: "enviromentId",
                            values: {
                              enviromentId: 0,
                              enviromentName: ""
                            }
                          });
                        }
                      }}
                      value={
                        entity.enviromentId
                          ? {
                              label: entity.enviromentName,
                              value: entity.enviromentId
                            }
                          : null
                      }
                    />

                    {!isRegister &&
                      !isUpdate &&
                      (!!entity.enviromentId ? (
                        <p>{entity.enviromentName}</p>
                      ) : (
                        "Nenhum"
                      ))}
                    {touched.enviromentId &&
                      errors.enviromentId &&
                      (isRegister || isUpdate) && (
                        <p className="input--error">{errors.enviromentId}</p>
                      )}
                  </div>
                )}

              <div className="column">
                {isRegister ? (
                  <IntegerInput
                    label="Número de Palavras"
                    value={entity.wordCount}
                    meta={{
                      error: errors.wordCount,
                      touched: touched.wordCount
                    }}
                    onChange={(wordCount, type) =>
                      form.handleChange({
                        path: "wordCount",
                        type,
                        values: {
                          wordCount: wordCount || 0
                        }
                      })
                    }
                  />
                ) : (
                  <>
                    <label className="form-label">Número de Palavras</label>

                    {entity.wordCount}
                  </>
                )}
              </div>
            </div>

            {isRegister && (
              <div className="columns">
                <div className="column is-3">
                  <div className="form-input">
                    <label>Pauta com Planejamento?</label>
                    <input
                      checked={entity.showTag}
                      onChange={ev =>
                        form.setValues({
                          showTag: ev.target.checked
                        })
                      }
                      type="checkbox"
                    />
                  </div>
                </div>

                <div className="column">
                  <DecimalInput
                    label="Percentual de Planejamento"
                    meta={{
                      error: errors.planningValue,
                      touched: touched.planningValue
                    }}
                    value={entity.planningValue || 0}
                    onChange={(planningValue, type) =>
                      form.handleChange({
                        path: "planningValue",
                        type,
                        values: {
                          planningValue
                        }
                      })
                    }
                  />
                </div>
              </div>
            )}

            <div
              className={classNames("columns", {
                "is-hidden": !isRegister && !untagDesc
              })}
            >
              <div className="column">
                <label className="form-label">
                  Tópicos principais, fontes ou descrição da pauta
                </label>

                {showDesc && (
                  <ReactQuill
                    modules={{
                      toolbar: isRegister
                      ? [
                        [
                          {
                            header: [2, 3, 4, 5, 6, false]
                          }
                        ],
                        [{ header: 2 }],
                        ["bold", "italic", "underline", "strike"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                        ],
                        ["link", "blockquote", "image"],
                        ['clean']
                      ]
                    : false
                    }}
                    readOnly={!isRegister}
                    value={entity.description}
                    onChange={description =>
                      form.handleChange({
                        path: "description",
                        type: "key",
                        values: { description }
                      })
                    }
                    onBlur={() =>
                      form.handleChange({
                        path: "description",
                        values: {
                          description: entity.description
                        }
                      })
                    }
                  />
                )}

                {!isRegister && (
                  <Button
                    icon={showDesc ? "fas fa-eye-slash" : "fas fa-bars"}
                    styleClass={classNames("is-small", {
                      "hide-desc": showDesc
                    })}
                    title={
                      !showDesc
                        ? "Exibir descrição de ajuda"
                        : "Esconder descrição de ajuda"
                    }
                    onClick={() => setShowDesc(x => !x)}
                  />
                )}

                {touched.description && errors.description && (
                  <p className="input--error">{errors.description}</p>
                )}
              </div>
            </div>

            {!isRegister && (canEditEssay || !!entity.essay) && (
              <div className="columns">
                <div className="column">
                  <label className="form-label">Redação</label>

                  <ReactQuill
                    readOnly={!canEditEssay}
                    modules={{
                      toolbar: canEditEssay
                        ? [
                            [
                              {
                                header: [2, 3, 4, 5, 6, false]
                              }
                            ],
                            [{ header: 2 }],
                            ["bold", "italic", "underline", "strike"],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                            ],
                            ["link", "blockquote", "image"],
                            ['clean']
                          ]
                        : false
                    }}
                    value={entity.essay}
                    onChange={essay =>
                      form.handleChange({
                        path: "essay",
                        type: "key",
                        values: { essay }
                      })
                    }
                    onBlur={() =>
                      form.handleChange({
                        path: "essay",
                        values: {
                          essay: entity.essay
                        }
                      })
                    }
                  />

                  <div className="quill-box">
                    <div>Palavras: {countWords(entity.essay)}</div>

                    {entity.oldEssay !== entity.essay && (
                      <div className="help is-danger">
                        Existem alterações não salvas
                      </div>
                    )}

                    {lastSave && (
                      <div>
                        {"Última versão salva: "}
                        <b>{toLocaleDateTime(lastSave)}</b>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {((!canUploadFile && fileCount > 0) || canUploadFile) && (
              <div className="columns">
                <div className="column">
                  <label className="form-label">Anexos</label>

                  {canUploadFile && (
                    <Dropzone
                      accept={
                        "image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                      }
                      maxSize={5e6}
                      onDrop={(files, rejFiles) => {
                        const maxFiles = 7 - upFileCount;

                        form.setValues(prev => ({
                          newFiles: [
                            ...prev.newFiles,
                            ...files.slice(0, maxFiles)
                          ]
                        }));

                        if (rejFiles.length > 0) {
                          form.setErrors({
                            newFiles: rejFiles.reduce((acc, curr) => {
                              if (curr.size > 5e6) {
                                return (
                                  acc +
                                  `O arquivo '${curr.name}' é maior que 5MB.\n`
                                );
                              }

                              return (
                                acc +
                                `O arquivo '${curr.name}' tem uma extensão inválida.\n`
                              );
                            }, "") as any
                          });
                        } else if (files.length > maxFiles) {
                          form.setErrors({
                            newFiles: "Você só pode enviar no máximo 7 items." as any
                          });
                        } else {
                          form.setErrors({
                            newFiles: undefined
                          });
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps()}
                          className={classNames("dropzone", {
                            active: isDragActive
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>
                            Arraste anexos aqui, ou clique para selecionar os
                            arquivos.
                          </p>
                        </div>
                      )}
                    </Dropzone>
                  )}

                  {errors.newFiles && (
                    <p className="input--error files--error">
                      {errors.newFiles}
                    </p>
                  )}

                  <ul className="anexos">
                    {entity.files.map((f, i) => (
                      <li key={i}>
                        <svg className="file-icon" viewBox="0 0 32 32">
                          <path d="M26.5,9.1l-7.7-7.7C17.9,0.5,16.7,0,15.3,0H8C5.8,0,4,1.8,4,4v24c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4V12.7C28,11.3,27.5,10.1,26.5,9.1z M25.1,10.5c0.1,0.1,0.3,0.3,0.4,0.5H19c-1.1,0-2-0.9-2-2V2.5c0.2,0.1,0.3,0.2,0.5,0.4L25.1,10.5z M26,28c0,1.1-0.9,2-2,2H8c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h7v7c0,2.2,1.8,4,4,4h7V28z" />
                        </svg>

                        <a
                          download={f.filename}
                          href={`${config.API_URL}/task/download.php?id=${
                            f.id
                          }&token=${localStore.get(config.TOKEN_KEY)}`}
                        >
                          {f.filename}
                        </a>

                        {canUploadFile && (
                          <a
                            className="delete-button"
                            onClick={() => {
                              removeTaskFile(f.id).catch(err =>
                                modal.alert(err.message)
                              );

                              form.setValues(prev => ({
                                ...prev,
                                files: prev.files.filter(x => x.id !== f.id)
                              }));
                            }}
                          >
                            <svg viewBox="0 0 32 32">
                              <path d="M18.1,16l8.9-8.9c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L16,13.9L7.1,4.9c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l8.9,8.9l-8.9,8.9c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z" />
                            </svg>
                          </a>
                        )}
                      </li>
                    ))}

                    {entity.newFiles.map((f, i) => (
                      <li key={i}>
                        <svg className="file-icon" viewBox="0 0 32 32">
                          <path d="M26.5,9.1l-7.7-7.7C17.9,0.5,16.7,0,15.3,0H8C5.8,0,4,1.8,4,4v24c0,2.2,1.8,4,4,4h16c2.2,0,4-1.8,4-4V12.7C28,11.3,27.5,10.1,26.5,9.1z M25.1,10.5c0.1,0.1,0.3,0.3,0.4,0.5H19c-1.1,0-2-0.9-2-2V2.5c0.2,0.1,0.3,0.2,0.5,0.4L25.1,10.5z M26,28c0,1.1-0.9,2-2,2H8c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h7v7c0,2.2,1.8,4,4,4h7V28z" />
                        </svg>

                        <span>{f.name}</span>

                        <a
                          className="delete-button"
                          onClick={() =>
                            form.setValues(prev => ({
                              ...prev,
                              newFiles: prev.newFiles.filter(
                                (_, ind) => i !== ind
                              )
                            }))
                          }
                        >
                          <svg viewBox="0 0 32 32">
                            <path d="M18.1,16l8.9-8.9c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L16,13.9L7.1,4.9c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l8.9,8.9l-8.9,8.9c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z" />
                          </svg>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="columns mb-0">
              <div className="column pb-0">
                <label className="form-label is-right">Controle da tarefa</label>
              </div>
            </div>
            <div className="buttons is-right">
              {(isRegister || canEditEssay) && (
                <Button
                  icon="fas fa-save"
                  styleClass={canEditEssay ? "is-success rascunho" : "is-link is-medium"}
                  loading={form.submitting}
                  disabled={form.submitting}
                  title={canEditEssay ? "Salvar Rascunho" : "Salvar"}
                />
              )}

              {operator.type === "redator" && entity.statusId === 1 && (
                <Button
                  styleClass="is-link"
                  loading={form.submitting}
                  disabled={form.submitting}
                  title="Iniciar Redação"
                  onClick={() => {
                    form.setSubmitting(true);
                    updateTaskStatus(
                      {
                        id: entity.id,
                        statusId: 2
                      },
                      true
                    )
                      .then(() => {
                        form.setSubmitting(false);
                        form.setValues({
                          statusId: 2
                        });
                        setRefresh(true);
                      })
                      .catch(err => modal.alert(err.message));
                  }}
                />
              )}

              {operator.type === "redator" && entity.statusId === 2 && (
                <Button
                  icon="fas fa-paper-plane"
                  styleClass="is-success send"
                  loading={form.submitting}
                  disabled={form.submitting}
                  title="Enviar para Revisão"
                  onClick={() => {
                    form.setSubmitting(true);
                    updateTaskEssay({
                      id: entity.id,
                      essay: entity.essay,
                      keywords: entity.keywords
                    }).then(() => {
                      setLastSave(new Date());
                      form.setValues({
                        oldEssay: entity.essay
                      });

                      updateTaskStatus({
                        id: entity.id,
                        statusId: 3
                      })
                        .then(() => {
                          updateTaskEvent({
                            id: entity.id,
                            type: "approvedAt"
                          });
                          setRefresh(true);
                        })
                        .catch(err => modal.alert(err.message));
                      close();
                    });
                  }}
                />
              )}

              {isRevisorEdit && (
                <Button
                  icon="fas fa-paper-plane"
                  styleClass="is-success"
                  loading={form.submitting}
                  disabled={form.submitting}
                  title="Enviar para Publicação"
                  onClick={() => {
                    form.setSubmitting(true);

                    updateTaskEssay({
                      id: entity.id,
                      essay: entity.essay,
                      keywords: entity.keywords
                    }).then(() => {
                      setLastSave(new Date());
                      form.setValues({
                        oldEssay: entity.essay
                      });

                      updateTaskStatus({
                        id: entity.id,
                        statusId: 5
                      })
                        .then(() => {
                          setRefresh(true);
                        })
                        .catch(err => modal.alert(err.message));
                      close();
                    });
                  }}
                />
              )}

              {(isAdminEdit ||
                (operator.type === "revisor" && entity.statusId === 5)) &&
                !!entity.essay && (
                  <button
                    className="copyButton button is-white"
                    onClick={ev => ev.preventDefault()}
                    data-clipboard-text={entity.essay}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-code" aria-hidden="true" />
                    </span>

                    <span>Copiar em HTML</span>
                  </button>
                )}

              {isAdminEdit &&
                !entity.approvedAt &&
                [4, 5].includes(entity.statusId) && (
                  <Button
                    icon="fas fa-clipboard-check"
                    styleClass="is-success"
                    title="Aprovar"
                    onClick={() => {
                      updateTaskEvent({
                        id: entity.id,
                        type: "approvedAt"
                      }).then(() => setRefresh(true));
                      close();
                    }}
                  />
                )}

              {isAdminEdit && entity.statusId !== 6 && (
                <Button
                  icon="fas fa-archive"
                  styleClass="is-dark arquivar"
                  onClick={() => {
                    modal.confirm(
                      "Deseja realmente arquivar essa tarefa?",
                      conf => {
                        if (conf) {
                          updateTaskStatus({
                            id: entity.id,
                            statusId: 6
                          }).then(() => {
                            setRefresh(true);
                            refreshArchived();
                          });
                          close();
                        }
                      }
                    );
                  }}
                />
              )}
            </div>

            <ErrorMessage error={errors._error} />
          </form>

          <Button
            className="modal-close is-large"
            aria-label="close"
            onClick={() => close()}
          />
        </div>
      </div>
    </ModalPortal>
  );
};
