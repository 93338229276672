import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  ConnectDropTarget,
  DragDropContext,
  DragElementWrapper,
  DragSource,
  DragSourceOptions,
  DropTarget
} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { __RouterContext, RouteComponentProps } from "react-router";

import useForceUpdate from "use-force-update";
import { classNames } from "../../helpers/misc";
import {
  getArchivedTasks,
  getList,
  getTasks,
  getTasksEnviroment,
  remove,
  sendEmailGestao,
  updateTaskEvent,
  updateTaskStatus
} from "../../service/api";
import { parseNumber } from "../../utils/parse";
import { localStore } from "../../utils/store";
import { Button } from "../button";
import TaskFilter from "../filters/Task-Filter";
import TaskFilterArquivadas from "../filters/Task-FilterArquivadas";
import { RequiredPageProps } from "../route/page";
import { TaskForm } from "./TaskForm";
type Route = RouteComponentProps<any>;

type CardProps = TaskInfo & {
  boardId: string;
  operatorType: UserType;
  route: Route;
};

type CardInjected = {
  connectDragSource: DragElementWrapper<DragSourceOptions>;
  isDragging: boolean;
};

type BoardProps = {
  id: string;
  name: string;
  route: Route;
  operator: Operator;
  tasks: TaskInfo[];
  setTasks: React.Dispatch<React.SetStateAction<TaskList>>;
  borderColor: string;
  listUserEmail?: User[];
};

type BoardInjected = {
  connectDropTarget: ConnectDropTarget;
  selectedItem: TaskInfo;
  canDrop: boolean;
  isOver: boolean;
};

function findStatusId(name: string) {
  if (name === "available") {
    return 1;
  } else if (name === "inProduction") {
    return 2;
  } else if (name === "review") {
    return 3;
  } else if (name === "adjustment") {
    return 4;
  } else if (name === "publish") {
    return 5;
  }

  throw new Error("Invalid statusId");
}

function renderStatus(
  operatorType: UserType,
  readedAt: NullDate,
  paidAt: NullDate,
  approvedAt: NullDate
) {
  if (!!paidAt) {
    return <div className="status paid">Pago</div>;
  } else if (!!approvedAt) {
    return <div className="status approved"><i className="fas fa-dollar-sign"></i> Aguardando pagamento</div>;
  } else if (!readedAt && operatorType === "redator") {
    return <div className="status">Novo</div>;
  }

  return null;
}
  
const Card = DragSource<CardProps, CardInjected>(
  "card",
  {
    beginDrag: props => props,
    canDrag: props => {
      if (!!props.paidAt) {
        return false;
      }

      if (props.operatorType === "revisor") {
        return !["inProduction", "available"].includes(props.boardId);
      }

      if (props.operatorType === "redator") {
        return !["publish"].includes(props.boardId);
      }

      return true;
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(
  ({
    name,
    due,
    projetoName,
    isDragging,
    approvedAt,
    paidAt,
    readedAt,
    operatorType,
    route,
    connectDragSource,
    id
  }: CardProps & CardInjected) => {
    const dueDate = moment(due);

    return connectDragSource(
      <div
        className={classNames("sortable-item", {
          "is-dragging": isDragging
        })}
        onClick={ev => {
          ev.preventDefault();
          route.history.push(`/tarefas/${id}`);
        }}
      >
        <div className="sortable-card" draggable={true}>
          <div className="contents">
            <div className="card-title">{name}</div>
            <div className="metadata">
              <div
                className={classNames("dueDate", {
                  past: dueDate.isBefore(new Date()),
                  soon: dueDate.isBetween(new Date(), moment().add(7, "d"))
                })}
              >
                <span>Prazo: {dueDate.format("DD/MM/YYYY")}</span>
              </div>

              {!!projetoName &&
                ["admin", "gerente", "revisor"].includes(operatorType) && (
                  <div className="project">{projetoName}</div>
                )}
            </div>
          </div>
        </div>

        {renderStatus(operatorType, readedAt, paidAt, approvedAt)}
      </div>
    );
  }
);

const Board = DropTarget<BoardProps, BoardInjected>(
  "card",
  {
    canDrop(props, monitor) {
      const item: CardProps = monitor.getItem();
      const isSameBoard = !!props.tasks.find(c => c.id === item.id);

      let extraCondition = true;

      if (props.operator.type === "redator") {
        extraCondition =
          ["inProduction", "review"].includes(props.id) &&
          !(item.boardId === "adjustment" && props.id === "review");
      } else if (props.operator.type === "revisor") {
        extraCondition = ["adjustment", "publish"].includes(props.id);
      }

      return !isSameBoard && extraCondition;
    },
    drop(props, monitor) {
      const item: CardProps = monitor.getItem();
      const { operator, listUserEmail } = props;

      props.setTasks(tasks => ({
        ...tasks,
        [item.boardId]: tasks[item.boardId as keyof TaskList].filter(
          i => i.id !== item.id
        ),
        [props.id]: [...tasks[props.id], item]
      }));

      const statusId = findStatusId(props.id);
      updateTaskStatus(
        { id: item.id, statusId },
        statusId === 2 && operator.type === "redator" && !item.readedAt
      );

      if (statusId === 3) {
        if (listUserEmail) {
          listUserEmail
            .filter(c => c.type === "revisor")
            .forEach(user => {
              sendEmailGestao("revisor", {
                to: user.email,
                nomeTarefa: item.name,
                site: item.projetoName,
                link: item.projetoLink
              });
            });
        }
      }

      if (statusId === 5) {
        if (listUserEmail) {
          listUserEmail
            .filter(c => c.type === "gerente" || c.type === "admin")
            .forEach(user => {
              sendEmailGestao("admin", {
                to: user.email,
                nomeTarefa: item.name,
                site: item.projetoName,
                link: item.projetoLink
              });
            });
        }
      }

      if (statusId === 3 && !item.approvedAt) {
        updateTaskEvent({
          id: item.id,
          type: "approvedAt"
        });

        props.route.history.go(0);
      }
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    selectedItem: monitor.getItem()
  })
)(
  ({
    id,
    tasks: cards,
    name,
    connectDropTarget,
    selectedItem,
    canDrop,
    operator,
    borderColor,
    route,
    isOver
  }: BoardProps & BoardInjected) =>
    connectDropTarget(
      <div className="sortable-item">
        <div className="board-column">
          <div className="board-header" style={{ borderColor }}>
            <span>{name}</span>
          </div>

          <div className="board-container">
            {cards.map((c, i) => (
              <Card
                {...c}
                key={i}
                operatorType={operator.type}
                route={route}
                boardId={id}
              />
            ))}

            {isOver && canDrop && (
              <Card
                {...selectedItem}
                operatorType={operator.type}
                route={route}
                boardId={id}
              />
            )}
          </div>
        </div>
      </div>
    )
);


export const TaskList = DragDropContext(HTML5Backend)(
  ({ route, operator, modal }: RequiredPageProps) => {
    const [taskId, setTaskId] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [refreshArchived, setArchivedRefresh] = useState(false);
    const [userEmailList, setUserEmailList] = useState<User[]>();

    const [archived, setArchived] = useState<
      ArchivedTasks & { lastPage: number; loading: boolean }
    >({
      items: [],
      pages: 1,
      lastPage: 1,
      loading: false
    });

    const [tasks, setTasks] = useState<TaskList>({
      available: [],
      inProduction: [],
      review: [],
      adjustment: [],
      publish: []
    });

    const [defaultFilter] = useState<Filter>({
      description: "",
      projetoId: 0,
      redatorId: 0,
      prazo: null
    });

    const [getFilter, setFilter] = useState<Filter>(      
      localStore.get("taskListFilter")?
      localStore.get<Filter>("taskListFilter") as Filter: defaultFilter);

    const forceUpdate = useForceUpdate();
    const routerContext = useContext(__RouterContext);

    const  [projetoList, setProjetoList] = useState<Projeto[]>([]);
    const  [redatorList, setRedatorList] = useState<User[]>([]);

    const  [getFilterArquivadas, setFilterArquivadas] = useState<Filter>(defaultFilter);

     
    function gravarFilterArquivadas(nomeTarefa:string) {
      const filter={
        description: nomeTarefa,
        projetoId: 0,
        redatorId: 0,
        prazo: null
      };
      setFilterArquivadas(filter);
    }

    let idx = 0;
    let id = 0;

    useEffect(() => {
      routerContext.history.listen(forceUpdate);

      const idxRef = parseNumber(route.match.params.id);
      if (id !== idxRef) {
        setRefresh(true);
      }
    }, [routerContext]);

    useEffect(() => {
      routerContext.history.listen(forceUpdate);

      const idxRef = parseNumber(route.match.params.idx);
      if (idx !== idxRef) {
        const filter={
          description: "",
          projetoId: 0,
          redatorId: 0,
          prazo: null
        };
        setFilter(filter);
        localStore.set<Filter>("taskListFilter", filter);
        setShowFilter(false);
        setRefresh(true);
      }
    }, [routerContext]);

    useEffect(() => {
      setFilterArquivadas(defaultFilter);
      getList<User>("user", { sendEmail: 1 })
        .then(users => {
          setUserEmailList(users);
        })
        .catch(err => modal.alert(err.message));
    }, [refresh]);

    useEffect(() => {
      setFilterArquivadas(defaultFilter);
      idx = parseNumber(route.match.params.idx);
      id = parseNumber(route.match.params.id);

      if (refresh || id || idx) {        
        if (idx) {
          getTasksEnviroment(idx, getFilter)
            .then(items => {
              setTasks(items);
            })
            .catch(err => modal.alert(err.message));

            getList<Projeto>("projeto")
            .then(itens => setProjetoList(itens));

            getList<User>("user", { type: "redator"})
            .then(itens => setRedatorList(itens));
        } else if (id) {
          setTaskId(id);
          setShowForm(true);
        } else if (!id && !idx) {
          getTasks(getFilter)
            .then(items => setTasks(items))
            .catch(err => modal.alert(err.message));

            getList<Projeto>("projeto")
            .then(items => setProjetoList(items));

            getList<User>("user", { type: "redator"})
            .then(itens => setRedatorList(itens));
        }
        setRefresh(false);
      }
    }, [refresh]);

    useEffect(() => {
      if (["admin", "gerente"].includes(operator.type) && !refreshArchived) {

        setArchived(prev => ({
          ...prev,
          lastPage: 1,
          loading: true
        }));
        
        getArchivedTasks(getFilterArquivadas.description, 1)
          .then(resp =>{
            setArchived(prev => ({
              ...prev,
              items: [...resp.items],
              pages: resp.pages,
              loading: false
            }))}
          ).catch(err => modal.alert(err.message));
      }
    }, [getFilterArquivadas]);

    useEffect(() => {
      if (["admin", "gerente"].includes(operator.type) && !refreshArchived) {
        setArchived(prev => ({
          ...prev,
          loading: true
        }));
        

        getArchivedTasks(getFilterArquivadas.description, archived.lastPage)
          .then(resp =>{
            setArchived(prev => ({
              ...prev,
              items: [...resp.items],
              pages: resp.pages,
              loading: false
            }))}
          ).catch(err => modal.alert(err.message));
      }
    }, [archived.lastPage]);

    useEffect(() => {
      if (refreshArchived) {
        setFilterArquivadas(defaultFilter);

        setArchived(prev => ({
          ...prev,
          lastPage: 1,
          loading: true
        }));

        getArchivedTasks(getFilterArquivadas.description)
          .then(resp => {
            setArchived(prev => ({
              ...prev,
              items: resp.items,
              pages: resp.pages,
              loading: false
            }));
            setArchivedRefresh(false);
          })
          .catch(err => modal.alert(err.message));
      }
    }, [refreshArchived]);

    const getEnv = () => {
      const env = localStore.get("enviromentName");
      return env ? `Ambiente / ${env}` : "";
    };




    return (
      <div className="content">
        <h3>{getEnv()}</h3>
        {["admin", "gerente"].includes(operator.type) && (
          <div>
            <div className="columns is-gapless">
              <div className="column is-half">
                <div className="buttons">
                  <Button
                    onClick={() => setShowForm(true)}
                    title="Nova Tarefa"
                    icon="fas fa-plus"
                    styleClass="is-link is-medium"
                  />
                </div>
              </div>

              <div className="column is-half">
                <div className="buttons is-right">
                  <Button
                    onClick={() => setShowArchived(true)}
                    title=""
                    icon="fas fa-archive"
                  />
                  <Button
                    onClick={() => setShowFilter(!showFilter)}
                    title="Filtrar"
                    icon="fas fa-filter"
                  />
                </div>
              </div>
            </div>
            <div>
              {showFilter && (
                <TaskFilter
                  operator={operator}
                  route={route}
                  currentFilter={getFilter}
                  projetoList={projetoList}
                  redatorList={redatorList}
                  onFilter={filter => {
                    localStore.set<Filter>("taskListFilter",filter);
                    setFilter(filter);
                    setRefresh(true);
                  }}
                />
              )}
            </div>
          </div>
        )}

        {["redator"].includes(operator.type) && (
          <div>
            <div className="columns is-gapless">
              <div className="column">
                <div className="buttons is-right">
                  <Button
                    onClick={() => setShowFilter(!showFilter)}
                    title="Filtros"
                    icon="fas fa-filter"
                  />
                </div>
              </div>
            </div>
            <div>
              {showFilter && (
                <TaskFilter
                  route={route}
                  operator={operator}
                  currentFilter={getFilter}
                  projetoList={projetoList}
                  redatorList={redatorList}
                  onFilter={filter => {
                    localStore.set<Filter>("taskListFilter",filter);
                    setFilter(filter);
                    setRefresh(true);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div className="task-list">
          <div className="container-row">
            <Board
              id="available"
              name="Disponível para produzir"
              tasks={tasks.available}
              setTasks={setTasks}
              operator={operator}
              route={route}
              borderColor="#3273dc"
            />
            <Board
              id="inProduction"
              name="Em produção"
              tasks={tasks.inProduction}
              setTasks={setTasks}
              operator={operator}
              route={route}
              borderColor="#209cee"
            />
            <Board
              id="review"
              name="Pronta para revisão"
              tasks={tasks.review}
              setTasks={setTasks}
              operator={operator}
              route={route}
              borderColor="#ffdd57"
              listUserEmail={userEmailList}
            />
            <Board
              id="adjustment"
              name="Em ajuste"
              tasks={tasks.adjustment}
              setTasks={setTasks}
              operator={operator}
              route={route}
              borderColor="#ef6422"
            />
            <Board
              id="publish"
              name="Pronta pra publicar"
              tasks={tasks.publish}
              setTasks={setTasks}
              operator={operator}
              route={route}
              borderColor="#36d123"
              listUserEmail={userEmailList}
            />
          </div>

          <TaskForm
            route={route}
            showForm={showForm}
            taskId={taskId}
            setTaskId={setTaskId}
            closeForm={() => {
              setRefresh(true);
              route.history.goBack();
              setShowForm(false);
            }}
            refreshArchived={() => setArchivedRefresh(true)}
            setRefresh={setRefresh}
            modal={modal}
            operator={operator}
          />

          {["admin", "gerente"].includes(operator.type) && (
            <div
              className={classNames("quickview", {
                "is-active": showArchived
              })}
            >
              <header className="quickview-header">
                <p className="title">Tarefas Arquivadas</p>
                <span
                  className="delete"
                  data-dismiss="quickview"
                  onClick={() => setShowArchived(false)}
                />
              </header>

              <div className="quickview-body">
                <TaskFilterArquivadas
                route={route}
                initialEntity={getFilter}
                onFilter={gravarFilterArquivadas}
                /> 
                <div className="quickview-block">
                  {archived.items.map((t, i) => (
                    <div key={i}>
                      <div
                        className={"sortable-item"}
                        onClick={ev => {
                          ev.preventDefault();
                          setTaskId(t.id);
                          setShowArchived(false);
                          setShowForm(true);
                        }}
                      >
                        <div className="sortable-card">
                          <div className="contents">
                            <div className="card-title">{t.name}</div>
                            <div className="metadata">
                              <div
                                className={classNames("dueDate", {
                                  past: moment(t.due).isBefore(new Date()),
                                  soon: moment(t.due).isBetween(
                                    new Date(),
                                    moment().add(7, "d")
                                  )
                                })}
                              >
                                <span>
                                  {"Prazo: "}
                                  {moment(t.due).format("DD/MM/YYYY")}
                                </span>
                              </div>

                              {!!t.projetoName &&
                                ["admin", "gerente", "revisor"].includes(
                                  operator.type
                                ) && (
                                  <div className="project">{t.projetoName}</div>
                                )}
                            </div>
                          </div>
                        </div>

                        {renderStatus(
                          operator.type,
                          t.readedAt,
                          t.paidAt,
                          t.approvedAt
                        )}
                      </div>

                      <div className="remove-archived">
                        <a
                          onClick={ev => {
                            ev.preventDefault();
                            remove<Task>("task", t.id);
                            setArchived(prev => ({
                              ...prev,
                              items: prev.items.filter(p => p.id !== t.id)
                            }));
                          }}
                          href="javascript:;"
                        >
                          Remover
                        </a>
                      </div>
                    </div>
                  ))}

                  {archived.pages > archived.lastPage && (
                    <Button
                      styleClass="is-fullwidth is-success"
                      title="Carregar Mais"
                      disabled={archived.loading}
                      loading={archived.loading}
                      onClick={() =>
                        setArchived(prev => ({
                          ...prev,
                          lastPage: prev.lastPage + 1
                        }))
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
