const apiUrls = {
  dev: "https://task-dev.brworks.com.br/api",
  prod: "https://task.brworks.com.br/api",
  hml: "https://task-dev.brworks.com.br/api"
};

export const config = {
  API_URL: apiUrls[process.env.REACT_APP_HOST_ENV || "dev"],
  NAMESPACE: "tasktop",
  OPERATOR_KEY: "operator",
  TOKEN_KEY: "tk"
};
