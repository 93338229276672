import React, { Component } from "react";
import { classNames } from "../../../helpers/misc";
import * as MASK from "../../../utils/mask";

type Props = {
  type: InputType;
  value?: string | null;
  meta: MetaProps;
  input: InputProps;
  ignoreEnter: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  mask?: MaskChar[];
  icon?: string;
  onChange?: (value: string, type: ChangeType) => void;
  refEl?: (el: HTMLInputElement | null) => void;
};

type State = {
  previousValue: string;
  caretPosition: number;
};

export class TextInput extends Component<Props, State> {
  static defaultProps: Pick<
    Props,
    "type" | "meta" | "ignoreEnter" | "input"
  > = {
    type: "text",
    ignoreEnter: true,
    input: {},
    meta: {}
  };

  readonly state: State = {
    caretPosition: 0,
    previousValue: ""
  };

  inputElement: HTMLInputElement | null;

  constructor(props: Props) {
    super(props);
    this.inputElement = null;
  }

  componentDidMount() {
    const { mask, value } = this.props;

    if (!!mask && !!value) {
      this._handleChange(value, "key");
    }
  }

  componentDidUpdate() {
    const { props, state } = this;

    if (!!props.mask && !!this.inputElement) {
      MASK.setCaretPosition(this.inputElement, state.caretPosition);
    }
  }

  render() {
    const {
      icon,
      refEl,
      type,
      meta,
      label,
      value,
      placeholder,
      disabled,
      ignoreEnter,
      input
    } = this.props;
    const hasError = meta.touched && !!meta.error;

    return (
      <div
        className={classNames("form-input", {
          "is-danger": hasError
        })}
      >
        {label && <label>{label}</label>}

        <input
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          ref={el => (this.inputElement = el) && refEl && refEl(el)}
          onChange={ev => this._handleChange(ev.target.value, "key")}
          onBlur={ev =>
            this._handleChange((ev.target as HTMLInputElement).value, "blur")
          }
          onFocus={ev =>
            this._handleFocus((ev.target as HTMLInputElement).value)
          }
          onKeyDown={ev =>
            ev.keyCode === 13 && ignoreEnter && ev.preventDefault()
          }
          value={value || ""}
        />

        {icon && (
          <span className="icon is-small is-left">
            <i className={icon} />
          </span>
        )}

        {hasError && <p className="input--error">{meta.error}</p>}
      </div>
    );
  }

  _handleFocus(value: string) {
    const { props, state } = this;

    if (
      !!props.mask &&
      value.indexOf(MASK.placeholderChar) > -1 &&
      !!this.inputElement
    ) {
      MASK.setCaretPosition(this.inputElement, state.caretPosition);
    }
  }

  _handleChange(value: string, type: ChangeType) {
    const { props, state } = this;
    const inputElement = this.inputElement;

    if (!!props.onChange && !!inputElement) {
      if (!!props.mask) {
        const placeholder = MASK.convertMaskToPlaceholder(props.mask);
        const { end: caretPosition } = MASK.getInputSelection(inputElement);
        const maskedValue = MASK.conformToMask({
          caretPosition,
          mask: props.mask,
          placeholder,
          previousValue: state.previousValue,
          value
        });

        const newCaretPosition = MASK.adjustCaretPosition({
          caretPosition,
          placeholder,
          previousValue: state.previousValue,
          rawValue: value,
          value: maskedValue
        });

        this.setState({
          previousValue: maskedValue,
          caretPosition: newCaretPosition
        });

        const finalValue = maskedValue === placeholder ? "" : maskedValue;

        props.onChange(finalValue, type);
      } else {
        props.onChange(value, type);
      }
    }
  }
}
