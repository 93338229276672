import moment from 'moment'

type MomentDate = moment.Moment | Date
type UnitDuration = moment.unitOfTime.DurationConstructor

export const toMoment = (d: MomentDate | string) =>
    d instanceof Date || typeof d === 'string' ? moment(d) : d

export const set = (date: moment.Moment, values: moment.MomentSetObject) =>
    date
        .clone()
        .set(values)
        
export const add = (date: MomentDate, amount: number, unit: UnitDuration) =>
    toMoment(date)
        .clone()
        .add(amount, unit)

export const subtract = (date: MomentDate, amount: number, unit: UnitDuration) =>
    toMoment(date)
        .clone()
        .subtract(amount, unit)

export function parseDate(value: string | number | Date, dateFormat?: string) {
    const m = moment(value, dateFormat, moment.locale(), true)
    return m.isValid() ? m : null
}

export const safeDateFormat = (date: MomentDate | null, dateFormat?: string) =>
    !!date ? moment(date).format(dateFormat) : ''

export function getDayOfWeekCode(day: MomentDate) {
    const dayOfWeekCodes = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    return dayOfWeekCodes[toMoment(day).isoWeekday() - 1]
}

export function isSameDay(a?: MomentDate | null, b?: MomentDate | null) {
    if (a && b) {
        return toMoment(a).isSame(b, 'day')
    } else {
        return !a && !b
    }
}

export function getWeekdayMinInLocale(date: MomentDate) {
    return moment.localeData().weekdaysMin(toMoment(date))
}

export function getMonthInLocale(date: MomentDate, format?: string) {
    return moment.localeData().months(toMoment(date), format)
}

export function isDayDisabled(_day: MomentDate, minDate?: MomentDate | null, maxDate?: MomentDate | null) {
    const day = toMoment(_day)
    return minDate && day.isBefore(minDate, 'day') || maxDate && day.isAfter(maxDate, 'day')
}

export function allDaysDisabledBefore(
    day: MomentDate,
    unit: moment.unitOfTime.DurationConstructor,
    minDate: MomentDate | null
) {
    const dateBefore = toMoment(day).clone().subtract(1, unit)
    return minDate && dateBefore.isBefore(minDate, unit)
}

export function allDaysDisabledAfter(
    day: MomentDate,
    unit: moment.unitOfTime.DurationConstructor,
    maxDate: MomentDate | null
) {
    const dateAfter = toMoment(day).clone().add(1, unit)
    return maxDate && dateAfter.isAfter(maxDate, unit)
}