import { maskCpfCnpj, toLocaleDate, toLocaleDateTime } from '../utils/format'
import { applyMask, tableMaskMoney } from '../utils/mask'
import { PhoneMask } from '../utils/misc'
import { inPath } from './path'

export const getValue = <T>(
    entity: T,
    path: PropAccessor<T>,
    format?: ListFormater<T>
) => {
    if (!!format && format instanceof Function) {
        return format(entity)
    }

    const val = inPath<T, any>(entity, path)
    const hasValue = typeof val !== 'undefined' && val !== null

    if (!!format && hasValue) {
        switch (format) {
            case 'money':
                return tableMaskMoney(val)
            case 'date':
                return toLocaleDate(val)
            case 'datetime':
                return toLocaleDateTime(val)
            case 'cpfcnpj':
                return maskCpfCnpj(val)
            case 'phone':
                return applyMask(PhoneMask(val.length === 11), val)
        }
    }

    return hasValue ? val.toString() : ''
}

export const getCellClassName = (
    align: TextAlign = 'left',
    isHeader = false,
    fixedWidth = false
) => {
    const classList = []

    if (align === 'center') {
        classList.push('has-text-centered')
    }

    if (align === 'right' && !isHeader) {
        classList.push('has-text-right')
    }

    if (fixedWidth) {
        classList.push('no-wrap no-width')
    }

    return classList.join(' ')
}