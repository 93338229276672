import React from 'react'
import { passText } from '../../helpers/functional'
import { getCellClassName, getValue } from '../../helpers/list'
import { isVisible } from '../../helpers/misc'
import { Button } from '../button'

type Props<T> = {
    items: T[]
    columns: Array<ListColumn<T>>
    primaryKey: PrimaryKey<T>
    emptyMessage?: string
    buttons?: Array<TableButton<T>>
}

type TableButton<T> = {
    styleClass: string
    title?: string
    icon?: string
    hideWhen?: (ent: T) => boolean
    action: string | ((ent: T) => void)
}

export const List = <T extends object>({
    items,
    buttons = [],
    columns: _columns,
    primaryKey,
    emptyMessage
}: Props<T>) => {
    const columns = _columns.filter(c => isVisible(c.hideWhen))
    const hasSomeFooter = columns.some(c => !!c.footer)
    const hasButton = buttons.length > 0

    return items.length > 0 ? (
        <div className="responsive-table">
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        {columns.map((col, key) => (
                            <th
                                key={key}
                                className={getCellClassName(
                                    col.textAlign,
                                    true,
                                    col.fixedWidth
                                )}
                            >
                                {col.title}
                            </th>
                        ))}

                        {hasButton && <th />}
                    </tr>
                </thead>
                <tbody>
                    {items.map((ent, key) => (
                        <tr key={key}>
                            {columns.map(
                                (
                                    {
                                        textAlign,
                                        style,
                                        fixedWidth,
                                        path,
                                        format
                                    },
                                    i
                                ) => (
                                    <td
                                        key={i}
                                        style={style}
                                        className={getCellClassName(
                                            textAlign,
                                            false,
                                            fixedWidth
                                        )}
                                    >
                                        {getValue(ent, path, format)}
                                    </td>
                                )
                            )}

                            {hasButton && (
                                <td className="no-wrap no-width">
                                    <div className="buttons">
                                        {buttons
                                            .filter(
                                                ({ hideWhen = () => false }) =>
                                                    !hideWhen(ent)
                                            )
                                            .map((bt, i) => {
                                                const action =
                                                    typeof bt.action ===
                                                    'string'
                                                        ? bt.action.replace(
                                                              ':id',
                                                              !!ent
                                                                  ? (ent[
                                                                        primaryKey
                                                                    ] as any).toString()
                                                                  : ''
                                                          )
                                                        : () => {
                                                              if (
                                                                  typeof bt.action !==
                                                                  'string'
                                                              ) {
                                                                  bt.action(ent)
                                                              }
                                                          }

                                                return (
                                                    <Button
                                                        key={i}
                                                        styleClass={`${
                                                            bt.styleClass
                                                        } is-small`}
                                                        title={bt.title}
                                                        icon={bt.icon}
                                                        onClick={action}
                                                    />
                                                )
                                            })}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>

                {hasSomeFooter && (
                    <tfoot>
                        <tr>
                            {columns.map(
                                (
                                    {
                                        footer = passText,
                                        style,
                                        textAlign,
                                        fixedWidth
                                    },
                                    i
                                ) => (
                                    <th
                                        key={i}
                                        style={style}
                                        className={getCellClassName(
                                            textAlign,
                                            false,
                                            fixedWidth
                                        )}
                                    >
                                        {footer(items)}
                                    </th>
                                )
                            )}
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    ) : (
        <article className="message">
            <div className="message-body">
                {emptyMessage || 'Nenhum item foi adicionado.'}
            </div>
        </article>
    )
}
