import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { FormProps, useForm } from "../../hooks/form";
import { addOrUpdate, getById } from "../../service/api";
import { requiredMessage } from "../../utils/misc";
import { parseNumber } from "../../utils/parse";
import { Button } from "../button";
import { ErrorMessage } from "../form/error-message";
import { ReadOnlyInput } from "../form/readonly-input";
import { TextInput } from "../form/text-input";
import { ModalActions } from "../panel";
import { RequiredPageProps } from "../route/page";

type Route = RouteComponentProps<{ id?: string }>;
type Props = RequiredPageProps;

function addOrUpdateEnviroment(
  enviroment: Enviroment,
  route: Route,
  form: FormProps<Enviroment>,
  modal: ModalActions
) {
  addOrUpdate<Enviroment>("enviroment", enviroment)
    .then(() => {
      form.setSubmitting(false);
      modal.alert(
        form.hasId
          ? "Ambiente atualizado com sucesso!"
          : "Ambiente criado com sucesso!",
        () => route.history.push("/ambiente")
      );
    })
    .catch(err => form.setErrors({ _error: err.message }));
}

export const AmbienteForm = ({ route, modal }: Props) => {
  const form = useForm<Enviroment>(
    {
      initialEntity: {
        id: 0,
        name: ""
      },
      validate: (values, err) => {
        if (!values.name) {
          err.name = requiredMessage;
        }
        return err;
      }
    },
    route
  );

  const { entity, errors, touched } = form;

  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (id, ac) =>
          getById<Enviroment>("enviroment", parseNumber(id), ac.signal),
        errorFn: err => form.setErrors({ _error: err.message })
      });
    }
  }, []);

  return (
    <form
      className="content"
      onSubmit={ev => {
        ev.preventDefault();
        form.handleSubmit(ent =>
          addOrUpdateEnviroment(ent, route, form, modal)
        );
      }}
    >
      <label>
        <h4>Novo ambiente</h4>
      </label>
      <div className="columns">
        <div className="column">
          <TextInput
            label="Nome do ambiente"
            value={entity.name}
            input={{
              autoComplete: "name"
            }}
            meta={{
              error: errors.name,
              touched: touched.name
            }}
            onChange={(name, type) =>
              form.handleChange({
                path: "name",
                type,
                values: { name }
              })
            }
          />
        </div>
      </div>

      <ErrorMessage error={errors._error} />

      <div className="buttons is-right">
        <Button
          icon="fas fa-save"
          styleClass="is-link is-medium"
          loading={form.submitting}
          disabled={form.submitting}
          title="Salvar"
        />
      </div>
    </form>
  );
};
