import React, { useEffect, useState } from 'react'
import { getList, remove } from '../../service/api'
import { Button } from '../button'
import { List } from '../list'
import { RequiredPageProps } from '../route/page'

type Props = RequiredPageProps

export const PersonaList = ({ modal }: Props) => {
    const [refresh, setRefresh] = useState(true)
    const [personas, setPersona] = useState<Persona[]>([])

    useEffect(() => {
        if (refresh) {
            getList<Persona>('persona')
                .then(items => setPersona(items))
                .catch(err => modal.alert(err.message))

            setRefresh(false)
        }
    }, [refresh])

    return (
        <div className="content">
            <div className="float-left">
                <h4>Personas</h4>
            </div>
            
            <div className="buttons is-right">
                <Button
                    onClick="/persona/cadastro"
                    title="Nova Persona"
                    icon="fas fa-plus"
                    styleClass="is-link is-medium"
                />
            </div>

            <List<Persona>
                primaryKey={'id'}
                columns={[
                    { title: '#', path: 'id' },
                    { title: 'Nome', path: 'name' },
                    {
                        title: 'Sexo',
                        path: 'sex',
                        format: e => (e.sex === 'F' ? 'Feminino' : 'Masculino')
                    },
                    { title: 'Idade', path: 'age' }
                ]}
                buttons={[
                    {
                        action: '/persona/cadastro/:id',
                        styleClass: 'is-info',
                        title: 'Editar',
                        icon: 'fas fa-edit'
                    },
                    {
                        action: entity =>
                            modal.confirm(
                                `Deseja remover a persona '${entity.name}'?`,
                                confirmed =>
                                    confirmed &&
                                    remove<Persona>('persona', entity.id)
                                        .then(() => setRefresh(true))
                                        .catch(err => modal.alert(err.message))
                            ),
                        icon: 'fas fa-times',
                        styleClass: 'is-danger',
                        title: 'Remover'
                    }
                ]}
                items={personas}
                emptyMessage="Nenhuma persona encontrada."
            />
        </div>
    )
}
