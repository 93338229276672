import { getPropPath } from './path'

export const passTrue = () => true
export const passText = () => ''
export const passVoid = () => { /**/ }
export const passArg = <T>(arg: T) => arg
export const _empty = <T>() => ({}) as T
export const _dbnull = <T>() => null as any as T
export const filterNull = <T>(array: Array<T | null>) => array.filter(e => !!e) as T[]
export const distinct = <T>(array: T[]) => Array.from(new Set(array))
export const flatMap = <T, E>(list: T[], callback: (ent: T, index: number) => E[]) =>
    list.reduce((acc, curr, i) => acc.concat(callback(curr, i)), [] as E[])
export const zip = <T1, T2>(first: T1[], second: T2[]) =>
    first.map((f, i) => [f, second[i]]) as Array<[T1, T2]>
export const head = <T>([first]: T[]) => !!first ? first : undefined
export const fst = <T, U>([first]: [T, U]) => first
export const snd = <T, U>([, second]: [T, U]) => second
export const range = (start: number, end: number) =>
    Array.from({ length: (end - start + 1) }, (_, k) => k + start)
export const unionWith = <T extends object>(...arrays: T[][]) => (comparator: (a: T, b: T) => boolean) =>
    arrays
        .reduce((a, b) => a.concat(b), [])
        .reduce(
            (result, computed) =>
                !result.some(s => comparator(computed, s)) ? [...result, computed] : result,
            [] as T[]
        )
export const groupBy = <T extends object>(collection: T[], accessor: keyof T | ((ent: T) => PropertyKey)) => {
    const aggr = new Map<PropertyKey, T[]>()
    const getKey = (ent: T) => accessor instanceof Function ? accessor(ent) : accessor

    collection.map(c => {
        const key = getKey(c)
        const list = aggr.get(key)

        if (!!list) {
            aggr.set(key, [...list, c])
        } else {
            aggr.set(key, [c])
        }
    })

    return aggr
}

export const sortBy = <T extends object>(collection: T[], sorting: Array<Sorting<T>>) =>
    sorting.reduceRight(
        (list, [prop, order]) => {
            const path = getPropPath(prop)

            return list.sort((a, b) => {
                const value = path.reduce((obj, p) => obj && p in obj ? obj[p] : undefined, a)
                const other = path.reduce((obj, p) => obj && p in obj ? obj[p] : undefined, b)

                if (value !== other) {
                    const valIsDefined = value !== undefined
                    const valIsNull = value === null
                    const valIsReflexive = value === value
                    const valIsSymbol = typeof value === 'symbol' || value instanceof Symbol

                    const othIsDefined = other !== undefined
                    const othIsNull = other === null
                    const othIsReflexive = other === other
                    const othIsSymbol = typeof other === 'symbol' || other instanceof Symbol

                    if ((!othIsNull && !othIsSymbol && !valIsSymbol && value > other) ||
                        (valIsSymbol && othIsDefined && othIsReflexive && !othIsNull && !othIsSymbol) ||
                        (valIsNull && othIsDefined && othIsReflexive) ||
                        (!valIsDefined && othIsReflexive) || !valIsReflexive) {
                        return order === 'ASC' ? 1 : -1
                    }
                    if ((!valIsNull && !valIsSymbol && !othIsSymbol && value < other) ||
                        (othIsSymbol && valIsDefined && valIsReflexive && !valIsNull && !valIsSymbol) ||
                        (othIsNull && valIsDefined && valIsReflexive) ||
                        (!othIsDefined && valIsReflexive) || !othIsReflexive) {
                        return order === 'ASC' ? -1 : 1
                    }
                }

                return 0
            })
        },
        [...collection]
    )

export function deepMerge<R, T extends object, S extends object>(target: T, source: S) {
    const destination = {}
    const isMergeable = (object: any) =>
        !!object && object instanceof Object && !(object instanceof Date) && !(object instanceof RegExp) && !(object instanceof Array) && object.$$typeof !== Symbol.for('react.element')

    if (isMergeable(target)) {
        Object.keys(target).map(key => {
            destination[key] = isMergeable(target[key]) ? deepMerge({}, target[key]) : target[key]
        })
    }

    Object.keys(source).map(key => {
        if (!isMergeable(source[key])) {
            destination[key] = source[key]
        }
        else {
            destination[key] = deepMerge(target[key], source[key])
        }
    })

    return destination as R
}

export const makeEnum = <T extends string[]>(...args: T) => {
    return Object.freeze(args.reduce((acc, next) => {
        return {
            ...acc,
            [next]: next,
        }
    }, Object.create(null)) as { [P in UnionFromTuple<typeof args>]: P })
}