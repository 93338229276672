import React, { Component } from 'react'
import { InjectedOnClickOutProps } from 'react-onclickoutside'
import { classNames } from '../../helpers/misc'

type Props = InjectedOnClickOutProps & {
    month: number
    monthNames: string[]
    onChange(month: number): void
    onCancel(): void
}

export class MonthDropdownOptions extends Component<Props> {
    handleClickOutside = () => this.props.onCancel()
    render() {
        const { props } = this

        return (
            <div className="react-datepicker__month-dropdown">
                {props.monthNames.map((month, i) => (
                    <div
                        className={classNames('react-datepicker__month-option', {
                            'react-datepicker__month-option--selected_month': props.month === i
                        })}
                        key={month}
                        onClick={() => props.onChange(i)}
                    >
                        {props.month === i ? <span className="react-datepicker__month-option--selected">✓</span> : ''}
                        {month}
                    </div>
                ))}
            </div>
        )
    }
}
