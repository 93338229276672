import moment from 'moment'
import React from 'react'
import { InjectedOnClickOutProps } from 'react-onclickoutside'

type Props = InjectedOnClickOutProps & {
    year: number
    yearsList: number[]
    maxDate: NullDate
    minDate: NullDate
    shiftYears(amount: number): void
    onChange(year: number): void
    onCancel(): void
}

export class YearDropdownOptions extends React.PureComponent<Props> {
    handleClickOutside = () =>
        this.props.onCancel()

    render() {
        const { props } = this
        const selectedYear = props.year

        const options = props.yearsList.map(year => (
            <div
                className={
                    selectedYear === year
                        ? 'react-datepicker__year-option --selected_year'
                        : 'react-datepicker__year-option'
                }
                key={year}
                onClick={() => props.onChange(year)}
            >
                {selectedYear === year ? <span className="react-datepicker__year-option--selected">✓</span> : ''}

                {year}
            </div>
        ))

        const minYear = props.minDate ? moment(props.minDate).year() : null
        const maxYear = props.maxDate ? moment(props.maxDate).year() : null

        if (!maxYear || !props.yearsList.find(year => year === maxYear)) {
            options.unshift(
                <div
                    className="react-datepicker__year-option"
                    key="upcoming"
                    onClick={() => props.shiftYears(1)}
                >
                    <a className="react-datepicker__navigation react-datepicker__navigation--years react-datepicker__navigation--years-upcoming" />
                </div>
            )
        }

        if (!minYear || !props.yearsList.find(year => year === minYear)) {
            options.push(
                <div
                    className="react-datepicker__year-option"
                    key="previous"
                    onClick={() => props.shiftYears(-1)}
                >
                    <a className="react-datepicker__navigation react-datepicker__navigation--years react-datepicker__navigation--years-previous" />
                </div>
            )
        }

        return (
            <div className="react-datepicker__year-dropdown">
                {options}
            </div>
        )
    }
}
